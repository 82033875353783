import React, { useState } from "react";
import { Button } from "../../../../components/Button/Button";
import { http } from "../../../../utils/httpCommon";
import { TextInput } from "../../../../components/TextInput/TextInput";
import { useForm } from "react-hook-form";
import useFetchData from "../../../../hooks/FetchData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";
import LoadingCircle from "../../../../components/LoadingCircle/LoadingCircle";
import ModalComponent from "../../../../components/Modal/Modal2";
const SendClientNotification = ({ opened, setOpened, res }) => {
  const schema = yup
    .object({
      title: yup.string().required(),
      message: yup.string(),
    })
    .required();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  let titles = ["In-Active", "MQR Coins"];

  const onSubmit = (passedData) => {};

  const handleOnClose = () => {
    setOpened(false);
    reset();
  };

  return (
    <ModalComponent opened={opened} setOpened={setOpened} onClose={handleOnClose}>
      <div className="flex flex-col">
        <div className="text-[25px] font-semibold">
          ASend Notifications to <br /> “{res?.name}”{" "}
        </div>
        <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
          <div className="my-4">
            <div>
              <FormControl fullWidth sx={{ border: "none !important" }}>
                <InputLabel>Notification Title</InputLabel>
                <Controller
                  control={control}
                  name="title"
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <Select
                      sx={{
                        color: "black",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#EFEFEF",
                        },
                      }}
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      variant="outlined"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={value}
                      label="Notification Title"
                      inputRef={ref}
                      defaultValue=""
                    >
                      <MenuItem value="">
                        {" "}
                        <em>None</em>{" "}
                      </MenuItem>
                      {titles.map((l) => (
                        <MenuItem key={l} value={l}>
                          {l}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <p className="text-red-500">{errors.title?.message}</p>
            </div>
          </div>

          <div className="mt-4 ">
            <TextInput label={"Notification Message"} multiline={true} rows={4} {...register("message")} />
            <p className="text-red-500">{errors.message?.message}</p>
          </div>
          <div className="mt-4 mb-20 flex justify-end gap-10">
            <Button onClick={() => setOpened(false)} secondary={true} invert={true}>
              Cancel
            </Button>
            <Button type="submit" secondary={true}>
              Send
            </Button>
          </div>
        </form>
      </div>
    </ModalComponent>
  );
};

export default SendClientNotification;
