import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import uploadIcon from "../../assets/uploadIcon.svg";
import DeleteIcon from "../../assets/icons/DeleteIcon.png";

const DropFile = ({
  image,
  setImage,
  width,
  height,
  message,
  bgColor,
  fit,
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      setImage(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    }, []),
    multiple: false,
  });

  const gallery = image?.map((file) => (
    <>
      <img
        className={`w-full h-full  ${fit ? fit : "object-cover"}`}
        key={file.name}
        src={file.preview}
        alt={file.name}
      />
    </>
  ));

  return (
    <div className={`relative z-30  w-[${width}] h-[${height}]`}>
      <div
        className={` flex flex-col items-center justify-center p-0 w-[${width}] h-[${height}] rounded-[5px]   border-[1px] border-[#32B3BE] border-dashed `}
        style={{
          backgroundColor: bgColor ? bgColor : "rgba(50, 179, 190, 0.1)",
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <>
            <img className="w-[70px]" src={uploadIcon} alt="upload icon" />
            <p className="  text-[16px]">
              Drag and drop {message ? message : "Image here"}
            </p>
          </>
        ) : (
          <>
            {gallery?.length > 0 && gallery[0]?.props.children.key ? (
              <>{gallery}</>
            ) : (
              <>
                <img className="w-[70px]" src={uploadIcon} alt="upload icon" />
                <p className="  text-[16px]  ">
                  Upload {message ? message : "Image"}
                </p>
              </>
            )}
          </>
        )}
      </div>
      {gallery?.length > 0 ? (
        <button
          onClick={() => {
            setImage([]);
          }}
          className="absolute  flex justify-center items-center mx-2 top-2 right-0  bg-[#E4E4E4] w-[40px] h-[40px] rounded-full z-50  hover:bg-gray-100 transition-all"
        >
          <img className=" w-[15px] h-[15px]  " src={DeleteIcon} alt="delete" />
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DropFile;
