import React from "react";
import { Modal } from "@mantine/core";

const ModalComponent = ({ opened, setOpened, reset = () => {}, children, size }) => {
  return (
    <>
      <Modal
        radius="lg"
        overflow="inside"
        centered
        size={size}
        opened={opened}
        onClose={() => {
          setOpened(false);
          reset();
        }}
      >
        {children}
      </Modal>
    </>
  );
};

export default ModalComponent;
