import { http } from "../utils/httpCommon";
import { useState, useEffect } from "react";

const useFetchData = (url) => {
  const [response, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const fetchData = async () => {
    try {
      const res = await http.get(url);
      setData(res.data.data);
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (url) {
      fetchData();
    } else {
      setData([]);
    }
  }, [url]);

  const refetch = () => {
    fetchData();
  };

  return { response, error, refetch, loading, fetchData };
};

export default useFetchData;
