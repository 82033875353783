import React, { useState } from "react";
import { Layout } from "../../components/Layout/Layout";
import { useAuthUser } from "react-auth-kit";
import TutorialsTable from "./tutorialsTable";

const Tutorials = () => {
    const auth = useAuthUser();
    const taps = ['Tutorials']
    return (
        <Layout>
            <div className="mt-[0px] mx-[2%]">
                <div className="text-[16px] font-semibold text-[#797979] ">Tutorials</div>

                <div className="text-[28px] font-semibold  " style={{ fontFamily: "gilroy-bold" }}>
                    Tutorials
                </div>
                
                <div>
                    <TutorialsTable />
                </div>
            </div>
        </Layout>
    );
};

export default Tutorials;
