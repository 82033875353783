import React, { useContext, useEffect, useState } from "react";
import { http } from "../../../../utils/httpCommon";
import Table from "../../../../components/DataGrid/Table";
import { toast } from "react-hot-toast";
import { SelectedLocationContext } from "../../../../Context/LocationProvider";
import { Link } from "react-router-dom";

const ClientSubscriptions = ({ clientId }) => {
  const { selectedLocation } = useContext(SelectedLocationContext);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const columns = [
    {
      field: "passes",
      headerName: "Passes",
      width: 150,

      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] ">
            {" "}
            <Link
              to={`/finance/subscriptions/${params?.row?._id}`}
              className=" object-cover h-[66px]  flex justify-center items-center font-semibold text-[16px] hover:text-[#34B2C0] transition-all"
            >
              {params?.row?.passess}
            </Link>
          </div>
        );
      },
    },
    {
      field: "usedPasses",
      headerName: "Used Passes",
      width: 150,

      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] ">
            {params?.row?.usedPassess}
          </div>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] font-semibold">
            {params?.row?.isActive ? "Active" : "In Active"}
          </div>
        );
      },
      // editable: true,
    },

    {
      field: "startDate",
      headerName: "Start Date",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {params?.row?.startDate ? new Date(params?.row?.startDate).toLocaleDateString() : "-"}
          </div>
        );
      },
      // editable: true,
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {params?.row?.endDate ? new Date(params?.row?.endDate).toLocaleDateString() : "-"}
          </div>
        );
      },
      // editable: true,
    },
  ];

  const fetchData = async (searchValues) => {
    try {
      if (!clientId) return;
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const res = await http.get(
        `/packages/subscriptions/client/${clientId}?page=${pageState.page}&limit=${pageState.pageSize}&location=${selectedLocation}&isActive=true`,
      );
      const rowsData = res.data?.data?.subscriptions?.map((d) => ({
        id: d._id,
        ...d,
      }));
      console.log(rowsData, res?.data, "rowsData");
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.data?.totalCount,
      }));
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageState.page, pageState.pageSize, selectedLocation, clientId]);

  return (
    <div>
      <Table
        rowCount={Math.ceil(pageState?.total / pageState?.pageSize)}
        isLoading={pageState.isLoading}
        removeSearch
        data={pageState?.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        keys={["passes", "usedPasses", "isActive", "startDate", "endDate"]}
        title={"Subscriptions"}
        removeActionButton={true}
      />
    </div>
  );
};

export default ClientSubscriptions;
