import React, { useEffect, useState } from "react";
import { Layout } from "../../../components/Layout/Layout";
import { Button } from "../../../components/Button/Button";
import { http } from "../../../utils/httpCommon";
import { TextInput } from "../../../components/TextInput/TextInput";
import CustomTextInput from "../../../components/CustomTextInput/CustomTextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import plus from "../../../assets/icons/plus.png";
import { Controller } from "react-hook-form";
import DropFile from "../../../components/DropFile/DropFile";
import { useNavigate } from "react-router-dom";
import coin from "../../../assets/coin.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import useFetchPostData from "../../../hooks/FetchPostData";
import { toast } from "react-hot-toast";
import AsyncSelect from "react-select/async";
import stopIcon from "../../../assets/stop.svg";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomTextField = styled(TextField)({
  backgroundColor: "white",
  ".MuiInputBase-input": {
    margin: " 0 10px",
    paddingLeft: "10px",
    borderLeft: "1px solid #EFEFEF",
  },
  "& .MuiFormLabel-root": {
    fontSize: "16px",
    fontWeight: "500",
  },

  "& label.Mui-focused": {
    color: "black",
    fontSize: "16px",
    fontWeight: "500",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#EFEFEF",
    },
  },
});

const NewLocation = () => {
  const navigator = useNavigate();
  //get all amenities
  const { response: amenities, loading } = useFetchPostData(
    "/amenity/all",
    JSON.stringify({
      page: 1,
      limit: 10000,
    })
  );

  //get all package Tiers
  const [loadingPackageTiers, setLoadingPackageTiers] = useState(false);
  const [packageTiers, setPackageTiers] = useState([]);

  const fetchPackageTiers = async () => {
    setLoadingPackageTiers(true);
    try {
      let res = await http.get("/package-tier?limit=1000000&page=1");
      setPackageTiers(res.data?.packageTiers);
      setLoadingPackageTiers(false);
    } catch (error) {
      setLoadingPackageTiers(false);
    }
  };

  useEffect(() => {
    fetchPackageTiers();
  }, []);

  const [mainImage, setMainImages] = useState([]);

  const [galleryImage, setGalleryImage] = useState([]);
  const [galleryImage2, setGalleryImage2] = useState([]);
  const [galleryImage3, setGalleryImage3] = useState([]);
  const [galleryImage4, setGalleryImage4] = useState([]);
  const [galleryImage5, setGalleryImage5] = useState([]);
  const [selectedLeader, setSelectedLeader] = useState([]);

  const [galleryCount, setGalleryCount] = useState(1);
  const schema = yup
    .object({
      name: yup.string().required(),
      area: yup
        .number()
        .positive()
        .integer()
        .required()
        .typeError("Area is a required filed and must be positive integer"),
      netArea: yup
        .number()
        .positive()
        .typeError("Net Area is a required filed and must be positive integer")
        .integer()
        .required(),
      checkinPriceCoins: yup
        .number()
        .positive()
        .typeError("Check in price coins is a required filed and must be positive integer")
        .integer()
        .required(),
      checkinPriceCash: yup
        .number()
        .positive()
        .typeError("check in price cash is a required filed and must be positive integer")
        .integer()
        .required(),
        target: yup
        .number()
        .positive()
        .min(0)
        .typeError("Target should be a positive number")
        .integer()
        .required(),
      starts: yup.string(),
      teamLeader: yup.string(),
      ends: yup.string(),
      notes: yup.string(),
      nas: yup.string(),
      metabaseDashboardRevenueNumber: yup
        .number()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
        .nullable()
        .notRequired()
        .positive("Metabase Dashboard Revenue Number should be a positive integer")
        .typeError("Metabase Dashboard Revenue Number should be a positive integer")
        .integer("Metabase Dashboard Revenue Number should be an integer"),
      metabaseDashboardFinanceNumber: yup
        .number()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
        .nullable()
        .notRequired()
        .positive("Metabase Dashboard Finance Number should be a positive integer")
        .typeError("Metabase Dashboard Finance Number should be a positive integer")
        .integer("Metabase Dashboard Finance Number should be an integer"),
      metabaseDashboardCommunityNumber: yup
        .number()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
        .nullable()
        .notRequired()
        .positive("Metabase Dashboard Community Number should be a positive integer")
        .typeError("Metabase Dashboard Community Number should be a positive integer")
        .integer("Metabase Dashboard Community Number should be an integer"),
      metabaseDashboardExperienceNumber: yup
        .number()
        .transform((value, originalValue) => (originalValue === "" ? null : value))
        .nullable()
        .notRequired()
        .positive("Metabase Dashboard Experience Number should be a positive integer")
        .typeError("Metabase Dashboard Experience Number should be a positive integer")
        .integer("Metabase Dashboard Experience Number should be an integer"),
      packageTier: yup.string(),
      weekends: yup.array(),
      paymentMethods: yup
        .string()
        .required("Payment method  is required")
        .typeError("Payment method  is required"),
    })
    .required();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [checkedAmenities, setCheckedAmenities] = useState([]);
  const handleCheckAmenities = (event) => {
    var updatedList = [...checkedAmenities];
    if (event.target.checked) {
      updatedList = [...checkedAmenities, event.target.value];
    } else {
      updatedList.splice(checkedAmenities.indexOf(event.target.value), 1);
    }
    setCheckedAmenities(updatedList);
  };

  const handleAddGalleryImage = () => {
    setGalleryCount((prevCount) => {
      return prevCount + 1;
    });
  };

  const [submitLoading, setSubmitLoading] = useState(false);

  const onSubmit = async (passedData) => {
    setSubmitLoading(true);

    var data = new FormData();
    data.append("image", mainImage[0]);

    var gallery = new FormData();
    gallery.append("image", galleryImage[0]);

    var gallery2 = new FormData();
    gallery2.append("image", galleryImage2[0]);

    var gallery3 = new FormData();
    gallery3.append("image", galleryImage3[0]);

    var gallery4 = new FormData();
    gallery4.append("image", galleryImage4[0]);

    var gallery5 = new FormData();
    gallery5.append("image", galleryImage5[0]);

    try {
      let res = await http.post("/image", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      let galleryRes;
      if (galleryImage.length > 0) {
        galleryRes = await http.post("/image", gallery, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      let galleryRes2;
      if (galleryImage2.length > 0) {
        galleryRes2 = await http.post("/image", gallery2, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      let galleryRes3;
      if (galleryImage3.length > 0) {
        galleryRes3 = await http.post("/image", gallery3, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      let galleryRes4;
      if (galleryImage4.length > 0) {
        galleryRes4 = await http.post("/image", gallery4, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      let galleryRes5;
      if (galleryImage5.length > 0) {
        galleryRes5 = await http.post("/image", gallery5, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      let galleryImagesUploaded = [
        galleryRes?.data?.url,
        galleryRes2?.data?.url,
        galleryRes3?.data?.url,
        galleryRes4?.data?.url,
        galleryRes5?.data?.url,
      ];

      let filteredUploadGallery = galleryImagesUploaded.filter((g) => g !== undefined);
      try {
        var data2 = JSON.stringify({
          name: passedData.name,
          area: passedData.area,
          netArea: passedData.netArea,
          amenities: checkedAmenities,
          mainImage: res.data.url,
          gallery: filteredUploadGallery,
          checkinPriceCoins: passedData.checkinPriceCoins,
          checkinPriceCash: passedData.checkinPriceCash,
          target: passedData.target,
          weekendDays: passedData.weekends,
          teamLeader: passedData.teamLeader,
          packageTier: passedData.packageTier,
          paymentMethods: paymentMethods?.filter((p) => p.value).map((p) => p.value),
          workingHours: {
            start: passedData.starts,
            end: passedData.ends,
          },
          notes: passedData.notes,
          nas: passedData.nas,
          metabaseDashboard: {
            REVENUE: passedData.metabaseDashboardRevenueNumber,
            FINANCE: passedData.metabaseDashboardFinanceNumber,
            COMMUNITY: passedData.metabaseDashboardCommunityNumber,
            EXPERIENCE: passedData.metabaseDashboardExperienceNumber,
          },
        });

        await http.post("/location", data2, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        setSubmitLoading(false);
        navigator("/inventory");
        toast.success("New Location has been added successfully");
      } catch (error) {
        toast.error(error?.response?.data?.message || error.message || "Something went wrong");
        setSubmitLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  let weekdays = [
    { name: "Friday", value: "Friday" },
    { name: "Saturday", value: "Saturday" },
    { name: "Sunday", value: "Sunday" },
    { name: "Monday", value: "Monday" },
    { name: "Tuesday", value: "Tuesday" },
    { name: "Wednesday", value: "Wednesday" },
    { name: "Thursday", value: "Thursday" },
  ];

  const loadOptions = async (inputText, callback) => {
    let res = null;
    if (inputText.length > 0) {
      var data = JSON.stringify({
        textField: inputText,
        page: 1,
        limit: 10000,
      });
      let response = await http.post(`/admin`, data);
      res = response.data.data.data;
      callback(
        res.map((i) => ({
          label: (
            <div key={`${i.phone}-${i?.name}`} className="flex items-center">
              {i?.imageUrl ? (
                <img
                  src={i?.imageUrl}
                  className="w-[35px] h-[35px] object-cover rounded-full mr-2"
                  alt={i.name}
                />
              ) : (
                <div className="h-[35px] "></div>
              )}
              {i.name} / {i.phone}
            </div>
          ),
          value: i._id,
        }))
      );
    }
  };

  //payment methods
  const [paymentMethods, setPaymentMethods] = useState([{ id: 1, value: "" }]);
  const addPaymentMethod = () => {
    const newId = paymentMethods.length + 1;
    const newPaymentMethod = { id: newId, value: "" };
    setPaymentMethods([...paymentMethods, newPaymentMethod]);
  };
  const handlePaymentMethodChange = (index, value) => {
    const updatedMethods = [...paymentMethods];
    updatedMethods[index].value = value;
    setPaymentMethods(updatedMethods);
  };

  const removePaymentMethod = (index) => {
    const updatedMethods = paymentMethods.filter((_, i) => i !== index);
    setPaymentMethods(updatedMethods);
  };

  const CustomNoOptionsMessage = ({ inputValue, onResetClick }) => (
    <div className="p-2">
      No results found{" "}
      <button
        className=" text-[18px]  text-[#34B2C0]  hover:opacity-95"
        style={{ fontFamily: "gilroy-bold" }}
        onClick={() => navigator(`/community/clients/new-client`)}
      >
        Add new client
      </button>
    </div>
  );

  return (
    <Layout>
      {!loading && !loadingPackageTiers ? (
        <div className="flex justify-center">
          <div className=" w-[75%]  bg-white p-4 m-1">
            <div className="flex flex-col">
              <div className="w-full flex justify-between">
                <span className=" text-[22px] font-bold my-2">Add new Location</span>
              </div>
              <span className="text-[16px]">Upload Images</span>
              <span className="text-[16px]">Supported formats: JPEG, PNG</span>
              {/* <span>Upload Main image</span> */}
            </div>
            <div className="flex gap-[15px] ">
              <div className="w-full min-w-[60%] h-[315px] py-2">
                <div className="font-semibold my-1 text-[16px]">Upload Main image</div>
                <DropFile
                  image={mainImage}
                  setImage={setMainImages}
                  height={"315px"}
                  width={"700px"}
                />
              </div>

              {/* Gallery */}
              <div className="  w-full py-2  overflow-auto ">
                <div className="font-semibold my-1 text-[16px]">Upload Gallery images</div>
                <div className="w-fit gap-[15px] grid  grid-rows-2  grid-flow-col   ">
                  <DropFile
                    image={galleryImage}
                    setImage={setGalleryImage}
                    height={"150px"}
                    width={"275px"}
                  />

                  {galleryImage.length > 0 || galleryImage2.length > 0 ? (
                    <DropFile
                      image={galleryImage2}
                      setImage={setGalleryImage2}
                      height={"150px"}
                      width={"275px"}
                    />
                  ) : (
                    <></>
                  )}

                  {galleryImage2.length > 0 || galleryImage3.length > 0 ? (
                    <DropFile
                      image={galleryImage3}
                      setImage={setGalleryImage3}
                      height={"150px"}
                      width={"275px"}
                    />
                  ) : (
                    <></>
                  )}

                  {galleryImage3.length > 0 || galleryImage4.length > 0 ? (
                    <DropFile
                      image={galleryImage4}
                      setImage={setGalleryImage4}
                      height={"150px"}
                      width={"275px"}
                    />
                  ) : (
                    <></>
                  )}

                  {galleryImage4.length > 0 || galleryImage5.length > 0 ? (
                    <DropFile
                      image={galleryImage5}
                      setImage={setGalleryImage5}
                      height={"150px"}
                      width={"275px"}
                    />
                  ) : (
                    <></>
                  )}

                  {galleryImage4.length > 0 ? (
                    <></>
                  ) : (
                    <button
                      style={{ backgroundColor: "rgba(50, 179, 190, 0.1)" }}
                      onClick={() => handleAddGalleryImage}
                      className=" h-[150px] w-[275px]   flex flex-col  items-center justify-center  p-4 rounded-[5px]   border-[1px] border-[#32B3BE] border-dashed "
                    >
                      <img className="w-[45px] h-[45px]" src={plus} alt="plus" />
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* form  */}
            <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-6">
                <TextInput
                  label={"Name*"}
                  {...register("name", { required: "input name is required" })}
                />
                <p className="text-red-500 text-[16px]">{errors.name?.message}</p>
              </div>
              <div className="mt-4">
                <div className=" flex flex-wrap gap-[5px] relative w-full h-fit py-3 bg-white border-[1px] border-[#EFEFEF] rounded-[5px] hover:border-[black] ">
                  <div className="absolute bg-white p-1 -top-4 text-[16px] left-2 text-[#00000099] ">
                    Amenities
                  </div>
                  {amenities?.data
                    ?.filter((a) => !a.isDeleted)
                    ?.map((item, index) => (
                      <div key={index} className=" ml-4">
                        <input
                          id={`cb${item._id}`}
                          className="hidden  peer "
                          value={item._id}
                          type="checkbox"
                          onChange={handleCheckAmenities}
                        />
                        <label
                          className="  text-[16px] cursor-pointer hover:bg-[#32B3BE] hover:text-[white] transition-all peer-checked:bg-[#32B3BE] peer-checked:text-[white] peer-checked:font-semibold  text-[#32B3BE] border-[1px] flex justify-center items-center py-1 px-2 border-[#32B3BE] rounded-full min-w-[80px]  "
                          htmlFor={`cb${item._id}`}
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}
                </div>
              </div>
              <hr className="my-4" />
              <div className="mt-4 mb-2 text-[20px] font-bold">Area</div>
              <div className="flex gap-4 w-full">
                <div className="w-1/2">
                  <CustomTextInput
                    label={"Area*"}
                    adornment={
                      <>
                        m<sup>2</sup>
                      </>
                    }
                    {...register("area", { required: "Area is required" })}
                  />
                  <p className="text-red-500 text-[16px]">{errors.area?.message}</p>
                </div>
                <div className="w-1/2">
                  <CustomTextInput
                    label={"Net Area*"}
                    adornment={
                      <>
                        m<sup>2</sup>
                      </>
                    }
                    {...register("netArea", {
                      required: "Net Area is required",
                    })}
                  />
                  <p className="text-red-500 text-[16px]">{errors.netArea?.message}</p>
                </div>
              </div>
              <hr className="my-4" />
              <div className="mt-4 flex w-full mb-2 text-[20px] font-bold">Check in price</div>
              <div className="flex w-full gap-4">
                <div className=" w-1/2  ">
                  <CustomTextInput
                    label={"Check in price coins*"}
                    adornment={
                      <>
                        <img className="w-[40px]" src={coin} alt="coin" />
                      </>
                    }
                    {...register("checkinPriceCoins", {
                      required: "Check in price coins is required",
                    })}
                  />
                  <p className="text-red-500 text-[16px]">{errors.checkinPriceCoins?.message}</p>
                </div>

                <div className="w-1/2">
                  <CustomTextInput
                    label={"Check in price cash*"}
                    adornment={<>EGP</>}
                    {...register("checkinPriceCash", {
                      required: "Check in price cash is required",
                    })}
                  />
                  <p className="text-red-500 text-[16px]">{errors.checkinPriceCash?.message}</p>
                </div>
              </div>
              <hr className="my-4" />
              <div className="mt-4 flex w-full mb-2 text-[20px] font-bold">Working hours</div>
              <div className="w-full flex mt-3 gap-4 ">
                <LocalizationProvider className="w-1/2" dateAdapter={AdapterDayjs}>
                  <Controller
                    control={control}
                    name="starts"
                    defaultValue={"sun, 12 Feb 2023 05:00:00 GMT"}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                    }) => (
                      <TimePicker
                        label="Working hours*"
                        value={value ? value : "Sun, 12 Feb 2023 06:00:00 GMT"}
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange}
                        inputRef={ref}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <>Starts</>
                            </InputAdornment>
                          ),
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            style={{ width: "100%" }}
                            className="bg-white w-full"
                            {...params}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>

                <LocalizationProvider className="w-1/2" dateAdapter={AdapterDayjs}>
                  <Controller
                    control={control}
                    name="ends"
                    defaultValue={"sun, 12 Feb 2023 21:00:00 GMT"}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                    }) => (
                      <TimePicker
                        label="Working hours*"
                        value={value ? value : "Sun, 12 Feb 2023 21:00:00 GMT"}
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange}
                        inputRef={ref}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <>Ends</>
                            </InputAdornment>
                          ),
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            style={{ width: "100%" }}
                            className="bg-white w-full"
                            {...params}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="my-4">
                <div>
                  <FormControl fullWidth sx={{ border: "none !important" }}>
                    <InputLabel>Select Weekends</InputLabel>
                    <Controller
                      control={control}
                      name="weekends"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <Select
                          sx={{
                            color: "black",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EFEFEF",
                            },
                          }}
                          labelId="Select Weekends"
                          id="Select Weekends"
                          multiple
                          value={value}
                          onBlur={onBlur} // notify when input is touched
                          onChange={onChange} // send value to hook form
                          input={<OutlinedInput label="Select Weekends" />}
                          MenuProps={MenuProps}
                          inputRef={ref}
                          defaultValue={[]}
                        >
                          {weekdays.map((w) => (
                            <MenuItem key={w.value} value={w.value}>
                              {w.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <p className="text-red-500 text-[16px]">{errors.weekends?.message}</p>
                </div>
              </div>
              <hr />
              <div className="mt-4">
                <div className="mt-4 flex w-full mb-2 text-[20px] font-bold">Location Leader</div>
                <Controller
                  control={control}
                  name="teamLeader"
                  render={({ field }) => (
                    <AsyncSelect
                      components={{
                        NoOptionsMessage: ({ inputValue }) => (
                          <CustomNoOptionsMessage inputValue={inputValue} onResetClick={() => {}} />
                        ),
                      }}
                      {...field}
                      isClearable
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: "56px",
                          borderRadius: "8px",
                          borderColor: "black",
                          "&:hover": {},
                        }),
                        option: (styles) => ({
                          ...styles,
                          zIndex: "999 !important",
                          backgroundColor: "white !important",
                        }),
                      }}
                      value={selectedLeader}
                      onChange={(value) => {
                        setSelectedLeader(value);
                        setValue("teamLeader", value.value);
                      }}
                      placeholder={"Search for Location Leader "}
                      loadOptions={loadOptions}
                    />
                  )}
                />
                <p className="text-red-500 text-[16px]">{errors.leader?.message}</p>
              </div>
              <hr className="my-4 w-full" />
              <div className="mt-4">
                <span className="text-[20px] font-bold">Payment Methods</span>
                <div className="mt-2">
                  <CustomTextInput
                    {...register("paymentMethods", {
                      required: "Payment methods name is required",
                    })}
                    label="Payment Method 1"
                    value={paymentMethods[0].value}
                    onChange={(e) => {
                      handlePaymentMethodChange(0, e.target.value);
                      setValue("paymentMethod", e.target.value);
                    }}
                  />

                  <p className="text-red-500 text-[16px]">{errors?.paymentMethods?.message}</p>
                </div>
                {paymentMethods.slice(1).map((method, index) => (
                  <div key={method.id} className="mt-2 flex items-center">
                    <CustomTextInput
                      label={`Payment Method ${index + 2}`}
                      value={method.value}
                      onChange={(e) => handlePaymentMethodChange(index + 1, e.target.value)}
                    />
                    <div onClick={() => removePaymentMethod(index + 1)} className="mx-2">
                      <img
                        src={stopIcon}
                        className="w-[30px] h-[30px] object-contain cursor-pointer"
                        alt="deleted"
                      />
                    </div>
                  </div>
                ))}
                <Button onClick={addPaymentMethod} secondary={true} invert={true}>
                  Add More
                </Button>
              </div>
              <hr className="my-4 w-full" />
              <div className="mt-4 ">
              <InputLabel> Target</InputLabel>
                <TextInput {...register("target")} type="number"/>
                <p className="text-red-500 text-[16px]">{errors.target?.message}</p>
              </div>
              <hr className="my-4 w-full" />
              <div>
                <div className="text-[20px] mb-2 font-bold">Package Tier</div>

                <FormControl fullWidth sx={{ border: "none !important" }}>
                  <InputLabel> Select package tier</InputLabel>
                  <Controller
                    control={control}
                    name={`packageTier`}
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                      <Select
                        sx={{
                          color: "black",
                          backgroundColor: "white",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#EFEFEF",
                          },
                        }}
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        variant="outlined"
                        labelId="Select package tier"
                        id=" Select package tier"
                        label=" Select package tier"
                        value={value}
                        inputRef={ref}
                        defaultValue=""
                      >
                        <MenuItem value=""> None </MenuItem>

                        {packageTiers?.map((p) => (
                          <MenuItem key={p._id} value={p._id}>
                            {p.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>

                <p className="text-red-500 text-[14px] ">
                  {errors.packages && errors.packageTier?.message}
                </p>
              </div>
              <hr className="my-4 w-full" />{" "}
              <div className="mt-4 ">
                <TextInput label={"Notes"} {...register("notes")} />
                <p className="text-red-500 text-[16px]">{errors.notes?.message}</p>
              </div>
              <div className="mt-4 ">
                <TextInput label={"NAS"} {...register("nas")} />
                <p className="text-red-500 text-[16px]">{errors.nas?.message}</p>
              </div>
              <hr className="my-4" />
              <div className="mt-4 flex w-full mb-2 text-[20px] font-bold">Metabase Dashboard</div>
              <div className="flex w-full gap-4">
                <div className="mt-4 ">
                  <TextInput label={"Revenue"} {...register("metabaseDashboardRevenueNumber")} />
                  <p className="text-red-500 text-[16px]">
                    {errors.metabaseDashboardRevenueNumber?.message}
                  </p>
                </div>

                <div className="mt-4 ">
                  <TextInput label={"Finance"} {...register("metabaseDashboardFinanceNumber")} />
                  <p className="text-red-500 text-[16px]">
                    {errors.metabaseDashboardFinanceNumber?.message}
                  </p>
                </div>

                <div className="mt-4 ">
                  <TextInput
                    label={"Community"}
                    {...register("metabaseDashboardCommunityNumber")}
                  />
                  <p className="text-red-500 text-[16px]">
                    {errors.metabaseDashboardCommunityNumber?.message}
                  </p>
                </div>

                <div className="mt-4 ">
                  <TextInput
                    label={"Experience"}
                    {...register("metabaseDashboardExperienceNumber")}
                  />
                  <p className="text-red-500 text-[16px]">
                    {errors.metabaseDashboardExperienceNumber?.message}
                  </p>
                </div>
              </div>
              <div className="mt-4 mb-8 flex justify-end gap-10">
                <Button onClick={() => navigator("/inventory")} secondary={true} invert={true}>
                  Cancel
                </Button>
                <Button loading={submitLoading} secondary={true} type="submit">
                  Add
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <LoadingCircle />
      )}
    </Layout>
  );
};

export default NewLocation;
