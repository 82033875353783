import { TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/Button";
import { http } from "../../../utils/httpCommon";
import { toast } from "react-hot-toast";

const Financial = () => {
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState({});
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm({
    values: config,
  });

  const editConfig = async (data) => {
    try {
      setLoading(true);
      const result = await http.put("/settings", data);
      setConfig(result.data?.data);
      toast.success(result.data.message || "Updated successfully");
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data.message || error.message || "Error saving configuration");
    } finally {
      setLoading(false);
    }
  };

  const fetchConfig = useCallback(async () => {
    try {
      const result = await http.get("/settings");
      setConfig(result.data?.data);
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data.message || error.message || "Error getting configuration");
    }
  }, []);

  useEffect(() => {
    fetchConfig();
  }, []);

  return (
    <div className="bg-white my-4 rounded-md min-h-[500px] p-2 space-y-3">
      <form onSubmit={handleSubmit(editConfig)} className="space-y-2 flex flex-col max-w-lg">
        <div className="space-y-4">
          <h1 className="font-bold text-xl">Quickbooks configurations</h1>
          <Controller
            control={control}
            name="unearnedRevenueAccountId"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="Unearned Revenue Account Id"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
          <Controller
            control={control}
            name="accountReceivableId"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="Account Receivable Id"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
          <Controller
            control={control}
            name="undepositedFundsAccountId"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="Undeposited Funds Account Id"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
          <Controller
            control={control}
            name="revenueAccountId"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="Global Revenue Account Id"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
          <Controller
            control={control}
            name="creditCardsAccId"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="Credit Cards Account Id"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
        </div>

        <div className="space-y-4">
          <h1 className="font-bold text-xl">Rent contracts configurations</h1>
          <Controller
            control={control}
            name="minRentDuration"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="Minimum Rent Duration in 'month'"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
          <Controller
            control={control}
            name="securityDepositPeriods"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="Security Deposit Period in 'month'"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
          <Controller
            control={control}
            name="downPaymentPercent"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="Down Payment Percentage (min 0.0, max 1.0)"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
          <Controller
            control={control}
            name="maxInstallments"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="Max Installments in 'month'"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
          <Controller
            control={control}
            name="rentPaymentLeeway"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="Payment Leeway in 'days'"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
          <Controller
            control={control}
            name="rentPaymentLeeway"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="Payment Leeway in 'days'"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
        </div>

        <div className="space-y-4">
          <h1 className="font-bold text-xl">Global</h1>
          <Controller
            control={control}
            name="forcedAppVersion"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="Required App Version"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
          <Controller
            control={control}
            name="vatPercent"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="VAT Percent (min 0.0, max 1.0)"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
          <Controller
            control={control}
            name="whtPercent"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="WHT Percent (min 0.0, max 1.0)"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
          <Controller
            control={control}
            name="coinExchangeRate"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="Coin Exchange Rate (1 coin = ? EGP)"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
          <Controller
            control={control}
            name="currencyExchangeRate"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="Currency Conversion Rate (1 $ = ? EGP)"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
          <Controller
            control={control}
            name="bookingDownPaymentPercent"
            rules={{ required: "Required" }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  fullWidth
                  type="number"
                  label="Booking Down Payment Percent (min 0.0, max 1.0)"
                  error={!!errors[field.name]}
                  helperText={errors[field.name] ? errors[field.name].message : ""}
                ></TextField>
              );
            }}
          ></Controller>
        </div>

        <Button type="submit" disabled={loading}>
          Update
        </Button>
      </form>
    </div>
  );
};

export default Financial;
