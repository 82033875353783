import React, { useState } from "react";
import { Layout } from "../../../components/Layout/Layout";
import { useParams, useNavigate } from "react-router-dom";
import useFetchData from "../../../hooks/FetchData";
import VerticalCarousel from "../../../components/VerticalCarousel/VerticalCarousel";
import whiteTrash from "../../../assets/icons/whiteTrash.png";
import whiteEdit from "../../../assets/icons/whiteEdit.png";
import locationIcon from "../../../assets/icons/locationIcon.png";
import { http } from "../../../utils/httpCommon";
import { prettyNumber } from "../../../lib/prettyNumber";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { toast } from "react-hot-toast";
import { useAuthUser } from "react-auth-kit";

const Facility = () => {
  const navigator = useNavigate();
  const { id } = useParams();
  const { response, error, loading, fetchData } = useFetchData(`/facility/${id}`);
  const auth = useAuthUser();
  let slides = [];
  let data = [];
  if (!loading) {
    slides = [...response?.gallery];
    slides?.push(response?.mainImage);
  }
  data = slides.map((s) => ({ id: s, introline: s }));

  const [openedDelete, setOpenedDelete] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const handleDelete = async (item) => {
    setDeletedItem(item);
    setOpenedDelete(true);
  };

  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleSubmitDelete = async () => {
    setDeleteLoading(true);
    try {
      await http.delete(`/facility/${deletedItem?._id}`);
      setOpenedDelete(false);
      fetchData();
      navigator("/inventory");

      toast.success("Facility has been deleted successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <DeleteModal
        opened={openedDelete}
        setOpened={setOpenedDelete}
        loading={deleteLoading}
        deletedItem={deletedItem}
        handleSubmitDelete={handleSubmitDelete}
        deleteMessage="Please Note that the facility is currently under a utilization by a service now or in the near future please confirm the deletion of all pending and active services booked."
      />
      <Layout>
        {!loading ? (
          <div className="flex  gap-[20px] ">
            {response?.mainImage ? (
              <div className="w-[55%] ">
                <div className="flex">
                  <VerticalCarousel data={data} />
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="w-[45%] flex flex-col gap-[15px] mt-[10px] ">
              {auth().role !== "Land Lord" && (
                <div className="flex gap-[50px]">
                  <button
                    onClick={() => navigator(`/inventory/edit-facility/${id}`)}
                    className="group text-[#686868] flex items-center text-[16px]  hover:text-[#32B3BE] transition-all "
                  >
                    <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                      <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                    </div>
                    Edit Facility
                  </button>
                  {auth().role === "Branch Manager" || auth().role === "Area Manager" ? null : (
                    <button
                      onClick={() => handleDelete(response)}
                      className="group text-[#686868] flex items-center text-[16px] hover:text-[#32B3BE] transition-all "
                    >
                      <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                        <img className=" w-[18px] " src={whiteTrash} alt="edit" />
                      </div>
                      Delete Facility
                    </button>
                  )}
                </div>
              )}
              <div className="text-[32px] font-bold">{response?.name}</div>
              <div className="flex  gap-[40px] text-[18px]">
                <div>
                  Area :
                  <br />
                  <span className="font-semibold">{response?.area} m&#178;</span>
                </div>
                <div>
                  Net Area :
                  <br />
                  <span className="font-semibold">{response?.netArea} m&#178;</span>
                </div>
              </div>
              <hr />
              <div className="my-1 flex gap-[30px] items-center ">
                <span className="bg-[#32B3BE] py-1 px-2 rounded-full text-[white] font-semibold text-[18px] ">
                  {response?.resource?.name}
                </span>
                <span className="flex items-center gap-[5px] text-[18px] ">
                  <img className="w-[18px] " src={locationIcon} alt="location" />
                  {response?.location?.name}
                </span>
              </div>
              <hr />
              <div className="my-1  ">
                <div className=" font-semibold  text-[18px] ">Amenities</div>

                {response?.amenities?.map((a) => (
                  <div key={a._id} className="flex gap-[10px] items-center text-[16px] my-1">
                    <img className="w-[30px]  object-cover" src={a.icon} alt="location" />
                    {a.name}
                  </div>
                ))}
              </div>
              <hr />
              <div className="flex text-[18px] gap-[40px]">
                <div>
                  <span>Rent price/ month:</span>
                  <br />
                  <span className="font-semibold">
                    {prettyNumber(+response?.rentPricePerMonth)} EGP
                  </span>
                </div>
                <div>
                  Booking price/ half hour:
                  <br />
                  <span className="font-semibold">
                    {prettyNumber(+response?.bookingPricePerHalfHour)} EGP
                  </span>
                </div>
              </div>
              <hr />
              <div className="font-semibold text-[18px]">
                Rent Contract
                <br />
                In-Active{" "}
              </div>
              <hr />
              <div className="text-[18px]">
                <span className="font-semibold text-[18px] ">Notes</span>
                <br />
                {response?.notes}
              </div>
            </div>
          </div>
        ) : (
          <LoadingCircle />
        )}
      </Layout>
    </>
  );
};

export default Facility;
