import React, { useState } from "react";
import { Button } from "../../../../components/Button/Button";
import { Link } from "react-router-dom";
import moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextInput } from "../../../../components/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import backArrow from "../../../../assets/backarrow.svg";
import { Controller } from "react-hook-form";
import { http } from "../../../../utils/httpCommon";
import { PasswordInput } from "@mantine/core";

const CalendarFormStep2 = ({
  responseData,
  close,
  paymentMethods,
  fetchAppointmentsData,
  subTotal,
  vat,
  total,
  amountPaid,
}) => {
  const [steps, setSteps] = useState(0);

  const schema = yup
    .object({
      paidAmount: yup
        .string()
        .typeError("Amount paid is required")
        .required("Amount paid is required"),
      adminPassword:
        steps === 0
          ? yup.string()
          : yup
              .string()
              .typeError("Admin password is required")
              .required("Admin password is required"),
      paymentMethodId: yup
        .number()
        .typeError("Payment method is required")
        .required("Payment method is required"),
      invoice: yup.boolean(),
      vat: yup.boolean(),
      wht: yup.boolean(),
    })
    .required();
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const onSubmit = async (passedData) => {
    if (steps === 0) {
      setSteps(1);
    } else {
      setSubmitLoading(true);
      try {
        await http.put(
          `bookings/${responseData?._id}/pay`,
          JSON.stringify({
            adminPassword: passedData.adminPassword,
            paidAmount: passedData?.paidAmount,
            paymentMethodId: passedData?.paymentMethodId,
            promoCode: passedData?.promoCode,
          }),
        );
        toast.success("Booking payment created successfully");
        fetchAppointmentsData();
        close();
      } catch (error) {
        toast.error(
          error?.response?.data?.message || error?.response?.message || "Something went wrong",
        );
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  return (
    <>
      {steps === 0 ? (
        <div className="px-4 pb-4 ">
          <div className="flex justify-between ">
            <div
              className="text-[32px] "
              style={{
                fontFamily: "gilroy-bold",
              }}
            >
              Confirm Booking
            </div>
          </div>

          <div className="my-2 flex flex-col gap-3">
            <div className="text-[16px] text-[#797979] font-semibold">Facility</div>
            <div className="border-[#E4E4E4] border-[1px] rounded-[10px] p-[6px] flex gap-3">
              <img
                src={responseData?.facility?.mainImage}
                alt={responseData?.facility?.name}
                className="rounded-[10px] w-[100px] object-cover h-[75px] "
              />
              <div className="flex flex-col justify-between w-full  ">
                <div className="text-[18px] " style={{ fontFamily: "gilroy-bold" }}>
                  {responseData?.facility?.name}
                </div>

                <Link
                  to={`/inventory/facility/${responseData?.facility?._id}`}
                  className="text-[16px] text-[#34B2C0] underline"
                >
                  {" "}
                  Quick view
                </Link>
              </div>
            </div>
          </div>

          <div className="my-2 flex flex-col gap-3">
            <div className="text-[#797979] text-[16px] font-semibold">Client information</div>
            <Link
              to={`/community/client/${responseData?.user?._id}`}
              className="border-[#E4E4E4] border-[1px] rounded-[10px] p-[6px] flex gap-3"
            >
              {responseData?.user?.profilePicture && (
                <img
                  src={responseData?.user?.profilePicture}
                  alt={responseData?.user?.name}
                  className="rounded-[10px] w-[65px] object-cover h-[65px] "
                />
              )}
              <div className="flex flex-col justify-center w-full  ">
                <div className="text-[16px] " style={{ fontFamily: "gilroy-bold" }}>
                  {responseData?.user?.name}
                </div>
                {responseData?.user?.company && (
                  <div className="text-[#797979]">
                    Member at " {responseData?.user?.company?.name}"
                  </div>
                )}
              </div>
            </Link>
          </div>

          <div className="my-2 flex flex-col gap-3">
            <div className="text-[#797979] text-[16px] font-semibold">Bookings</div>

            <div className="border-[#E4E4E4] border-[1px] rounded-[10px] px-4 py-2 flex flex-col gap-2">
              <div className="text-[14px] " style={{ fontFamily: "gilroy-bold" }}>
                {responseData?.days?.length}{" "}
                {responseData?.days?.length > 1 ? "Days between" : "Day between"}{" "}
                {moment(responseData?.days[responseData?.days?.length - 1]?.date).format("ddd")}{" "}
                {moment(responseData?.days[responseData?.days?.length - 1]?.date).format(
                  "Do MMM  YYYY",
                )}{" "}
                to{" "}
                {moment(responseData?.days?.map((d, index) => d?.startTime)[0] * 1000).format(
                  "ddd",
                )}{" "}
                {moment(responseData?.days?.map((d, index) => d?.startTime)[0] * 1000).format(
                  "Do MMM  YYYY",
                )}{" "}
              </div>
              <div className="px-4 py-2 text-[14px]">
                <ul className="list-disc">
                  {responseData?.days?.map((d) => (
                    <li key={d?._id}>
                      {moment(d?.date).format("dddd")} {moment(d?.date).format("LL.")}{" "}
                      {moment(d?.startTime * 1000).format("LT")}
                      {"  -  "}
                      {moment(d?.endTime * 1000).format("LT")}{" "}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
            <div className="text-[20px]  mt-5 mb-2 " style={{ fontFamily: "gilroy-bold" }}>
              Payment
            </div>

            <div className="mt-4 mb-2">
              <div>
                <FormControl fullWidth sx={{ border: "none !important" }}>
                  <InputLabel>Payment Method</InputLabel>
                  <Controller
                    control={control}
                    name="paymentMethodId"
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                      <Select
                        sx={{
                          color: "black",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#EFEFEF",
                          },
                        }}
                        onBlur={onBlur}
                        onChange={onChange}
                        variant="outlined"
                        labelId="Payment Method-label"
                        id="Payment Method"
                        value={value}
                        label="Payment Method"
                        inputRef={ref}
                        defaultValue=""
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {paymentMethods?.map((l) => (
                          <MenuItem key={l?.qboId} value={l?.qboId}>
                            {l?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <p className="text-red-500">{errors.paymentMethodId?.message}</p>
              </div>
            </div>

            <div className="border-[1px]   rounded-[10px]  mt-6 px-4 py-2">
              <div className="text-[16px] " style={{ fontFamily: "gilroy-bold" }}>
                Total Due
              </div>
              <div className="mt-[10px]">
                <span className=" font-extrabold text-[14px]">Subtotal</span>
                <span className="text-[#797979]   mx-[10px] ">{subTotal}</span>
              </div>
              <div className="mt-[8px] text-[16px]">
                <span className="font-extrabold">VAT</span>
                <span className="text-[#797979] mx-[10px] ">{vat}</span>
              </div>
              {amountPaid ? (
                <div className="mt-[8px] text-[16px]">
                  <span className="font-extrabold">Amount Paid</span>
                  <span className="text-[#797979] mx-[10px] ">{amountPaid}</span>
                </div>
              ) : null}
              <div className="mt-[15px]">
                <span className="text-[16px] font-semibold">Total</span>
                <br />
                <span className="text-[32px] " style={{ fontFamily: "gilroy-bold" }}>
                  {total}
                </span>
              </div>
            </div>

            <div className="mt-5 ">
              <TextInput label={"Amount Paid"} {...register("paidAmount")} />
              <p className="text-red-500">{errors.paidAmount?.message}</p>
            </div>

            <div className="flex w-full justify-end  mt-4 gap-5">
              <Button
                secondary={true}
                invert={true}
                onClick={() => {
                  fetchAppointmentsData();
                  setTimeout(() => {
                    close();
                  }, 1000);
                }}
              >
                Cancel
              </Button>
              <Button type="submit" secondary={true}>
                Pay now
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <div className="px-10 relative">
          <div
            onClick={() => setSteps(0)}
            className="cursor-pointer flex justify-center items-center w-[40px] aspect-square rounded-full bg-[#EFEFEF] hover:[##686868] "
          >
            <img src={backArrow} alt="back" className="w-[8px] h-[15px] object-contain" />
          </div>
          <div className="flex justify-between ">
            <div
              className="text-[38px] "
              style={{
                fontFamily: "gilroy-bold",
              }}
            >
              Security Confirmation
            </div>
          </div>

          <div className="my-2 flex flex-col gap-3">
            <div className="text-[#797979] text-[16px] font-semibold">Client information</div>
            <Link
              to={`/community/client/${responseData?.user?._id}`}
              className="border-[#E4E4E4] border-[1px] rounded-[10px] p-[5px] flex gap-2"
            >
              <img
                src={responseData?.user?.profilePicture}
                alt={responseData?.user?.name}
                className="rounded-[10px] w-[65px] object-cover h-[65px] "
              />
              <div className="flex flex-col justify-center w-full  ">
                <div className="text-[18px] " style={{ fontFamily: "gilroy-bold" }}>
                  {responseData?.user?.name}
                </div>
                {responseData?.user?.company && (
                  <div className="text-[#797979]">
                    Member at " {responseData?.user?.company?.name}"
                  </div>
                )}
              </div>
            </Link>
          </div>

          <div className="text-[16px]">
            I Admin name confirm the payment of " {getValues()?.paidAmount} " by{" "}
            {responseData?.user?.name} for Booking facility at {responseData?.location?.name} branch
            for {responseData?.days?.length} days
          </div>

          <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-6 ">
              <PasswordInput
                required
                size="lg"
                radius={"md"}
                placeholder="Admin Passowrd"
                {...register("adminPassword")}
              />
              <p className="text-red-500">{errors.adminPassword?.message}</p>
            </div>

            <div className="flex w-full justify-end mt-4 gap-5">
              <Button
                secondary={true}
                invert={true}
                onClick={() => {
                  close();
                  fetchAppointmentsData();
                }}
              >
                Cancel
              </Button>

              <Button loading={submitLoading} type="submit" secondary={true}>
                Confirm
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default CalendarFormStep2;
