import React, { useState, useContext } from "react";
import logo from "../../assets/loginlogo.jpg";
import { TextInput, PasswordInput, Checkbox, Anchor, Paper, Title, Text, Group, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { http } from "../../utils/httpCommon";
import { useSignIn } from "react-auth-kit";

const Login = () => {
  const navigate = useNavigate();
  const [resetPass, setResetPass] = useState(false);
  const [error, setError] = useState({ status: "", mes: "" });
  const [passSent, setPassSent] = useState(false);

  const signIn = useSignIn();
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const ResetPasswordRequest = async (email) => {
    // var data = JSON.stringify({
    //   email: email,
    // });

    const res = await http.post("/admin/sendresetpasswordmail", { email });
    setPassSent(true);

    // var config = {
    //   method: "post",
    //   url: `${process.env.REACT_APP_URL}/user/sendresetpasswordmail`,
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     "Content-Type": "application/json",
    //   },
    //   data: data,
    // };
    // await axios(config)
    //   .then(function (response) {
    //     setPassSent(true);
    //   })
    //   .catch(function (error) {
    //   });
  };

  const handleSubmit = async (values) => {
    try {
      const response = await http.post("/admin/login", values);
      signIn({
        token: response.data?.data?.token,
        expiresIn: 1000000,
        tokenType: "Bearer",
        authState: response.data?.data,
      });
      localStorage.setItem("tap", 0);

      navigate("/");
      setError({ status: "", mes: "" });
    } catch (error) {
      console.error("error ",error);
      setError({
        status: error.response?.data.code,
        mes: error.response?.data.message || error.message || "Something went wrong",
      });
    }
  };

  return (
    <div className="flex w-full h-screen">
      <div className="w-1/2  ">
        <img className="max-h-screen" src={logo} alt="logo" />
      </div>
      <div className="w-1/2 h-full bg-black flex items-center justify-center">
        <div style={{}}>
          <Title
            align="center"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 900,
              color: "white",
            })}
          >
            <span>Welcome to</span>
            <br />
            <span>MQR Operations Portal</span>
          </Title>
          <Text color="dimmed" size="sm" align="center" mt={5}>
            Do not have an account yet? Contact System Admin
          </Text>
          {resetPass ? (
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
              <form onSubmit={form.onSubmit((values) => ResetPasswordRequest(values.email))}>
                <TextInput style={{ width: "250px" }} required label="Please Enter Your Email" type="email" placeholder="your@email.com" {...form.getInputProps("email")} />
                {passSent ? (
                  <p
                    style={{
                      margin: "10px 10px 10px 0px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    Email has sent please check your inbox{" "}
                  </p>
                ) : (
                  <></>
                )}
                <Button
                  fullWidth
                  mt="xl"
                  type="submit"
                  style={{
                    backgroundColor: "black",
                  }}
                >
                  <>Submit Email</>
                </Button>
                <Group position="apart" mt="md" width={900}>
                  <Anchor
                    onClick={() => {
                      setResetPass(false);
                    }}
                    size="sm"
                  >
                    Try to login again?
                  </Anchor>
                </Group>
              </form>
              {error ? (
                <div
                  style={{
                    margin: "5px",
                    fontSize: "14px",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  {/* Invalid Email or Password */}
                  {error.mes}
                </div>
              ) : (
                <></>
              )}
            </Paper>
          ) : (
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
              <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <TextInput required label="Email" type="email" placeholder="your@email.com" {...form.getInputProps("email")} />
                <PasswordInput required label="Password" placeholder="* * * * * * * *" {...form.getInputProps("password")} mt="md" />
                <Group position="apart" mt="md">
                  <Checkbox label="Remember me" />
                  <Anchor
                    onClick={() => {
                      setResetPass(true);
                    }}
                    size="sm"
                  >
                    Forgot password?
                  </Anchor>
                </Group>
                <Button
                  fullWidth
                  mt="xl"
                  type="submit"
                  style={{
                    backgroundColor: "black",
                  }}
                >
                  Sign in
                </Button>
              </form>
              {error ? (
                <div
                  style={{
                    margin: "5px",
                    fontSize: "14px",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  {error.mes}
                </div>
              ) : (
                <></>
              )}
            </Paper>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
