import React, { useEffect, useState } from "react";
import { Layout } from "../../../components/Layout/Layout";
import whiteEdit from "../../../assets/icons/whiteEdit.png";
import Trash from "../../../assets/icons/whiteTrash.png";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { http } from "../../../utils/httpCommon";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DeletePackage from "./DeletePackage";
import Table from "../../../components/DataGrid/Table";

const Package = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [openDeletePackageModal, setOpenDeletePackageModal] = useState(false);
  const navigator = useNavigate();
  const params = useParams();

  const fetchPackage = async () => {
    setLoading(true);
    try {
      const res = await http.get(`/packages/${params?.id}`);
      console.log(res.data.data);
      setResponse([res?.data?.data]);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPackage();
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Package Name",
      width: 220,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.name}
          </div>
        );
      },
    },
    {
      field: "passes",
      headerName: "Passes",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.passes}
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.price}
          </div>
        );
      },
    },
    {
      field: "discount",
      headerName: "Discount",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.discount}%
          </div>
        );
      },
    },
    {
      field: "priceAfterDiscount",
      headerName: "Price After Discount",
      width: 220,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.priceAfterDiscount}
          </div>
        );
      },
    },
    {
      field: "expireIn",
      headerName: "Expire In",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.expireIn} days
          </div>
        );
      },
    },
  ];
  return (
    <Layout>
      <DeletePackage
        opened={openDeletePackageModal}
        setOpened={setOpenDeletePackageModal}
        name={response?.[0]?.name}
        id={response?.[0]?._id}
      />
      {loading ? (
        <LoadingCircle />
      ) : (
        <div>
          <div
            className="text-[30px] mb-2 flex items-center justify-between pr-20"
            style={{ fontFamily: "gilroy-bold" }}
          >
            {response?.[0]?.name}
            <div className="flex items-center gap-6">
              <button
                onClick={() => navigator(`/operations/edit-package/${response?.[0]?._id}`)}
                className="text-[16px] group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
              >
                <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                  <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                </div>
                Edit Package
              </button>
              <button
                onClick={() => setOpenDeletePackageModal(true)}
                className="text-[16px] group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
              >
                <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                  <img className=" w-[18px] " src={Trash} alt="Archive" />
                </div>
                Delete
              </button>
            </div>
          </div>

          <div>
            <Table
              // searchQuery={searchQuery}
              // handleChange={handleChange}
              // rowCount={pageState?.total}
              // isLoading={pageState.isLoading}
              // data={pageState?.data}
              // page={pageState.page - 1}
              // pageSize={pageState.pageSize}
              // onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
              // onPageSizeChange={(newPageSize) =>
              //   setPageState((old) => ({ ...old, pageSize: newPageSize }))
              // }
              // fetchData={fetchData}
              hidePagination={true}
              removeSearch={true}
              checkboxSelection={false}
              disableFilters={true}
              columns={columns}
              data={response}
              title={"Subscriptions"}
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Package;
