import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "../../../assets/icons/DeleteIcon.png";
import uploadIcon from "../../../assets/uploadIcon.svg";
import { TextInput } from "../../../components/TextInput/TextInput";
import ModalComponent from "../../../components/Modal/Modal";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Button } from "../../../components/Button/Button";
import { http } from "../../../utils/httpCommon";
import { toast } from "react-hot-toast";

const VoidRequest = ({ opened, setOpened, fetchData }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    reason: "",
  });

  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    // Do something with the files
  }, []);
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

  const images = files.map((file) => <img className=" h-[120px] w-[150px] object-contain" key={file.name} src={file.preview} alt={file.name} />);

  const [submitLoading, setSubmitLoading] = useState(false);
  const onSubmit = async (passedData) => {
    setSubmitLoading(false);
    setOpened(false);
    setFiles([]);
    fetchData();
    reset();
    setError("");
    toast.success("Marked as void ");

    // setSubmitLoading(true);
    // var data = new FormData();
    // data.append("image", files[0]);
    // try {
    //   const res = await http.post("/image", data, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   });
    //   try {
    //     var data2 = JSON.stringify({
    //       name: passedData.name,
    //       icon: res.data.url,
    //     });
    //     await http.post("/amenity", data2, {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     });
    //     setSubmitLoading(false);
    //     setOpened(false);
    //     setFiles([]);
    //     fetchData();
    //     reset();
    //     setError("");
    //     toast.success("New Amenity has been added successfully");
    //   } catch (error) {
    //     toast.error("Something went wrong");
    //     setError(error.response.data.message);
    //   }
    // } catch (error) {
    // }
  };

  return (
    <ModalComponent opened={opened} setOpened={setOpened}>
      <div className="text-[24px] font-bold">Mark as void</div>
      <form onSubmit={handleSubmit(onSubmit)} className="  flex flex-col justify-center items-center gap-[40px] mt-4">
        <div className="w-[100%]">
          <TextInput multiline={true} rows={6} label={"Void Reason"} {...register("reason")}></TextInput>
          <ErrorMessage name="reason" errors={errors} render={({ message }) => <p className="text-red-500">{message}</p>} />
          <p className="text-red-500">{error}</p>
        </div>

        <div className="flex justify-around  w-full">
          <Button secondary={true} invert={true} onClick={() => setOpened(false)}>
            Cancel
          </Button>
          <Button loading={submitLoading} secondary={true} type="submit">
            Confirm
          </Button>
        </div>
      </form>
    </ModalComponent>
  );
};

export default VoidRequest;
