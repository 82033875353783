import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import { forwardRef } from "react";

const CustomTextField = styled(TextField)({
  backgroundColor: "white",
  ".MuiInputBase-input": {
    margin: " 0 10px",
    paddingLeft: "10px",
    borderLeft: "0px solid #EFEFEF",
  },
  "& .MuiFormLabel-root": {
    fontSize: "16px",
    fontWeight: "500",
  },

  "& label.Mui-focused": {
    color: "black",
    fontSize: "16px",
    fontWeight: "500",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#EFEFEF",
    },
    // '&:hover fieldset': {
    //   borderColor: '#EFEFEF',
    // },
    // '&.Mui-focused fieldset': {
    //   borderColor: 'green',
    // },
  },

  fontFamily: "gilroy !important",

  // "& .MuiInputBase-root ": {
  //   height: "50px !important",
  //   padding:"0px"
  // },
});
const CustomTextInput = forwardRef(
  ({ label, adornment, value, onChange, children, ...props }, ref) => {
    return (
      <>
        <CustomTextField
          label={label}
          id="outlined-start-adornment"
          sx={{ my: 1, width: "100%" }}
          ref={ref}
          value={value}
          onChange={onChange}
          {...props}
          InputProps={{
            startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
          }}
        />
        {children}
      </>
    );
  }
);

export default CustomTextInput;
