import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import viewIcon from "../../../assets/icons/ViewIcon.png";
import profileIcon from "../../../assets/icons/profileIcon.png";
import phoneIcon from "../../../assets/icons/phoneIcon.png";
import RequestModal from "./RequestModal";
import { http } from "../../../utils/httpCommon";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { debounce } from "lodash";
import Table from "../../../components/DataGrid/Table";

const CompanyRequestsTable = () => {
  const navigator = useNavigate();
  const [searchQuery, setSearchQuery] = React.useState("");

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    debounced(event.target.value, 600);
  };

  ///onFilterChange
  const onFilterChange = useCallback(async (filterModel) => {
    let searchVal = filterModel?.items[0]?.value;

    if (searchVal?.length > 0) {
      let res = await http.post(
        `/request/company/all`,
        JSON.stringify({
          page: pageState.page,
          limit: pageState.pageSize,
        })
      );

      let filteredData = res?.data?.data?.data;

      const rowsData = filteredData?.map((d) => ({
        id: d._id,
        type: "company",
        ...d,
      }));

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        //get from another api
        total: res?.data?.data?.count,
      }));
    } else {
      debounced();
    }
  }, []);

  const [openRequestModal, setOpenRequestModal] = useState(false);

  const columns = [
    // {
    //   field: "id",
    //   headerName: "Request ID",
    //   width: 150,

    //   // editable: true,
    //   renderCell: (params) => {
    //     return (
    //       <Link
    //         to={`/community/client/${params.row.id}`}
    //         className="flex items-center h-[100px] font-semibold text-[18px]  py-2"
    //       >
    //         {/* {params?.row?.id} */}
    //       </Link>
    //     );
    //   },
    // },
    {
      field: "type",
      headerName: "Request type",
      width: 200,
      renderCell: (params) => {
        return (
          <div
            // to={`/community/client/${params.row.id}`}
            className="flex items-center h-[100px] font-semibold text-[16px]  py-2"
          >
            Company
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Request Status",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] w-[130px]">
            <div
              className={` ${
                params?.row?.approved
                  ? " text-[#797979] bg-[#a09f9f]"
                  : "text-[#34B2C0]  bg-[#3bb2c0]"
              }  bg-opacity-20 capitalize font-semibold p-2 text-[16px] flex  justify-center items-center rounded-[25px]   `}
            >
              {params?.row?.approved ? "Resolved" : "Open"}
            </div>
          </div>
        );
      },
    },

    {
      field: "info",
      headerName: "Requester Information",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="flex flex-col gap-[10px] items-start h-[100px] text-[16px] text-base  py-2">
            <span className="flex items-center gap-[10px] text-[16px]">
              <img className="w-[14px]" src={profileIcon} alt={params?.row?.company?.name} />
              {params?.row?.company?.name}
            </span>
            <span className="flex text-[16px]  items-center gap-[10px]">
              <img className="w-[14px]" src={phoneIcon} alt={params?.row?.company?.phone} />
              {params?.row?.company?.phone}
            </span>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      // editable: true,
      renderCell: (params) => {
        return (
          <button
            onClick={() => handleViewRequestModal(params?.row)}
            className="group font-semibold text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
          >
            <div className="flex justify-center text-[16px] items-center bg-[#686868] w-[34px] h-[34px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
              <img className=" w-[18px] " src={viewIcon} alt="notificationIcon" />
            </div>
            View Request
          </button>
        );
      },
    },
  ];

  const [requestModalId, setRequestModalId] = useState({});
  const handleViewRequestModal = (row) => {
    setRequestModalId(row);
    setOpenRequestModal(true);
  };

  const handleActionButton = () => {
    // navigator("/settings/admins/add-admin");
  };

  ///Fetching new Data
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const fetchData = async (searchValues) => {
    setPageState((old) => ({
      ...old,
      isLoading: true,
    }));

    const res = await http.post(
      `/request/company/all`,
      JSON.stringify({
        page: pageState.page,
        limit: pageState.pageSize,
      })
    );

    let filteredData = res?.data?.data?.data;

    const rowsData = filteredData?.map((d) => ({
      id: d._id,
      type: "company",
      ...d,
    }));

    setPageState((old) => ({
      ...old,
      isLoading: false,
      data: rowsData,
      //get from another api
      total: res?.data?.data?.count,
    }));
  };
  const debounced = useCallback(debounce(fetchData, 1000), []);

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize]);

  return (
    <>
      <RequestModal
        opened={openRequestModal}
        setOpened={setOpenRequestModal}
        row={requestModalId}
        fetchData={fetchData}
      />

      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState.total}
        isLoading={pageState.isLoading}
        data={pageState?.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        removeActionButton={true}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        keys={["id", "name", "phone", "email", "location", "role"]}
        title={"Companies"}
        actionButton={""}
        onFilterChange={onFilterChange}
        handleActionButton={handleActionButton}
      />
    </>
  );
};

export default CompanyRequestsTable;
