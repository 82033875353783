import React, { useContext, useState } from "react";
import { Button } from "../../../../components/Button/Button";
import ModalComponent from "../../../../components/Modal/Modal2";
import { http } from "../../../../utils/httpCommon";
import { toast } from "react-hot-toast";
import { TextInput } from "../../../../components/TextInput/TextInput";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useFetchData from "../../../../hooks/FetchData";
import { SelectedLocationContext } from "../../../../Context/LocationProvider";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Loader } from "tabler-icons-react";

const EditCreditBalance = ({ opened, setOpened, response, refetch }) => {
  const { selectedLocation } = useContext(SelectedLocationContext);

  const { response: paymentMethods, loading: loadingPaymentMethod } = useFetchData(
    `financials/${selectedLocation || "none"}/payment-method-accounts`
  );

  const schema = yup
    .object({
      amount: yup
        .number()
        .required("Amount is required")
        .positive("Amount must be a positive number"),

      paymentMethodId: yup
        .string()
        .typeError("Payment method paid is required")
        .required("payment method is required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      amount: response?.wallet?.credit,
    },
  });

  const [disableButton, setDisableButton] = useState(false);

  const onSubmit = async (data) => {
    setDisableButton(true);
    try {
      await http.post(
        `/credit`,
        JSON.stringify({
          amount: data.amount,
          paymentMethodId: data.paymentMethodId,
          locationId: selectedLocation,
          accountId: response?._id,
        })
      );
      refetch();
      reset();
      setOpened(false);
      toast.success("Credit Added Successfully");
    } catch (error) {
      toast.error(
        error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong"
      );
    } finally {
      setDisableButton(false);
    }
  };

  const handleOnClose = () => {
    setOpened(false);
  };

  return (
    <ModalComponent size={"xl"} opened={opened} setOpened={setOpened} onClose={handleOnClose}>
      {loadingPaymentMethod ? (
        <Loader />
      ) : (
        <div className="flex justify-center flex-col items-center gap-[25px] ">
          <div className=" text-[25px] font-bold ">Edit {response?.name} Credit Balance </div>
          <form className="w-full p-8" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TextInput {...register("amount")} label={"Amount"} />
              <p className="text-red-500 text-[16px]">{errors.amount?.message}</p>
            </div>
            <div className="mt-6">
              {/* Payment method */}
              <FormControl fullWidth sx={{ border: "none !important" }}>
                <InputLabel>Payment Method</InputLabel>
                <Controller
                  control={control}
                  name="paymentMethodId"
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <Select
                      sx={{
                        color: "black",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#EFEFEF",
                        },
                      }}
                      onBlur={onBlur}
                      onChange={onChange}
                      variant="outlined"
                      labelId="Payment Method-label"
                      id="Payment Method"
                      value={value}
                      label="Payment Method"
                      inputRef={ref}
                      defaultValue=""
                    >
                      <MenuItem className="w-full" value="">
                        <em>None</em>
                      </MenuItem>
                      {paymentMethods?.map((l) => (
                        <MenuItem key={l?.qboId} value={l?.qboId}>
                          {l?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <p className="text-red-500">{errors.paymentMethodId?.message}</p>
            </div>
            <div className="flex gap-[40px] mt-8">
              <Button
                disabled={disableButton}
                onClick={handleOnClose}
                secondary={true}
                invert={true}
                type="button"
              >
                Cancel
              </Button>
              <Button disabled={disableButton} secondary type="submit">
                Confirm
              </Button>
            </div>
          </form>
        </div>
      )}
    </ModalComponent>
  );
};

export default EditCreditBalance;
