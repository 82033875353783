import React, { ReactNode } from "react";
import { useCallback } from "react";
import LoadingCircle from "../LoadingCircle/LoadingCircle";

export function Button({
  children,
  invert,
  secondary,
  loading,
  classes,
  height,
  disabled,
  type = "button",
  ...props
}) {
  const getButtonStyles = useCallback(() => {
    const defaultStyles = `flex items-center  justify-center font-semibold  text-[20px] text-center min-w-[120px] w-fit rounded-lg py-2 px-4 duration-150 transition `;

    let bgAndText;

    if (secondary) {
      if (invert) {
        bgAndText = `border-[1px] border-[white]  ${disabled ? "" : "hover:bg-black"} ${
          disabled ? "" : "hover:text-white"
        } font-bold   bg-white text-[black]  transition-all duration-150`;
      } else {
        bgAndText = ` bg-black text-white font-bold border-[1px]   ${
          disabled ? "" : "hover:border-[1px]"
        }  ${disabled ? "" : "hover:border-[#8E8E8E]"}  ${disabled ? "" : "hover:bg-white"}  ${
          disabled ? "" : "hover:text-[#8E8E8E]"
        }  transition-all duration-150 `;
      }
    } else {
      if (invert) {
        bgAndText = `bg-[#8E8E8E] text-white  font-bold ${disabled ? "" : "hover:bg-[#9E9E9E]"} `;
      } else {
        bgAndText = "bg-black text-white hover:bg-neutral-700 font-bold ";
      }
    }

    return defaultStyles + bgAndText;
  }, [invert, secondary]);

  return (
    <button
      disabled={loading || disabled}
      className={"    " + getButtonStyles() + classes}
      type={type}
      {...props}
    >
      {loading ? (
        <div className="h-[10px]">
          <LoadingCircle />
        </div>
      ) : (
        children
      )}
    </button>
  );
}
