import React, { useState } from "react";

const ActivityLogs = () => {
  const [filter, setFilter] = useState("all");
  return (
    <div>
      <div className="flex gap-5 text-[16px]">
        <button
          className={` font-semibold border-[1px] border-[#797979] px-4 py-2  rounded-[20px] 
                       hover:text-white hover:bg-[#34B2C0] hover:border-[#34B2C0] transition-all      
                         ${
                           filter === "all"
                             ? "text-[white] bg-[#34B2C0] border-[#34B2C0] "
                             : "text-[#797979]  "
                         }
                       `}
          onClick={() => setFilter("all")}
        >
          All{" "}
        </button>

        <button
          className={` font-semibold border-[1px] border-[#797979] px-4 py-2 rounded-[20px] 
                       hover:text-white hover:bg-[#34B2C0] hover:border-[#34B2C0] transition-all      
                         ${
                           filter === "checkin"
                             ? "text-[white] bg-[#34B2C0] border-[#34B2C0] "
                             : "text-[#797979]  "
                         }
                       `}
          onClick={() => setFilter("checkin")}
        >
          Check In{" "}
        </button>
        <button
          className={` font-semibold border-[1px] border-[#797979] px-4 py-2 rounded-[20px] 
                       hover:text-white hover:bg-[#34B2C0] hover:border-[#34B2C0] transition-all      
                         ${
                           filter === "bookings"
                             ? "text-[white] bg-[#34B2C0] border-[#34B2C0] "
                             : "text-[#797979]  "
                         }
                       `}
          onClick={() => setFilter("bookings")}
        >
          Bookings{" "}
        </button>
        <button
          className={` font-semibold  border-[1px] border-[#797979] px-4 py-2 rounded-[20px] 
          hover:text-white hover:bg-[#34B2C0] hover:border-[#34B2C0] transition-all      
            ${
              filter === "rent"
                ? "text-[white] bg-[#34B2C0] border-[#34B2C0] "
                : "text-[#797979]  "
            }
          `}
          onClick={() => setFilter("rent")}
        >
          Rent Contracts{" "}
        </button>
      </div>
      <div className="flex flex-col mt-4 gap-4">
        {filter === "all" || filter === "checkin" ? (
          <div className="w-[90%] h-fit p-4 border-[1px] border-[#EFEFEF] rounded-[10px] gap-10 flex justify-between">
            <div>
              <div className="flex gap-5 text-[18px]">
                <span style={{ fontFamily: "gilroy-bold" }}>
                  {new Date().toDateString()}
                </span>
                <span>{new Date().toLocaleTimeString()}</span>
              </div>
              <div className="mt-2 text-[16px]">
                Aser Nabil checked in a shared space in downtown between
                Oct-Dec, 2022, 6 hrs, and paid 100 coins. Status Pending
              </div>
            </div>
            <div className="min-w-[150px] text-[16px] text-[#797979] font-semibold border-[1px] border-[#EFEFEF] h-fit text-center p-2 rounded-[20px]">
              Check in
            </div>
          </div>
        ) : null}

        {filter === "all" || filter === "rent" ? (
          <div className="w-[90%] h-fit p-4 border-[1px] border-[#EFEFEF] rounded-[10px] gap-10 flex justify-between">
            <div>
              <div className="flex gap-5 text-[18px]">
                <span style={{ fontFamily: "gilroy-bold" }}>
                  {new Date().toDateString()}
                </span>
                <span>{new Date().toLocaleTimeString()}</span>
                <span>
                  {" "}
                  Status{" "}
                  <span className="font-semibold text-[#34B2C0]">Pending</span>
                </span>
              </div>
              <div className="mt-2 text-[16px]">
                Aser Nabil checked in a shared space in downtown between
                Oct-Dec, 2022, 6 hrs, and paid 100 coins.
              </div>
            </div>
            <div className="min-w-[150px] text-[16px] text-[#797979] font-semibold border-[1px] border-[#EFEFEF] h-fit text-center p-2 rounded-[20px]">
              Rent Contracts
            </div>
          </div>
        ) : null}

        {filter === "all" || filter === "bookings" ? (
          <div className="w-[90%] h-fit p-4 border-[1px] border-[#EFEFEF] rounded-[10px] gap-10 flex justify-between">
            <div>
              <div className="flex gap-5 text-[18px]">
                <span style={{ fontFamily: "gilroy-bold" }}>
                  {new Date().toDateString()}
                </span>
                <span>{new Date().toLocaleTimeString()}</span>
                <span>
                  {" "}
                  Status{" "}
                  <span className="font-semibold text-[#34B2C0]">Pending</span>
                </span>
              </div>
              <div className="mt-2 text-[16px]">
                Aser Nabil checked in a shared space in downtown between
                Oct-Dec, 2022, 6 hrs, and paid 100 coins.
              </div>
            </div>
            <div className="min-w-[150px] text-[16px] text-[#797979] font-semibold border-[1px] border-[#EFEFEF] h-fit text-center p-2 rounded-[20px]">
              Bookings
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ActivityLogs;
