import React, { useState } from "react";
import profile from "../../../assets/profile.png";
import timeIcon from "../../../assets/icons/timeIcon.png";
import ModalComponent from "../../../components/Modal/Modal2";
import phoneICon from "../../../assets/icons/phoneIcon.png";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button/Button";
import { http } from "../../../utils/httpCommon";
import { toast } from "react-hot-toast";
import moment from "moment";
import whiteEdit from "../../../assets/icons/whiteEdit.png";
import EditNoteModal from "./editNoteModel";

const CheckedInCardModal = ({ open, handleClose, setOpen, response, fetchData }) => {
  const [notes, setNotes] = useState(response?.notes || '');
  const [isLoading, setIsLoading] = useState(false);
  const handleCheckout = async () => {
    setIsLoading(true);
    try {
      await http.post(
        "/checks/out",
        JSON.stringify({
          userId: response?.user?._id,
          location: response?.location?._id,
        })
      );
      setIsLoading(false);
      toast.success("Client has been checked out successfully");
      setOpen(false);
      fetchData();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    }
  };


  const [openEditNoteModal, setOpenEditNoteModal] = useState(false);
  const handleEditNoteModal = (updatedNotes) => {
    setOpenEditNoteModal(true);
  }

  const handleNotesUpdate = (updatedNotes) => {
    setNotes(updatedNotes);  // Update notes in parent
    fetchData();  // Re-fetch data on the main page
  };

  return (

    <ModalComponent size={"350px"} opened={open} setOpened={setOpen} onClose={handleClose}>

      <EditNoteModal
        data={response}
        fetchData={fetchData}
        opened={openEditNoteModal}
        setOpened={setOpenEditNoteModal}
        onNotesUpdate={handleNotesUpdate}
      />

      <div className="w-[300px]  ">
        <div className="flex justify-between">
          <div className="w-1/2">
            <img
              className="w-[63px] h-[69px] rounded-[10px]  object-cover"
              src={response?.user?.profilePicture ? response?.user?.profilePicture : profile}
              alt="profile"
            />
            <div className="font-bold mt-[10px] text-[18px]">{response.name}</div>
          </div>

          <div className="w-1/2">
            {response?.checkOutTime ? (
              <span className="text-[#797979] text-[16px] h-[40px] bg-[#79797933] font-bold py-[8px] px-[16px] rounded-[23px]">
                Checked out
              </span>
            ) : (
              <span className="text-[#34B2C0] text-[16px] h-[40px] bg-[#34b2c033] font-bold py-[8px] px-[16px] rounded-[23px]">
                Checked in now
              </span>
            )}{" "}
          </div>
        </div>

        {response.user?.company && (
          <div
            className="text-[#797979]
            text-[18px]"
          >
            Company
            <br />
            {response?.user?.company?.name}
          </div>
        )}

        <div className="mt-[10px] ">
          <span className="text-[#797979] text-[18px] ">Checked in at</span>
          <br />
          <span className="font-bold text-[16px]">{response?.date}</span>
        </div>

        <div className="flex items-center gap-3 my-[10px] text-[18px] ">
          <img className="w-[12px] " src={phoneICon} alt="phone" />
          {response?.user?.phone}
        </div>
        <div className="mt-[15px]">
          <span className="text-[#797979] text-[18px] ">Check in Duration</span>
          <br />
          <span className="flex items-start text-[16px]">
            <img
              className=" w-[18px] h-[18px] mt-[2px]  object-contain mr-[5px]"
              src={timeIcon}
              alt="timeIcon"
            />
            <span className="flex flex-col">
              <span className="font-bold">
                {response?.startDate} -{" "}
                {response?.checkOutTime ? moment(response?.checkOutTime).format("LT") : "Now"}{" "}
              </span>
              {response.duration && (
                <span className="text-[#797979] ">{response?.duration} hours</span>
              )}
            </span>
          </span>
        </div>

        <div className="mt-[15px]">

          <div className="flex justify-between items-center">
            <p className="text-[#797979] text-[18px] ">Notes</p>


            <button onClick={() => handleEditNoteModal()} className="flex items-center">
            <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] group-hover:bg-[#32B3BE]">
              <img className=" w-[18px] " src={whiteEdit} alt="edit" />
              </div>

            </button>
          </div>

          <p className="font-bold text-[16px]">{response?.notes || "No Notes"}</p>
        </div>

        <div className="mt-[10px] ">
          <div className=" text-[#011516] font-medium text-[18px]">Location</div>

          <div className="flex gap-[10px] px-1 py-2 border-[1px] border-[#79797933] rounded-[10px]">
            <img
              src={response?.location?.mainImage}
              alt={response?.location?.name}
              className="w-[56px] h-[56px] object-cover rounded-[10px]"
            />
            <div className="flex flex-col h-full gap-[5px] text-[18px]">
              <span className="font-bold ">{response?.location?.name}</span>
              <Link
                to={`/inventory/location/${response?.location?._id}`}
                className="text-[#34B2C0] font-bold underline cursor-pointer text-[16px]"
              >
                Quick View
              </Link>
            </div>
          </div>
        </div>

        {response?.checkOutTime ? (
          <></>
        ) : (
          <div className="mt-6 flex  text-[18px] ">
            <Button
              loading={isLoading}
              type="submit"
              onClick={handleCheckout}
              style={{ width: "100%" }}
            >
              Check out
            </Button>
          </div>
        )}
      </div>
    </ModalComponent>
  );
};

export default CheckedInCardModal;
