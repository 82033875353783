import React from "react";
import { Layout } from "../../../../components/Layout/Layout";
import { useState } from "react";
import { http } from "../../../../utils/httpCommon";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import LoadingCircle from "../../../../components/LoadingCircle/LoadingCircle";
import whiteEdit from "../../../../assets/icons/whiteEdit.png";
import archiveImg from "../../../../assets/icons/archive.png";
import DeleteVas from "./DeleteVas";
import ArchiveVas from "./ArchieveVas";
import UnarchiveVas from "./UnarchiveVas";

const VasPage = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [openArchiveVasModal, setOpenArchiveVasModal] = useState(false);
  const [openUnArchiveVasModal, setOpenUnArchiveVasModal] = useState(false);
  const [openDeleteVasModal, setOpenDeleteVasModal] = useState(false);
  const params = useParams();
  const navigator = useNavigate();

  const getData = async () => {
    try {
      const res = await http.get(`/vas/${params?.id}`);
      setData(res?.data?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout>
      <ArchiveVas
        opened={openArchiveVasModal}
        setOpened={setOpenArchiveVasModal}
        name={data?.name}
        id={data?._id}
      />{" "}
      <UnarchiveVas
        opened={openUnArchiveVasModal}
        setOpened={setOpenUnArchiveVasModal}
        name={data?.name}
        id={data?._id}
      />
      <DeleteVas
        opened={openDeleteVasModal}
        setOpened={setOpenDeleteVasModal}
        name={data?.name}
        id={data?._id}
      />
      {loading ? (
        <LoadingCircle />
      ) : (
        <div className="flex gap-6 bg-white m-4 rounded-[10px]  h-full w-[95%] pt-10 px-4">
          <div className="w-1/5">
            <img src={data?.image} alt={data?.name} className="w-[315px]" />
          </div>
          <div className="w-4/5">
            <div className="flex gap-8">
              <button
                onClick={() => navigator(`/operations/vas/edit-vas/${data?._id}`)}
                className="text-[16px] group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
              >
                <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                  <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                </div>
                Edit Product
              </button>

              {data?.isDeleted ? (
                <button
                  onClick={() => setOpenUnArchiveVasModal(true)}
                  className="text-[16px] group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                >
                  <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                    <img className=" w-[18px] " src={archiveImg} alt="edit" />
                  </div>
                  Unarchive Product
                </button>
              ) : (
                <button
                  onClick={() => setOpenArchiveVasModal(true)}
                  className="text-[16px] group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                >
                  <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                    <img className=" w-[18px] " src={archiveImg} alt="edit" />
                  </div>
                  Archive Product
                </button>
              )}

              <button
                onClick={() => setOpenDeleteVasModal(true)}
                className="text-[16px] group text-[#686868] flex items-center hover:text-[#E41D1D] transition-all "
              >
                <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#E41D1D]">
                  <img className=" w-[18px] " src={archiveImg} alt="edit" />
                </div>
                Delete Product
              </button>
            </div>

            <div className="mt-6">
              <div className="text-[24px]" style={{ fontFamily: "gilroy-bold" }}>
                {data?.name}
              </div>
              <div className=" text-[#797979] w-fit min-w-[80px] mt-1 text-[15px] bg-[#EFEFEF]  font-semibold p-2 flex  justify-center items-center rounded-[25px]  ">
                {data?.type}
              </div>
              <div className="text-[20px] mt-1" style={{ fontFamily: "gilroy-bold" }}>
                {data?.price} EGP
              </div>
              <div className=" text-[#797979] w-fit  text-[15px]   font-semibold  ">Per hour</div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default VasPage;
