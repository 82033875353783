import React from "react";
import { Button } from "../../../../components/Button/Button";
import { TextInput } from "../../../../components/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ModalComponent from "../../../../components/Modal/Modal2";
import { http } from "./../../../../utils/httpCommon";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const ArchiveVas = ({ opened, setOpened, name, id }) => {
  const schema = yup
    .object({
      reasons: yup.string(),
    })
    .required();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigator = useNavigate();
  const onSubmit = async (passedData) => {
    try {
      await http.put(
        `/vas/${id}/archive`,
        JSON.stringify({
          archive: true,
        })
      );
      setOpened(false);
      toast.success("VAS has been archived successfully");
      navigator("/operations/vas");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleOnClose = () => {
    setOpened(false);
    reset();
  };

  return (
    <ModalComponent opened={opened} setOpened={setOpened} onClose={handleOnClose}>
      <div className="flex flex-col justify-center items-center">
        <div className="text-[25px] font-semibold">Archive "{name}" VAS</div>
        <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="mt-4 ">
            <TextInput label={"Reasons"} {...register("reasons")} />
            <p className="text-red-500">{errors.moreInfo?.message}</p>
          </div> */}
          <div className="mt-10  mb-10 flex justify-center gap-10">
            <Button onClick={() => setOpened(false)} secondary={true} invert={true}>
              Cancel
            </Button>
            <Button type="submit" secondary={true}>
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </ModalComponent>
  );
};

export default ArchiveVas;
