import React, { useCallback, useEffect, useState } from "react";
import Table from "../../../components/DataGrid/Table";
import ProfileImg from "../../../assets/profile.png";
import { Link, useNavigate } from "react-router-dom";
import { http } from "../../../utils/httpCommon";
import { debounce } from "lodash";
import { toast } from "react-hot-toast";

const AdminsTable = () => {
  const navigator = useNavigate();
  const [searchQuery, setSearchQuery] = React.useState("");

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    debounced(event.target.value, 600);
  };

  ///onFilterChange
  const onFilterChange = useCallback(async (filterModel) => {
    try {
      let searchVal = filterModel?.items[0]?.value;

      if (searchVal?.length > 0) {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }));

        const res = await http.post(
          `/admin`,
          JSON.stringify({
            page: pageState.page,
            limit: pageState.pageSize,
            textField: searchVal,
          })
        );

        let filteredData = res?.data?.data?.data;

        const rowsData = filteredData?.map((d) => {
          const locations = d?.locations?.map((location) => location.name);

          return {
            id: d._id,
            name: d?.name,
            email: d?.email,
            phone: d?.phone,
            company: d?.company?.name,
            role: d?.role,
            location: locations,
            imageUrl: d?.imageUrl,
          };
        });
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: rowsData,
          total: res?.data?.data?.count,
        }));
      } else {
        debounced();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    }
  }, []);

  const columns = [
    {
      field: "Images",
      headerName: "",
      width: 120,
      filterable: false,
      sortable: false,

      // editable: true,
      renderCell: (params) => {
        return (
          <Link
            to={`/settings/admin/${params.row.id}`}
            className="flex items-center h-[80px]  my-1"
          >
            {params?.row?.imageUrl ? (
              <img
                className="w-[66px] h-[66px]  rounded-full object-cover "
                src={params?.row?.imageUrl}
                alt={params.row.imageUrl}
              />
            ) : (
              <>
                <img
                  className="w-[66px] h-[66px]  rounded-full object-cover "
                  src={ProfileImg}
                  alt={params?.row?.imageUrl}
                />
              </>
            )}
            {/* {params.row.name} */}
          </Link>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,

      renderCell: (params) => {
        return (
          <Link
            to={`/settings/admin/${params.row.id}`}
            className="flex items-center  font-semibold  text-[16px]  py-2 hover:text-[#34B2C0]"
          >
            {params?.row?.name}
          </Link>
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      // editable: true,
    },

    {
      field: "email",
      headerName: "Email",
      width: 260,
      // editable: true,
    },

    {
      field: "location",
      headerName: "Location",
      width: 200,

      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center">
            <span className="text-[16px] ml-4">
              {params?.row?.location.map((l, index) => (
                <span key={l}>
                  {l}
                  {index !== params.row.location.length - 1 && " - "}
                </span>
              ))}
            </span>
          </div>
        );
      },
    },

    {
      field: "role",
      headerName: "Role",
      width: 200,

      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] text-[16px] items-center w-[160px]">
            {params?.row?.role === "Admin" ? (
              <div className=" text-[#797979] bg-[#EFEFEF]   text-[15px] font-semibold p-2 flex  justify-center items-center rounded-[25px]  ">
                {params?.row?.role}
              </div>
            ) : params?.row?.role === "Super Admin" ? (
              <div className=" text-[#34B2C0]   text-[15px] bg-[#3bb2c0] bg-opacity-20 font-semibold p-2 flex  justify-center items-center rounded-[25px]   ">
                {params?.row?.role}
              </div>
            ) : (
              <div className=" text-[#797979] text-[15px] bg-[#EFEFEF]  font-semibold p-2 flex  justify-center items-center rounded-[25px]  ">
                {params?.row?.role}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const handleActionButton = () => {
    navigator("/settings/admins/add-admin");
  };

  ///Fetching new Data
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const fetchData = async (searchValues) => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const res = await http.post(
        `/admin`,
        JSON.stringify({
          page: pageState.page,
          limit: pageState.pageSize,
          textField: searchValues,
        })
      );

      let filteredData = res?.data?.data?.data;

      const rowsData = filteredData?.map((d) => {
        const locations = d?.locations?.map((location) => location.name);

        return {
          id: d._id,
          name: d?.name,
          email: d?.email,
          phone: d?.phone,
          company: d?.company?.name,
          role: d?.role,
          location: locations,
          imageUrl: d?.imageUrl,
        };
      });
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.data?.count,
      }));
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    }
  };
  const debounced = debounce(fetchData, 1000);

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize]);

  return (
    <>
      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState.total}
        isLoading={pageState.isLoading}
        data={pageState?.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        keys={["id", "name", "phone", "email", "location", "role"]}
        title={"Companies"}
        actionButton={"Add Admin"}
        onFilterChange={onFilterChange}
        handleActionButton={handleActionButton}
      />
    </>
  );
};

export default AdminsTable;
