import React, { useCallback, useEffect, useState } from "react";
import ProfileImg from "../../../../assets/profile.png";
import { Link } from "react-router-dom";
import { http } from "../../../../utils/httpCommon";
import { debounce } from "lodash";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  useGridRootProps,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { TextInput } from "@mantine/core";
import columnIcon from "../../../../assets/columns.svg";
import filtersIcon from "../../../../assets/filter.svg";
import downloadIcon from "../../../../assets/download.svg";
import densityIcon from "../../../../assets/density.svg";
import { IconButton } from "@mui/material";
import { Search } from "tabler-icons-react";

const ClientsTable = ({ setStep, rowSelectionModel, setRowSelectionModel }) => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    debounced(event.target.value, 600);
  };

  ///onFilterChange
  const onFilterChange = useCallback(async (filterModel) => {
    let searchVal = filterModel?.items[0]?.value;

    if (searchVal?.length > 0) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const res = await http.post(
        `/client/all`,
        JSON.stringify({
          page: pageState.page,
          limit: pageState.pageSize,
          textField: searchVal,
        })
      );

      let filteredData = res?.data?.data?.data?.filter((item) => !item?.isDeleted);
      const rows = filteredData?.map((d) => ({
        id: d?._id,
        ...d,
      }));

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rows,
        //get from another api
        total: res?.data?.data?.count,
      }));
    } else {
      debounced();
    }
  }, []);

  const fetchData = async (searchValues) => {
    setPageState((old) => ({
      ...old,
      isLoading: true,
    }));

    const res = await http.post(
      `/client/all`,
      JSON.stringify({
        page: pageState.page,
        limit: pageState.pageSize,
        textField: searchValues,
      })
    );

    let filteredData = res?.data?.data?.data?.filter((item) => !item?.isDeleted);
    const rows = filteredData?.map((d) => ({
      id: d?._id,
      ...d,
    }));
    setPageState((old) => ({
      ...old,
      isLoading: false,
      data: rows,
      total: res?.data?.data?.count,
    }));
  };

  const debounced = debounce(fetchData, 1000);

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize, searchQuery]);

  const columns = [
    {
      field: "Images",
      headerName: "",
      width: 120,
      filterable: false,
      sortable: false,

      renderCell: (params) => {
        return (
          <Link
            to={`/community/client/${params.row.id}`}
            className="flex items-center h-[75px]  py-2 my-1"
          >
            {params?.row?.profilePicture ? (
              <img
                className="w-[70px] h-[70px]  rounded-full object-cover "
                src={params?.row?.profilePicture}
                alt={params?.row?.profilePicture}
              />
            ) : (
              <>
                <img
                  className="w-[70px] h-[70px]  rounded-full object-cover "
                  src={ProfileImg}
                  alt={params?.row?.profilePicture}
                />
              </>
            )}
          </Link>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 180,

      renderCell: (params) => {
        return (
          <Link
            to={`/community/client/${params?.row?.id}`}
            className="flex items-center  font-bold  text-[16px]  py-2 hover:text-[#34B2C0]"
          >
            {params?.row?.name}
          </Link>
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 180,
    },

    {
      field: "email",
      headerName: "email",
      width: 250,
    },
    {
      field: "company",
      headerName: "Company",
      width: 180,
      renderCell: (params) => {
        return (
          <Link
            to={`/community/company/${params?.row?.company?._id}`}
            className="flex items-center  font-semibold  text-[16px]  py-2 hover:text-[#34B2C0]"
          >
            {params?.row?.company?.name}
          </Link>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center w-[140px]">
            <div
              className={` ${
                // params?.row?.status !== "open"
                // ?
                ""
                // : "text-[#34B2C0]  bg-[#3bb2c0]"
              }
              text-[#797979] bg-[#a09f9f] bg-opacity-20  font-semibold p-2 text-[16px] flex  justify-center items-center rounded-[25px]   `}
            >
              Not on the app
            </div>
          </div>
        );
      },
    },
  ];

  const handleActionButton = () => {
    setStep(2);
    // navigator("/community/clients/new-client");
  };

  const GridCustomToolbar = React.forwardRef(function GridToolbar(props, ref) {
    const { className, ...other } = props;
    const rootProps = useGridRootProps();

    if (
      rootProps.disableColumnFilter &&
      rootProps.disableColumnSelector &&
      rootProps.disableDensitySelector
    ) {
      return null;
    }

    return (
      <>
        <GridToolbarContainer className="flex " ref={ref} {...other}>
          <div className="flex justify-end gap-[5px] items-center w-1/2 mx-2 ">
            <div className="flex gap-[5px] ">
              <GridToolbarColumnsButton
                startIcon={<img className="w-3 h-4 " src={columnIcon} alt="Column" />}
              />
              <GridToolbarFilterButton
                componentsProps={{
                  button: {
                    startIcon: (
                      <IconButton sx={{ backgroundColor: "#f8f9fa !important" }}>
                        <img className="w-3 h-4 " src={filtersIcon} alt="filter icon" />
                      </IconButton>
                    ),
                  },
                }}
              />
              <GridToolbarExport
                startIcon={<img className="w-3 h-4 " src={downloadIcon} alt="Download " />}
              />
              <GridToolbarDensitySelector
                startIcon={<img className="w-3 h-4 " src={densityIcon} alt="Column" />}
              />
            </div>
            {rowSelectionModel?.length > 0 ? (
              <button
                onClick={() => handleActionButton()}
                className="bg-black h-[60px] px-2 text-[16px] font-extrabold min-w-[100px] rounded-[10px] text-white transition-all duration-100 "
              >
                Confirm selection
              </button>
            ) : null}
          </div>
        </GridToolbarContainer>
      </>
    );
  });

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => pageState.data.find((row) => row._id === id));
    setRowSelectionModel(selectedRowsData);
  };
  return (
    <>
      <div className=" bg-[#f8f9fa] relative  mt-0">
        <div>
          <TextInput
            style={{ backgroundColor: "#f8f9fa !important" }}
            label=""
            placeholder="Search..."
            radius="md"
            size="lg"
            className="absolute w-[40%]   bg-[#f8f9fa]  top-3  left-[0%] z-50"
            value={searchQuery}
            onChange={handleChange}
            icon={<Search size={24} />}
          />
        </div>
        <DataGrid
          getRowHeight={() => "auto"}
          sx={{
            boxShadow: 0,
            border: 0,
            width: "100%",
            borderColor: "white",
            paddingTop: "10px",
            backgroundColor: "#f8f9fa",

            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              // padding: "1px",
            },
            "& .MuiDataGrid-footerContainer ": {
              backgroundColor: "white",
              paddingBottom: "10px",
              borderRadius: " 0 0 10px 0",
            },

            "& .MuiButtonBase-root": {
              padding: 0,
              margin: 0,
            },
            borderTop: "none",
            "& .MuiDataGrid-root": {
              marginTop: "0 !important",
            },
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiButton-text": {
              color: "rgba(0, 0, 0, 0.54) !important",
            },
            " & .MuiDataGrid-root": {
              backgroundColor: "white !important",
            },
            "& .MuiDataGrid-main": {
              background: "white",
              marginTop: "20px",
              width: "100%",
              borderRadius: "10px",
            },
            "& .MuiDataGrid-toolbarContainer": {
              display: " flex ",
              flexDirection: "row",
              position: "relative",
              justifyContent: "flex-end",
              backgroundColor: "#f8f9fa",
              padding: "5px 0 ",
            },

            "& .MuiButton-root": {
              padding: "8px",
              backgroundColor: "#f8f9fa !important",
              border: "1px solid #DEDEDE",
              margin: "0 5px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "60px",
              width: "70px",
              fontSize: "11px",
              marginBottom: "0px",
            },
            "& .MuiSvgIcon-root": {
              fontSize: "1.5rem",
            },
            "& .MuiDataGrid-columnHeaders": {
              fontSize: "18px",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "600",
            },
            "& .MuiDataGrid-cellContent": {
              fontSize: "16px",
            },
            "& .MuiIconButton-root": {
              height: "28px",
              width: "32px",
            },
          }}
          checkboxSelection
          autoHeight
          rowCount={pageState.total}
          loading={pageState.isLoading}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          pagination
          responsive
          rows={pageState.data}
          page={pageState.page - 1}
          pageSize={pageState.pageSize}
          density="comfortable"
          paginationMode="server"
          onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
          onPageSizeChange={(newPageSize) =>
            setPageState((old) => ({ ...old, pageSize: newPageSize }))
          }
          filterMode="server"
          onFilterModelChange={onFilterChange}
          columns={columns}
          components={{ Toolbar: GridCustomToolbar }}
          componentsProps={{}}
          disableSelectionOnClick
          onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
        />
      </div>
    </>
  );
};

export default ClientsTable;
