import moment from "moment";
import { useEffect, useState } from "react";

export function useTimeSelection({ responseData }) {
  //   console.log(responseData);
  const [components, setComponents] = useState(
    responseData.days.map((day) => ({
      dateValue: moment(day.date),
      startTimeValue: moment(day.startTime * 1000),
      endTimeValue: moment(day.endTime * 1000),
    }))
  );

  return {
    components,
    setComponents,
  };
}
