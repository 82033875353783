import React, { useState, useEffect } from "react";
import { Layout } from "../../../components/Layout/Layout";
import { Button } from "../../../components/Button/Button";
import { http } from "../../../utils/httpCommon";
import { TextInput } from "../../../components/TextInput/TextInput";
import CustomTextInput from "../../../components/CustomTextInput/CustomTextInput";
import { useForm } from "react-hook-form";
import useFetchData from "../../../hooks/FetchData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DropFile from "../../../components/DropFile/DropFile";
import { useNavigate, useParams } from "react-router-dom";
import flag from "../../../assets/EgyptFlag.png";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { toast } from "react-hot-toast";
import { useAuthUser } from "react-auth-kit";

let roles = ["Super Admin", "Area Manager", "Branch Manager", "Experience Officer", "Land Lord"];

const EditAdmin = () => {
  const navigator = useNavigate();
  const params = useParams();
  const [userRole, setUserRole] = useState("");

  //fetch admin
  const { response: admin, loading: loadingAdmin } = useFetchData(`/admin/${params.id}`);
  //fetch locations
  const { response: locations, loading } = useFetchData("/location/me");
  const phoneRegExp = /^(?:)?1[0-9]{9}$/;

  //schema for admin
  const schema = yup
    .object({
      name: yup.string().required(),
      phone: yup
        .string()
        .matches(phoneRegExp, "Please enter a valid Egypt phone number")
        .required("Phone number is required"),
      email: yup.string().email().typeError("Email is a required filed ").required(),
      role: yup.string().required().typeError("Role is a required filed "),
      location:
        userRole === "Super Admin"
          ? yup.array().of(yup.string()).nullable()
          : yup.array().of(yup.string()).min(1).required(),
      birthdate: yup.string(),
    })
    .required();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [mainImage, setMainImages] = useState([]);
  const [submitError, setSubmitError] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const setCustomValue = (id, value) => {
    setValue(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const onSubmit = async (passedData) => {
    setSubmitLoading(true);
    var data = new FormData();
    data.append("image", mainImage[0]);
    try {
      let res = await http.post("/image", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      try {
        var data2 = JSON.stringify({
          name: passedData.name,
          birthday: passedData.birthdate,
          email: passedData.email,
          locations: passedData.location,
          imageUrl: res.data.url,
          phone: "+20" + passedData.phone,
          role: passedData.role,
        });
        await http.put(`/admin/${admin._id}`, data2, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setSubmitLoading(false);
        toast.success("Admin has been Edited successfully");
      } catch (error) {
        setSubmitLoading(false);
        toast.error(error.response?.data.message || error.message || "Something went wrong");
        setSubmitError(error.response.data.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    }
  };

  const auth = useAuthUser();
  const [birthdate, setBirthDate] = React.useState("");
  const handleChange = (newValue) => {
    setValue("birthdate", newValue);
    setBirthDate(newValue);
  };
  const [checkedRole, setCheckedRole] = useState("");
  const handleCheckedRole = (role) => {
    if (checkedRole === role) {
      setCheckedRole("");
      setUserRole("");
    } else {
      setUserRole(role);
      setCheckedRole(role);
    }
  };

  useEffect(() => {
    setCheckedRole(admin?.role);
    setValue("role", admin?.role);
    setSelectedLocations(admin?.locations?.map((l) => l._id));
    setBirthDate(admin?.birthday);
    setMainImages([{ name: admin?.imageUrl, preview: admin?.imageUrl }]);
  }, [loadingAdmin]);

  const [selectedLocations, setSelectedLocations] = useState([]);

  const handleChangeSelectLocations = (event) => {
    // const value = Array.from(event.target.value);
    const value = event.target.value;
    setCustomValue("location", value);
    setSelectedLocations(value);
  };

  return (
    <Layout>
      {!loading && !loadingAdmin ? (
        <div className="flex justify-center">
          <div className="w-[70%] bg-white p-4">
            <div className="flex flex-col">
              <div className="w-full flex justify-between">
                <span className=" text-[22px] font-bold my-3">Edit Admin</span>
              </div>
              <span className="text-[16px]">Upload profile pic</span>
              <span className="text-[16px]">Supported formate: JPEG, PNG</span>
              <br />
            </div>
            <div className="flex gap-[20px] ">
              <div className="min-w-[40%] w-full h-[280px] mb-0 py-2">
                <DropFile
                  image={mainImage}
                  setImage={setMainImages}
                  height={"280px"}
                  width={"600px"}
                  message={"Admin image"}
                  bgColor="white"
                  fit="object-contain"
                />
              </div>
            </div>

            {/* form  */}
            <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-6">
                <TextInput
                  label={"Name"}
                  defaultValue={admin?.name}
                  {...register("name", { required: "input name is required" })}
                />
                <p className="text-red-500">{errors.name?.message}</p>
              </div>

              <div className="mt-3">
                <TextInput
                  label={"Email"}
                  defaultValue={admin?.email}
                  {...register("email", { required: "Email  is required" })}
                />
                <p className="text-red-500">{errors.email?.message}</p>
              </div>
              <div className="mt-3">
                <CustomTextInput
                  label={"Phone Number"}
                  defaultValue={
                    admin?.phone?.charAt(0) === "+" ? admin?.phone.substring(3) : admin?.phone
                  }
                  adornment={
                    <>
                      <img className=" w-5 h-fit  " src={flag} alt="Egypt flag" />{" "}
                      <span className="mx-4 text-black"> +20</span>
                    </>
                  }
                  {...register("phone", {
                    required: "Phone number is required",
                  })}
                />
                <p className="text-red-500">{errors.phone?.message}</p>
              </div>
              <div className="mt-3 w-full">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Birth date"
                    inputFormat="MM/DD/YYYY"
                    value={birthdate}
                    defaultValue={admin?.birthday}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          width: "100%",
                          color: "#000000cc",
                          "& .MuiFormControl-root ": {
                            borderColor: "#EFEFEF",
                          },
                          "& .MuiInputBase-root": {
                            "& fieldset": {
                              borderColor: "#EFEFEF !important",
                            },
                            "&:hover fieldset": {
                              borderColor: "black !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#207beb !important",
                            },
                          },
                          "& .MuiFormLabel-root ": {
                            color: "#000000cc !important",
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <div className=" mt-3 flex flex-wrap gap-[2px] relative w-full h-fit py-6 bg-white border-[1px] border-[#EFEFEF] rounded-[5px] hover:border-[black] ">
                <div className="absolute bg-white p-1 -top-4 text-[16px] left-2 text-[#00000099] ">
                  Role (You can select only one role)
                </div>

                {roles.map((item) => {
                  return (
                    <div
                      key={item}
                      className={` ml-2 mt-1 cursor-pointer hover:bg-[#32B3BE] hover:text-[white] transition-all 
                    ${checkedRole === item ? "bg-[#32B3BE]" : ""} ${
                        checkedRole === item ? "text-[#FFFFF1]" : ""
                      }
                      ${checkedRole === item ? "font-semibold" : "font-semibold"}
                      text-[#32B3BE] border-[1px] text-[16px] flex justify-center items-center py-3  border-[#32B3BE] 
                      rounded-full w-[160px]  `}
                      onClick={() => {
                        item === checkedRole ? setValue("role", null) : setValue("role", item);
                        handleCheckedRole(item);
                      }}
                      {...register("role", { required: "role is required" })}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
              <p className="text-red-500">{errors.role?.message}</p>

              <div className="mt-3">
                <FormControl fullWidth>
                  <InputLabel id="select-multiple-locations">
                    Select Locations {userRole === "Super Admin" ? "" : "*"}
                  </InputLabel>
                  <Select
                    labelId="Select Locations"
                    id="Select Locations"
                    label="Select Locations"
                    multiple
                    value={selectedLocations}
                    onChange={handleChangeSelectLocations}
                  >
                    {locations
                      ?.filter((l) => !l.isDeleted)
                      ?.map((l) => (
                        <MenuItem key={l._id} value={l._id}>
                          {l.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <p className="text-red-500 text-[16px]">{errors.location?.message}</p>
              </div>

              <p className="text-red-500 my-3">{submitError}</p>
              <div className="mt-8 mb-10 gap-[50px] flex justify-end">
                <Button onClick={() => navigator("/settings")} secondary={true} invert={true}>
                  Cancel
                </Button>
                <Button loading={submitLoading} secondary={true} type="submit">
                  Edit Admin
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <LoadingCircle />
      )}
    </Layout>
  );
};

export default EditAdmin;
