import React from "react";
import { Button } from "../../../../components/Button/Button";
import DeleteImage from "../../../../assets/icons/deleteIcon1.png";
import ModalComponent from "../../../../components/Modal/Modal2";
import { http } from "../../../../utils/httpCommon";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const DeleteClient = ({ opened, setOpened, name, id }) => {
  const navigator = useNavigate();
  const onSubmit = async (passedData) => {
    try {
      await http.delete(`/client/${id}`);
      setOpened(false);
      toast.success("Client has been deleted successfully");
      navigator("/community");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleOnClose = () => {
    setOpened(false);
  };

  return (
    <ModalComponent opened={opened} setOpened={setOpened} onClose={handleOnClose}>
      <div className="flex justify-center flex-col items-center gap-[25px]">
        <img className="w-[80px]" src={DeleteImage} alt="delete" />
        <div className=" text-[25px] font-bold ">Delete "{name}"</div>
        <div className="text-center text-[18px]">Are you sure you want to delete {name} ?</div>
        <div className="flex  gap-[40px]">
          <Button onClick={() => setOpened(false)} secondary={true} invert={true}>
            Cancel
          </Button>
          <Button onClick={onSubmit} secondary type="submit">
            Confirm
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default DeleteClient;
