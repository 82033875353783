import React from "react";
import useFetchPostData from "../../../hooks/FetchPostData";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import TransactionsTable from "./TransactionsTable";
import useFetchData from "../../../hooks/FetchData";

const Transactions = () => {
  // const { response, loading, fetchData } = useFetchData("/transactions");

  return (
    <>
      {/* {loading ? (
        <LoadingCircle />
      ) : ( */}
      <TransactionsTable />
      {/* )} */}
    </>
  );
};

export default Transactions;
