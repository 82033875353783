import React from "react";
import { http } from "../utils/httpCommon";
import { useState, useEffect } from "react";

const useFetchPostData = (url, passedDate) => {
  const [response, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const fetchData = async () => {
    try {
      const res = await http.post(url,passedDate);
      setData(res.data.data);
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return { response, error, loading, fetchData };
};

export default useFetchPostData;
