import React, { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "../../../assets/icons/DeleteIcon.png";
import uploadIcon from "../../../assets/uploadIcon.svg";
import { TextInput } from "../../../components/TextInput/TextInput";
import ModalComponent from "../../../components/Modal/Modal";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Button } from "../../../components/Button/Button";
import { http } from "../../../utils/httpCommon";
import { toast } from "react-hot-toast";
import { SelectedLocationContext } from "../../../Context/LocationProvider";

const ReconsiliationRequest = ({ opened, setOpened, fetchData }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    amount: "",
  });

  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    // Do something with the files
  }, []);
  const { selectedLocation } = useContext(SelectedLocationContext);
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  });

  const images = files.map((file) => (
    <img
      className="h-[120px] w-[150px] object-contain"
      key={file.name}
      src={file.preview}
      alt={file.name}
    />
  ));

  const [submitLoading, setSubmitLoading] = useState(false);
  const onSubmit = async (passedData) => {
    setSubmitLoading(false);
    var data = new FormData();
    data.append("image", files[0]);

    let imageUrl;
    try {
      const image = await http.post("/image", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      imageUrl = image.data.url;
    } catch (error) {
      toast.error(error.response?.data.message || "Error uploading image");
    }

    try {
      const payload = JSON.stringify({
        amount: +passedData.amount,
        image: imageUrl,
        location: selectedLocation,
      });

      const result = await http.post("/transactions/reconsile", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setSubmitLoading(false);
      setOpened(false);
      setFiles([]);
      reset();
      fetchData();
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data.message || "Error creating reconsilation request");
    }
  };

  return (
    <ModalComponent opened={opened} setOpened={setOpened} reset={reset}>
      <div className="text-[24px] font-bold">Request Reconsiliation</div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="  flex flex-col justify-center items-center gap-[40px] mt-4"
      >
        <div className="relative  flex   justify-center items-start mt-4 w-full">
          <div
            className="flex flex-col items-center p-5  max-h-[250px] rounded-[20px] w-[90%]   border-[1px] border-[#4987FF] border-dashed "
            style={{ backgroundColor: "rgba(73, 135, 255, 0.05" }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <>
                {/* {images.length > 0 ? (
                  <div className="relative">{images}</div>
                ) : ( */}
                <>
                  <img className="w-[100px] " src={uploadIcon} alt="upload icon" />
                  <p className=" font-medium ">Drop the icon here ...</p>
                </>
                {/* // )} */}
              </>
            ) : (
              <>
                {images.length > 0 ? (
                  <div className="relative">{images}</div>
                ) : (
                  <>
                    <img className="w-[80px] " src={uploadIcon} alt="upload icon" />
                    <p className=" font-medium ">Upload bank statement</p>
                  </>
                )}
              </>
            )}
          </div>
          {images.length > 0 ? (
            <button
              onClick={() => {
                setFiles([]);
              }}
              className="relative  flex justify-center items-center mx-2 top-6 right-16 bg-[#E4E4E4] w-[40px] h-[40px] rounded-full z-50  hover:bg-gray-100 transition-all"
            >
              <img className=" w-[15px] h-[15px]  " src={DeleteIcon} alt="delete" />
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className="w-[90%]">
          <TextInput
            label={"Amount"}
            {...register("amount", { required: "amount is required" })}
          ></TextInput>
          <ErrorMessage
            name="amount"
            errors={errors}
            render={({ message }) => <p className="text-red-500">{message}</p>}
          />
          <p className="text-red-500">{error}</p>
        </div>

        <div className="flex justify-around  w-full">
          <Button secondary={true} invert={true} onClick={() => setOpened(false)}>
            Cancel
          </Button>
          <Button loading={submitLoading} secondary={true} type="submit">
            Request
          </Button>
        </div>
      </form>
    </ModalComponent>
  );
};

export default ReconsiliationRequest;
