import React, { useContext, useState } from "react";
import { Layout } from "../../../components/Layout/Layout";
import { Button } from "../../../components/Button/Button";
import { TextInput } from "../../../components/TextInput/TextInput";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { http } from "../../../utils/httpCommon";
import stopIcon from "../../../assets/stop.svg";
import { motion } from "framer-motion";
import { SelectedLocationContext } from "../../../Context/LocationProvider";

const NewPackage = () => {
  const [packages, setPackages] = useState([
    { name: "", passes: "", price: "", discount: "", expireIn: "" },
  ]);
  const navigate = useNavigate();

  const schema = yup.object().shape({
    name: yup.string().required("Package Name is required"),
    passes: yup
      .number()
      .typeError("Passes must be a number")
      .required("Passes is required")
      .max(9999, "Passes must be at most 9999"),
    price: yup
      .number()
      .typeError("Price must be a number")
      .required("Price is required")
      .max(999999, "Price must be at most 999999"),
    discount: yup
      .number()
      .typeError("Discount must be a number")
      .required("Discount is required")
      .min(0, "Discount must be at least 0")
      .max(100, "Discount must be at most 100"),
    expireIn: yup
      .number()
      .typeError("Expire In must be a number")
      .required("Expire In is required")
      .oneOf([14, 30, 60, 90, 180], "Invalid value for Expire In"),
    priceAfterDiscount: yup
      .number()
      .typeError("Price must be a number")
      .required("Price is required")
      .max(yup.ref('price'), "Price must be at most = price"),
  });

  const { selectedLocation } = useContext(SelectedLocationContext);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      await http.post("/packages", {
        name: data?.name,
        passes: data?.passes,
        price: data?.price,
        priceAfterDiscount: data?.priceAfterDiscount,
        discount: data?.discount,
        expireIn: data?.expireIn,
        location: selectedLocation,
      });
      toast.success("Package created successfully");
      navigate("/inventory/packages"); // Navigate to desired route after success
    } catch (error) {
      if (error?.response?.data?.error?.details) {
        error?.response?.data?.error?.details?.map((err) => {
          toast.error(err.message);
        });
      } else {
        toast.error(
          error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong",
        );
      }
    }
  };
  return (
    <Layout>
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        className="flex flex-col px-4 min-h-500"
      >
        <div className="text-[30px] mb-2" style={{ fontFamily: "gilroy-bold" }}>
          New Package
        </div>

        <form className="w-[65%]" onSubmit={handleSubmit(onSubmit)}>

          <div>
            <div className="mt-5 flex justify-between w-full gap-4">
              <div className="w-1/2">
                <div>
                  <TextInput label="Package Name" {...register("name")} />
                  <p className="text-red-500 text-[14px] ">{errors.name?.message}</p>
                </div>
              </div>

              <div className="w-1/2">
                <div>
                  <TextInput label="Passes" {...register(`passes`)} />
                  <p className="text-red-500 text-[14px] ">{errors.passes?.message}</p>
                </div>
              </div>

            </div>
          </div>

          <div>

            <div className="mt-5 flex justify-between w-full gap-4">
              <div className="w-1/2">
                <div>
                  <TextInput label="Price" {...register(`price`)} />
                  <p className="text-red-500 text-[14px] ">{errors.price?.message}</p>
                </div>
              </div>

              <div className="w-1/2">
                <div>
                  <TextInput label="Discount (%)" {...register(`discount`)} />
                  <p className="text-red-500 text-[14px] ">{errors.discount?.message}</p>
                </div>
              </div>

            </div>

            <div>
              <div className="w-2/2 mt-5">
                <div>
                  <TextInput label="Price After Discount" {...register(`priceAfterDiscount`)} />
                  <p className="text-red-500 text-[14px] ">{errors.priceAfterDiscount?.message}</p>
                </div>
              </div>
            </div>

            <div className="w-[100%] mt-5">
              <FormControl fullWidth sx={{ border: "none !important" }}>
                <InputLabel> Expire In (Days)</InputLabel>
                <Controller
                  control={control}
                  name={`expireIn`}
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <Select
                      sx={{
                        color: "black",
                        backgroundColor: "white",

                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#EFEFEF",
                        },
                      }}
                      onBlur={onBlur}
                      onChange={onChange}
                      variant="outlined"
                      labelId="expireIn"
                      id="expireIn"
                      value={value || ""}
                      label="expireIn"
                      inputRef={ref}
                      defaultValue=""
                    >
                      <MenuItem value=""> None </MenuItem>
                      <MenuItem value={14}> 14 </MenuItem>
                      <MenuItem value={30}> 30 </MenuItem>
                      <MenuItem value={60}> 60 </MenuItem>
                      <MenuItem value={90}> 90 </MenuItem>
                      <MenuItem value={180}> 180 </MenuItem>
                    </Select>
                  )}
                />
              </FormControl>

              <p className="text-red-500 text-[14px] ">{errors.expireIn?.message}</p>
            </div>
          </div>

          <div className="mt-4 mb-6 flex justify-end gap-10">
            <Button onClick={() => navigate(-1)} secondary={true} invert={true}>
              Cancel
            </Button>
            <Button type="submit" secondary={true}>
              Confirm
            </Button>
          </div>
        </form>
      </motion.div>
    </Layout>
  );
};

export default NewPackage;
