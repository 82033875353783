import React, { useEffect, useState } from "react";
import { Layout } from "../../../components/Layout/Layout";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { http } from "../../../utils/httpCommon";
import { useParams } from "react-router-dom";
import Table from "../../../components/DataGrid/Table";
import { Link } from "react-router-dom";

const Subscription = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const params = useParams();

  const fetchSubscription = async () => {
    setLoading(true);
    try {
      const res = await http.get(`/packages/subscriptions/${params?.id}`);
      console.log(res.data.data);
      setResponse([res?.data?.data]);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscription();
  }, []);

  const columns = [
    {
      field: "client",
      headerName: "Client",
      width: 450,
      renderCell: (params) => {
        return (
          <Link
            to={
              params?.row?.clientType === "company"
                ? `/community/company/${params?.row?.company?._id}`
                : `/community/client/${params.row?.client?._id}`
            }
            className="flex flex-col gap-[5px] h-[80px] text-[16px]  justify-center hover:text-[#34B2C0]"
          >
            <div className="font-semibold">
              {params?.row?.clientType === "company"
                ? params.row?.company?.name
                : params.row?.client?.name}
            </div>
          </Link>
        );
      },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {new Date(params?.row?.startDate).toLocaleDateString()}
          </div>
        );
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {new Date(params?.row?.endDate).toLocaleDateString()}
          </div>
        );
      },
    },
    {
      field: "passess",
      headerName: "Passes",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.passess}
          </div>
        );
      },
    },
    {
      field: "usedPassess",
      headerName: "Used Passes",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.usedPassess}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.isActive ? "Active" : "Inactive"}
          </div>
        );
      },
    },
  ];

  const usageLogColumns = [
    {
      field: "client",
      headerName: "Client",
      width: 450,
      renderCell: (params) => {
        return (
          <Link
            to={`/community/client/${params.row?.client?._id}`}
            className="flex flex-col gap-[5px] h-[80px] text-[16px]  justify-center hover:text-[#34B2C0]"
          >
            <div className="font-semibold">
              {params?.row?.clientType === "company"
                ? params.row?.company?.name
                : params.row?.client?.name}
            </div>
          </Link>
        );
      },
    },
    {
      field: "admin",
      headerName: "Admin",
      width: 350,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.admin?.name}
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {new Date(params?.row?.date).toLocaleDateString()}
          </div>
        );
      },
    },
  ];

  return (
    <Layout>
      {loading ? (
        <LoadingCircle />
      ) : (
        <div>
          <div
            className="text-[30px]  flex items-center justify-between pr-20"
            style={{ fontFamily: "gilroy-bold" }}
          >
            Usage Log
          </div>

          <div className="flex flex-col  ">
            <Table
              hidePagination={true}
              removeSearch={true}
              checkboxSelection={false}
              disableFilters={true}
              columns={usageLogColumns}
              data={response?.[0]?.usageLog || []}
              title={"Subscriptions"}
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Subscription;
