import React, { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Next from "../../assets/arrowBottom.png";
import Prev from "../../assets/arrowTop.png";
import "./verticalCarousel.css";

const VerticalCarousel = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  // Used to determine which items appear above the active item
  const halfwayIndex = Math.ceil(data?.length / 0.6);

  // Usd to determine the height/spacing of each item
  const itemHeight = 130;

  // Used to determine at what point an item is moved from the top to the bottom
  const shuffleThreshold = halfwayIndex * itemHeight;

  // Used to determine which items should be visible. this prevents the "ghosting" animation
  const visibleStyleThreshold = shuffleThreshold / 2;

  const determinePlacement = (itemIndex) => {
    // If these match, the item is active
    if (activeIndex === itemIndex) return 0;

    if (itemIndex >= halfwayIndex) {
      if (activeIndex > itemIndex - halfwayIndex) {
        return (itemIndex - activeIndex) * itemHeight;
      } else {
        return -(data?.length + activeIndex - itemIndex) * itemHeight;
      }
    }

    if (itemIndex > activeIndex) {
      return (itemIndex - activeIndex) * itemHeight;
    }

    if (itemIndex < activeIndex) {
      if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
        return (data?.length - (activeIndex - itemIndex)) * itemHeight;
      }
      return -(activeIndex - itemIndex) * itemHeight;
    }
  };

  const handleClick = (direction) => {
    setActiveIndex((prevIndex) => {
      if (direction === "next") {
        if (prevIndex + 1 > data?.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      }

      if (prevIndex - 1 < 0) {
        return data?.length - 1;
      }

      return prevIndex - 1;
    });
  };

  return (
    <div className="container">
      <section className="outer-container">
        {data[1]?.id?.length > 0 && (
          <div className="carousel-wrapper">
            <button className="carousel-button prev" onClick={() => handleClick("prev")}>
              <img src={Prev} alt="Prev" />
            </button>

            <div className="carousel">
              <div className="slides">
                <div className="carousel-inner">
                  {data?.map((item, i) => (
                    <button
                      onClick={() => setActiveIndex(i)}
                      className={cn("carousel-item", {
                        active: activeIndex === i,
                        visible: Math.abs(determinePlacement(i)) <= visibleStyleThreshold,
                      })}
                      key={item.id}
                      style={{
                        transform: `translateY(${determinePlacement(i)}px)`,
                      }}
                    >
                      <img className="w-[110px] py-[5px] h-[110px]  rounded-[20px] object-cover  " src={item.introline} alt={item.introline} />
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <button className="carousel-button next" onClick={() => handleClick("next")}>
              <img src={Next} alt="Next" />
            </button>
          </div>
        )}
        {data[0]?.id?.length > 0 && (
          <div className="content mt-[70px]">
            <img className="h-[400px] w-full rounded-[20px] object-cover" src={data[activeIndex].introline} alt={data[activeIndex].introline} />
          </div>
        )}
      </section>
    </div>
  );
};

VerticalCarousel.propTypes = {
  data: PropTypes.array.isRequired,
};

export default VerticalCarousel;
