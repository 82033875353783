import React from "react";
import { Layout } from "../../components/Layout/Layout";
import { useState } from "react";
import Requests from "./Requests/Requests";
import ClientsTable from "./Clients/ClientTable";
import CompaniesTable from "./Companies/CompaniesTable";
import { useAuthUser } from "react-auth-kit";

const Community = () => {
  const [activeTab, setActiveTab] = useState(0);
  const auth = useAuthUser();
  const taps =
    auth().role === "Land Lord"
      ? [
          { name: "Clients", index: 0 },
          { name: "Companies", index: 1 },
          // { name: "Notifications", index: 3 },
        ]
      : [
          { name: "Clients", index: 0 },
          { name: "Companies", index: 1 },
          { name: "Requests", index: 2 },
          // { name: "Notifications", index: 3 },
        ];

  const handleActiveLink = (index) => {
    setActiveTab(index);
  };

  return (
    <Layout>
      <div className="mt-[0px] mx-[2%]">
        <div className="text-[16px] font-semibold text-[#797979]  ">Inventory</div>

        <div className="text-[32px] font-semibold  " style={{ fontFamily: "gilroy-bold" }}>
          {taps[activeTab].name}
        </div>
        {/* <div className="text-[32px] font-semibold  ">Community</div> */}
        <div className="mt-0 mb-0 flex gap-[20px]  text-[20px] font-medium     ">
          <div className="mt-2 mb-0 flex w-full max-w-[500px]   text-[18px] font-semibold     ">
            {taps.map((t, index) => (
              <button
                onClick={() => handleActiveLink(index)}
                className={` ${
                  activeTab === t.index
                    ? "text-black border-b-[#32B3BE] border-b-[6px]"
                    : " text-[#8E8E8E]  border-b-[#EFEFEF]  border-b-[6px]"
                } flex   font-semibold  w-full transition-all duration-150 
                  underline-offset-8 
                  `}
                key={index}
              >
                <div className="px-2 w-[115px]">{t.name}</div>
              </button>
            ))}
          </div>
        </div>
        <div>
          {activeTab === 0 ? (
            <ClientsTable />
          ) : activeTab === 1 ? (
            <CompaniesTable />
          ) : activeTab === 2 ? (
            <Requests />
          ) : (
            // <Notifications/>
            <></>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Community;
