import React, { useState, useContext, useCallback, useEffect } from "react";
import { Button } from "../../../../components/Button/Button";
import { http } from "../../../../utils/httpCommon";
import { TextInput } from "../../../../components/TextInput/TextInput";
import { useForm } from "react-hook-form";
import useFetchData from "../../../../hooks/FetchData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";
import LoadingCircle from "../../../../components/LoadingCircle/LoadingCircle";
import ModalComponent from "../../../../components/Modal/Modal2";
import Compnaylogo from "../../../../assets/aiesec.png";
import { toast } from "react-hot-toast";
import { Checkbox } from "@mantine/core";
import { prettyNumber } from "../../../../lib/prettyNumber";

const NewPaymentModal = ({ opened, setOpened, data, fetchData }) => {
  //to do fetch payment method
  // const { selectedLocation } = useContext(SelectedLocationContext);
  // const { response: paymentMethods, loading: loadingPaymentMethod } = useFetchData(`financials/${selectedLocation}/payment-method-accounts`);
  const { response: paymentMethods, loading: loadingPaymentMethod } = useFetchData(
    `rent-contracts/${data?._id}/payment-method-accounts`
  );

  const [submitLoading, setSubmitLoading] = useState(false);
  const [payWithCredit, setPayWithCredit] = useState(false);

  const paymentMethodValidation = useCallback(() => {
    if (payWithCredit) {
      return yup.string().optional();
    } else {
      return yup
        .number()
        .typeError("Payment method is a required field")
        .required("Payment method is a required field");
    }
  }, [payWithCredit]);

  useEffect(() => {
    paymentMethodValidation();
  }, [payWithCredit]);

  const schema = yup
    .object({
      paymentMethodId: paymentMethodValidation(),
      amount: yup.number().typeError("Amount is required").required("Amount is required"),
      adminPassword: yup
        .string()
        .typeError("Admin password is required")
        .required("Admin password is required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (passedData) => {
    setSubmitLoading(true);
    try {
      await http.put(
        `/rent-contracts/${data._id}/pay`,
        payWithCredit
          ? JSON.stringify({
              adminPassword: passedData.adminPassword,
              rentPaymentUsingCredit: payWithCredit,
              paidAmount: passedData.amount,
            })
          : JSON.stringify({
              paidAmount: passedData.amount,
              paymentMethodId: Number(passedData.paymentMethodId),
              adminPassword: passedData.adminPassword,
              rentPaymentUsingCredit: payWithCredit,
            })
      );

      toast.success("Successful payment");
      fetchData();
      setOpened(false);
      reset();
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || error.message || "Something went wrong");
    } finally {
      setSubmitLoading(false);
    }
    // }
  };

  const handleOnClose = () => {
    setOpened(false);
    reset();
  };

  let sum = data?.transactions?.reduce((accumulator, object) => {
    return accumulator + object.amount;
  }, 0);

  return (
    <ModalComponent opened={opened} setOpened={setOpened} onClose={handleOnClose} size={"lg"}>
      {!loadingPaymentMethod ? (
        <div className="flex flex-col px-6">
          <div className="text-[24px] mb-2 " style={{ fontFamily: "gilroy-bold" }}>
            New Payment
          </div>

          <div className="border-[1px] rounded-[10px] w-full">
            <div className="flex items-center  ">
              {data?.company?.logo && (
                <img
                  src={data?.company?.logo ? data?.company?.logo : Compnaylogo}
                  alt="Company-logo"
                  className="w-[55px] h-[55px] object-contain "
                />
              )}

              <div className="text-[20px] px-2 py-2" style={{ fontFamily: "gilroy-bold" }}>
                {data?.company?.name}
              </div>
            </div>
          </div>

          <div className="text-[20px] mt-2 text-[#797979] " style={{ fontFamily: "gilroy-bold" }}>
            Contract Number
          </div>
          <div className="text-[24px] font-bold" style={{ fontFamily: "gilroy-bold" }}>
            {data?.rentNumber}
          </div>
          <hr />

          {/* to do  */}
          <div className="flex  gap-[50px] mt-[15px]">
            <div className="flex flex-col">
              <span className="text-[18px] ">Total Due</span>
              <span className="text-[20px] " style={{ fontFamily: "gilroy-bold" }}>
                {prettyNumber(data?.total - sum)} EGP
              </span>
            </div>
            <div className="flex flex-col text-[#797979]">
              <span className="text-[18px] ">Amount Paid</span>
              <span className="text-[20px] " style={{ fontFamily: "gilroy-bold" }}>
                {prettyNumber(sum)}
                {/* {Math.floor( data?.transactions((d) => d?.amount))} EGP */}
              </span>
            </div>
          </div>
          <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
            {!payWithCredit ? (
              <div className="my-3">
                <div>
                  <FormControl fullWidth sx={{ border: "none !important" }}>
                    <InputLabel> Payment Method</InputLabel>
                    <Controller
                      control={control}
                      name="paymentMethodId"
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <Select
                          sx={{
                            height: "40px",
                            color: "black",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EFEFEF",
                            },
                          }}
                          onBlur={onBlur} // notify when input is touched
                          onChange={onChange} // send value to hook form
                          variant="outlined"
                          labelId="Payment Method-label"
                          id="Payment Method"
                          // value={value}
                          label="Payment Method"
                          inputRef={ref}
                          defaultValue=""
                        >
 <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {paymentMethods?.map((l) => {
                            return (
                              (l?.name.toLowerCase() != 'receivable') &&
                              <MenuItem key={l?.qboId} value={l?.qboId}>
                                {l?.name}
                              </MenuItem>
                            )

                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <p className="text-red-500">{errors.paymentMethodId?.message}</p>
                </div>
              </div>
            ) : null}

            <div className="flex items-center gap-2 mt-4">
              <Checkbox
                label="Pay with credit"
                checked={payWithCredit}
                onChange={(event) => {
                  setPayWithCredit(event.currentTarget.checked);
                }}
                color="rgba(0, 0, 0, 1)"
                size={"md"}
              />
            </div>

            <div className="mt-4 ">
              <TextInput height="40px" label={"Amount"} {...register("amount")} />
              <p className="text-red-500 mt-4">{errors.amount?.message}</p>
            </div>

            <div className="mt-10 ">
              <TextInput
                height="40px"
                type="password"
                label={"Admin Password"}
                {...register("adminPassword")}
              />
              <p className="text-red-500 mt-4 ">{errors.adminPassword?.message}</p>
            </div>

            <div className="mt-6 mb-20 flex justify-end gap-10">
              <Button
                onClick={() => setOpened(false)}
                secondary={true}
                disabled={submitLoading}
                invert={true}
              >
                Cancel
              </Button>
              <Button loading={submitLoading} type="submit" secondary={true}>
                Pay now
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <LoadingCircle />
      )}
    </ModalComponent>
  );
};

export default NewPaymentModal;
