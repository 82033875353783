import React, { useState } from "react";
import stopIcon from "../../../../assets/stop.svg";
import Carousel from "react-grid-carousel";

import { Button } from "../../../../components/Button/Button";
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-hot-toast";
import { http } from "../../../../utils/httpCommon";
import DeleteModalBooking from "../../../../components/DeleteModal/DeleteModalBooking";
import ConfirmShowBooking from "../../../../components/Modal/confirmShowBoooking";
import CalendarFormStep2 from "../Forms/CalendarFormStep2";
import { useAuthUser } from "react-auth-kit";
import { useEffect } from "react";
import { useTimeSelection } from "../../../../hooks/useTimeSelection";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Switch, TextField } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { classes, diff_hours } from "../Forms/CalendarForm";
import { prettyNumber } from "../../../../lib/prettyNumber";
const BookingsDetails = ({
  responseData: ogRespone,
  getOpenAppointmentData,
  selectedTimeSlotData,
  open,
  close,
  facilities,
  paymentMethods,
  fetchAppointmentsData,
}) => {
  const [steps, setSteps] = useState(0);

  const [responseData, setResponseDate] = useState(ogRespone);

  const auth = useAuthUser();

  const calculateTotalPaid = () => {
    let totalPaid = 0;
    for (const transaction of responseData?.transactions) {
      totalPaid += transaction.amount;
    }
    return Math.ceil(totalPaid);
  };

  const [submitLoading, setSubmitLoading] = useState(false);

  //receivable toggle
  const [receiableChange, setReceivableChange] = useState(false);

  const [notes, setNotes] = useState(responseData.notes);
  const [editMode, setEditMode] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openConfirmShowModal, setOpenConfirmShowModal] = useState(false);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [facilityData, setFacilityData] = useState(responseData?.facility);

  const handleReceivableChange = async (checked) => {
    setReceivableChange(true);
    try {
      if (!checked) {
        await http.put(`/bookings/${responseData?._id}/remove-receivable`);
        setResponseDate((prev) => ({ ...prev, receivable: false }));
        toast.success("Booking has been converted back to payment mode successfully");
      } else {
        await http.put(`/bookings/${responseData?._id}/make-receivable`);
        setResponseDate((prev) => ({ ...prev, receivable: true }));
        toast.success("Booking has been converted to receivable successfully");
      }
    } catch (error) {
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    } finally {
      setReceivableChange(false);
    }
  };

  const handleSubmitDelete = async () => {
    setDeleteLoading(true);
    try {
      await http.delete(`/bookings/${responseData?._id}`);
      setDeleteLoading(false);
      setOpenDeleteModal(false);
      toast.success("Booking has been deleted successfully");
      fetchAppointmentsData();
      setTimeout(() => {
        close();
      }, 1500);
    } catch (error) {
      setDeleteLoading(false);
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    }
  };

  const [showStatusLoading, setShowStatusLoading] = useState(false);
  const handelSubmitShow = async () => {
    setShowStatusLoading(true);
    try {
      await http.put(`bookings/${responseData?._id}/show`);
      setDeleteLoading(false);
      setOpenConfirmShowModal(false);
      getOpenAppointmentData(responseData?._id);
      toast.success("Booking show successfully");
    } catch (error) {
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    } finally {
      setShowStatusLoading(false);
    }
  };

  const bookingNotShow = async () => {
    try {
      setShowStatusLoading(true);
      await http.put(`bookings/${responseData?._id}/no-show`);
      getOpenAppointmentData(responseData?._id);
      toast.success("Booking no show successfully");
    } catch (error) {
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    } finally {
      setShowStatusLoading(false);
    }
  };

  const { components, setComponents } = useTimeSelection({ responseData });

  const bookingDuration = components.reduce(
    (prev, curr) => prev + diff_hours(curr?.endTimeValue, curr?.startTimeValue),
    0
  );

  const [pricing, setPricing] = useState({
    subTotal: null,
    vat: null,
    totalWithVat: null,
    wht: null,
    totalDiscounted: null,
  });

  const calcPrice = async () => {
    try {
      const result = await http.get(
        `/bookings/calc?facility=${facilityData?._id}&discount=${responseData?.discountType === "percentage"
          ? responseData?.discount
          : responseData?.discount
        }&discountType=${responseData?.discountType}&wht=${responseData?.whtPercent
        }&duration=${bookingDuration}&promoCodeId=${responseData?.promoCode}`
      );
      setPricing(result.data);
    } catch (error) {
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (editMode) {
      calcPrice();
    }
  }, [bookingDuration, editMode, facilityData?._id, components]);

  const editBooking = async ({ redirect = false }) => {
    try {
      setSubmitLoading(true);
      const res = await http.put(
        `/bookings/${responseData._id}/edit`,
        JSON.stringify({
          facility: facilityData._id,
          notes: notes,
          days: components.map((c) => ({
            date: moment(c.dateValue).format("YYYY-MM-D"),
            startTime: moment(c.startTimeValue).unix(),
            endTime: moment(c.endTimeValue).unix(),
          })),
        })
      );
      fetchAppointmentsData();
      setResponseDate(res?.data);
      setEditMode(false);
      if (redirect) {
        setSteps(1);
      } else {
        toast.success("Booking updated successfully");
      }
    } catch (error) {
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    } finally {
      setSubmitLoading(false);
    }
  };

  const addNewComponent = () => {
    const newComponent = {
      dateValue: moment(),
      startTimeValue: moment(),
      endTimeValue: moment(),
    };
    setComponents([...components, newComponent]);
  };

  const removeComponent = (indexToRemove) => {
    const updatedComponents = components.filter((_, index) => index !== indexToRemove);
    setComponents(updatedComponents);
  };

  const totalValues = editMode
    ? {
      subTotal: pricing?.subTotal || 0,
      vat: pricing?.vat || 0,
      wht: pricing?.whtAmount || 0,
      discountAmount: pricing?.discountValue || 0,
      discountValue: pricing?.discountAmount || 0,
      promoCode: pricing?.promoCodeAmount || 0,
      total: pricing?.totalDiscounted || 0,
    }
    : {
      subTotal: responseData?.subTotal || 0,
      vat: responseData?.vat || 0,
      wht: responseData?.whtAmount || 0,
      discountValue:
        responseData?.discountType === "percentage"
          ? Math.ceil((responseData?.discount / 100) * responseData?.subTotal)
          : responseData?.discount,
      discountAmount: responseData?.discount || 0,
      promoCode: responseData?.promoCodeAmount || 0,
      total: responseData?.totalAmount || 0,
    };

  return (
    <>
      <ConfirmShowBooking
        opened={openConfirmShowModal}
        setOpened={setOpenConfirmShowModal}
        loading={deleteLoading}
        handelSubmitShow={handelSubmitShow}
        paidAmount={
          responseData?.transactions?.reduce((prev, curr)  => prev + curr.amount, 0)}
      />
      <DeleteModalBooking
        opened={openDeleteModal}
        setOpened={setOpenDeleteModal}
        deletedItem={"Booking"}
        loading={deleteLoading}
        handleSubmitDelete={handleSubmitDelete}
        deleteMessage={responseData?.days?.map((d, index) => (
          <li key={`${d.date}-${index}`}>
            {" "}
            {moment(d?.date).format("dddd")} {moment(d?.date).format("LL.")}{" "}
          </li>
        ))}
      />

      {steps === 0 ? (
        <div className="px-4 ">
          <div className="flex justify-between ">
            <div
              className="text-[32px] "
              style={{
                fontFamily: "gilroy-bold",
              }}
            >
              Booking
            </div>

            {/* Show or no show button */}
            <div className="flex gap-2 my-2 justify-end">
              {!responseData?.status ? (
                auth().role !== "Land Lord" && (
                  <>
                    {!responseData?.receivable && (
                      <button
                        disabled={showStatusLoading}
                        className="px-4 py-2 bg-[#00AD3B] disabled:bg-[#00AD3B60] text-white rounded-[10px] font-bold hover:opacity-90 hover:ring-1"
                        onClick={() => setOpenConfirmShowModal(true)}
                      >
                        Show
                      </button>
                    )}
                    <button
                      disabled={showStatusLoading}
                      className="px-4 py-2 bg-[#9C0101] disabled:bg-[#9C010160] text-white rounded-[10px] font-bold hover:opacity-90 hover:ring-1"
                      onClick={bookingNotShow}
                    >
                      No show
                    </button>
                  </>
                )
              ) : (
                <>
                  {responseData?.status === "show" ? (
                    <div className="px-4 py-2 bg-[#00AD3B] text-white rounded-[10px] font-bold ">
                      Show
                    </div>
                  ) : (
                    <div className="px-4 py-2 bg-[#9C0101] text-white rounded-[10px] font-bold ">
                      No show
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="my-1 flex flex-col gap-3">
            <div className="flex items-center w-full justify-between mt-2">
              <div className="text-[18px] text-[#797979] font-semibold">Facility</div>
              <div className="flex gap-4 items-center">
                {auth().role !== "Land Lord" && (
                  <button
                    onClick={() => setEditMode(!editMode)}
                    className={` rounded-[10px] px-2 py-[2px] border-[1px] border-black transition-all ${editMode
                        ? " bg-white text-black hover:bg-gray-100 "
                        : " bg-black text-white  hover:opacity-90 "
                      }`}
                  >
                    {editMode ? "Close" : "Edit"}
                  </button>
                )}
                {editMode && (
                  <button
                    onClick={() => editBooking({})}
                    className="bg-black text-white rounded-[10px] px-2 py-[2px]"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>

            {editMode ? (
              <div className=" mt-2  gap-[2px] relative w-full h-fit pt-4 pb-2 bg-white  ">
                <div className="absolute bg-white py-1 -top-4 text-[16px]  font-bold ">
                  Select Facility
                </div>

                <Carousel cols={2} rows={2} gap={5} loop style={{ padding: "50px" }}>
                  {facilities?.data?.map((item, index) => {
                    return (
                      <Carousel.Item key={index}>
                        <div
                          className={`  ml-3  p-1 cursor-pointer hover:bg-[#32b3be1a]  transition-all
                      ${facilityData._id === item._id ? "bg-[#32b3be1a] border-[#34B2C0]" : ""}
                        font-semibold
                        border-[1px] flex justify-start items-start py-2  ]
                        rounded-[10px] w-[200px] h-full `}
                          onClick={() => {
                            setFacilityData(item);
                          }}
                        >
                          <LazyLoadImage
                            height={70}
                            width={70}
                            src={item.mainImage}
                            alt={item.name}
                            className="w-[60px] h-[65px] object-cover rounded-[10px] "
                          />
                          {/* <img
                            height={70}
                            width={70}
                            loading="lazy"
                           
                          /> */}
                          <div className="flex flex-col justify-between   text-[14px]  h-full  mx-1">
                            <span className="font-bold ">{item.name}</span>
                            <span className="text-[#34B2C0]  text-[14px] underline ">
                              Quick View
                            </span>
                          </div>
                        </div>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            ) : (
              <div className="border-[#E4E4E4] border-[1px] rounded-[10px] p-[6px] flex gap-3">
                <img
                  src={responseData?.facility?.mainImage}
                  alt={responseData?.facility?.name}
                  className="rounded-[10px] w-[100px] object-cover h-[75px] "
                />
                <div className="flex flex-col justify-between w-full  ">
                  <div className="text-[18px]  " style={{ fontFamily: "gilroy-bold" }}>
                    {responseData?.facility?.name}
                  </div>

                  <Link
                    to={`/inventory/facility/${responseData?.facility?._id}`}
                    className="text-[16px] text-[#34B2C0] underline"
                  >
                    Quick view
                  </Link>
                </div>
              </div>
            )}
          </div>

          <div className="my-2 flex flex-col gap-3">
            <div className="text-[#797979] text-[18px] font-semibold">Client information</div>
            <Link
              to={`/community/client/${responseData?.user?._id}`}
              className="border-[#E4E4E4] border-[1px] rounded-[10px] p-[6px] flex gap-3"
            >
              {responseData?.user?.profilePicture && (
                <img
                  src={responseData?.user?.profilePicture}
                  alt={responseData?.user?.name}
                  className="rounded-[10px] w-[65px] object-cover h-[65px] "
                />
              )}
              <div className="flex flex-col justify-center w-full  ">
                <div className="text-[18px] " style={{ fontFamily: "gilroy-bold" }}>
                  {responseData?.user?.name}
                </div>
                {responseData?.user?.company && (
                  <div className="text-[#797979]">
                    Member at " {responseData?.user?.company?.name}"
                  </div>
                )}
              </div>
            </Link>
          </div>

          {responseData?.editor && (
            <div className="my-2 flex flex-col gap-3">
              <div className="text-[#797979] text-[18px] font-semibold">Booked by</div>
              <Link
                target="_blank"
                to={
                  responseData?.editor?._id ? `/settings/admin/${responseData?.editor?._id}` : "#"
                }
                className="border-[#E4E4E4] border-[1px] rounded-[10px] p-[6px] flex gap-3"
              >
                <div className="flex flex-col justify-center w-full  ">
                  <div className="text-[18px] " style={{ fontFamily: "gilroy-bold" }}>
                    {responseData?.editor?.name || "n/a"}
                  </div>
                </div>
              </Link>
            </div>
          )}

          <div className="flex justify-between items-center">
            <div className="w-full  my-2 bg-white py-1  text-[18px]  font-bold ">Unpaid</div>
            <Switch
              disabled={receiableChange}
              defaultChecked={responseData?.receivable ? Boolean(responseData.receivable) : false}
              onChange={(_, checked) => {
                handleReceivableChange(checked);
              }}
            />
          </div>

          <div className="my-2 flex flex-col gap-3">
            <div className="flex justify-between items-center">
              <div className="text-[#797979] text-[18px] font-semibold">Bookings</div>
              {/* {editMode ? (
                <button
                  className="bg-black text-white rounded-[10px] px-2 py-[2px]"
                  onClick={() => setEditMode(false)}
                >
                  Close
                </button>
              ) : (
                <button
                  className="bg-black text-white rounded-[10px] px-2 py-[2px]"
                  onClick={() => setEditMode(true)}
                >
                  Edit
                </button>
              )} */}
            </div>
            {editMode ? (
              <>
                <div className="border-[1px] border-[#79797933] rounded-[10px] px-2 pt-1 pb-4">
                  {components.map((component, index) => {
                    return (
                      <div className="flex mt-4 items-center gap-3 " key={index}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            label="Date"
                            renderInput={(props) => (
                              <TextField
                                className={classes.picker}
                                style={{ fontSize: "14px" }}
                                {...props}
                              />
                            )}
                            value={component.dateValue}
                            onChange={(newValue) => {
                              const updatedComponents = [...components];
                              updatedComponents[index].dateValue = newValue;
                              const currentDate = newValue.format("YYYY-MM-DD");
                              const startTimeHrs =
                                updatedComponents[index].startTimeValue.format("THH:mm");
                              const endTimeHrs =
                                updatedComponents[index].endTimeValue.format("THH:mm");
                              const startTime = moment(currentDate + startTimeHrs);
                              const endTime = moment(currentDate + endTimeHrs);
                              updatedComponents[index].startTimeValue = startTime;
                              updatedComponents[index].endTimeValue = endTime;
                              setComponents(updatedComponents);
                            }}
                          />

                          <TimePicker
                            label="From"
                            renderInput={(props) => (
                              <TextField className={classes.picker} {...props} />
                            )}
                            value={component.startTimeValue}
                            onChange={(newValue) => {
                              const updatedComponents = [...components];
                              const currentDate =
                                updatedComponents[index].dateValue.format("YYYY-MM-DD");
                              const currentTime = newValue.format("THH:mm");
                              const value = moment(currentDate + currentTime);
                              updatedComponents[index].startTimeValue = value;
                              setComponents(updatedComponents);
                            }}
                          />

                          <TimePicker
                            label="To"
                            renderInput={(props) => (
                              <TextField className={classes.picker} {...props} />
                            )}
                            value={component.endTimeValue}
                            onChange={(newValue) => {
                              const updatedComponents = [...components];
                              const currentDate =
                                updatedComponents[index].dateValue.format("YYYY-MM-DD");
                              const currentTime = newValue.format("THH:mm");
                              const value = moment(currentDate + currentTime);

                              updatedComponents[index].endTimeValue = value;
                              setComponents(updatedComponents);
                            }}
                          />
                        </LocalizationProvider>
                        <button onClick={() => removeComponent(index)}>
                          <img
                            src={stopIcon}
                            className="w-[30px] h-[30px] object-contain cursor-pointer"
                            alt="deleted"
                          />
                        </button>
                      </div>
                    );
                  })}
                </div>
                <div
                  onClick={addNewComponent}
                  className="flex w-full justify-end text-[16px] mt-2 text-[#34B2C0]  cursor-pointer"
                  style={{ fontFamily: "gilroy-bold" }}
                >
                  + Add more days
                </div>
                <div className="mt-2 w-full">
                  <div className="mt- bg-white py-1  text-[18px]  font-bold ">Notes</div>
                  <textarea
                    className="w-full"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    rows={5}
                    style={{ borderRadius: 8, border: "1px solid black", padding: 8 }}
                  ></textarea>
                </div>

                <Button
                  loading={submitLoading}
                  secondary={true}
                  onClick={() => {
                    editBooking({});
                  }}
                >
                  Save
                </Button>
              </>
            ) : (
              <div className="border-[#E4E4E4] border-[1px] rounded-[10px] px-4 py-2 flex flex-col gap-2">
                <div className="text-[16px] " style={{ fontFamily: "gilroy-bold" }}>
                  {responseData?.days?.length}{" "}
                  {responseData?.days?.length > 1 ? "Days between" : "Day between"}{" "}
                  {moment(responseData?.days[responseData?.days?.length - 1]?.date).format("ddd")}{" "}
                  {moment(responseData?.days[responseData?.days?.length - 1]?.date).format(
                    "Do MMM  YYYY"
                  )}{" "}
                  to{" "}
                  {moment(responseData?.days?.map((d, index) => d?.startTime)[0] * 1000).format(
                    "ddd"
                  )}{" "}
                  {moment(responseData?.days?.map((d, index) => d?.startTime)[0] * 1000).format(
                    "Do MMM  YYYY"
                  )}{" "}
                </div>
                <div className="px-4 py-2 text-[16px]">
                  <ul className="list-disc">
                    {responseData?.days?.map((d) => (
                      <li key={d?._id}>
                        {moment(d?.date).format("dddd")} {moment(d?.date).format("LL.")}{" "}
                        {moment(d?.startTime * 1000).format("LT")}
                        {"  -  "}
                        {moment(d?.endTime * 1000).format("LT")}{" "}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            {/* edit mode */}
          </div>

          {responseData?.notes && (
            <div>
              <div className="text-[#797979] text-[18px] font-semibold mt-3 mb-2">Notes</div>
              <div className="border-[#E4E4E4] border-[1px] rounded-[10px] px-4 py-2 flex flex-col gap-2">
                {responseData?.notes}
              </div>
            </div>
          )}

          <div className="mt-6 bg-[#F6F8F7] py-2 px-4 w-full rounded-[10px] flex flex-col">
            <div
              className="mt-4"
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <span className="w-[80px] text-[18px] font-bold">Subtotal</span>
              <div className="flex items-center">
                <span className="text-[18px] text-[#797979]">
                  {prettyNumber(+totalValues.subTotal)} EGP
                </span>
              </div>
              <div></div>

              <span className="w-[80px] text-[18px] font-bold">VAT</span>
              <div className="flex items-center">
                <span className="text-[18px] text-[#797979]">
                  {prettyNumber(+totalValues.vat)} EGP
                </span>
              </div>
              <div></div>

              <span className="w-[80px] text-[18px] font-bold">WHT</span>
              <div className="flex items-center">
                <span className="text-[18px] text-[#797979]">
                  {prettyNumber(+totalValues.wht)} EGP
                </span>
              </div>
              <div></div>

              {(totalValues?.discountValue &&
                !isNaN(totalValues?.discountValue) &&
                Number(totalValues?.discountValue) > 0) ||
                true ? (
                <>
                  <span className="w-[80px] text-[18px] font-bold">Discount</span>
                  <div className="flex items-center">
                    <span className="text-[18px] text-[#797979]">
                      -{prettyNumber(+totalValues?.discountValue)} EGP
                      {responseData?.discountType === "percentage"
                        ? ` (${prettyNumber(
                          (totalValues?.discountValue / totalValues?.subTotal) * 100
                        ) + " %"
                        })`
                        : null}
                    </span>
                  </div>
                  <div></div>
                </>
              ) : null}

              {responseData?.promoCodeAmount &&
                !isNaN(responseData?.promoCodeAmount) &&
                Number(responseData?.promoCodeAmount) > 0 ? (
                <>
                  <span className="w-[80px] text-[18px] font-bold">Promo Code</span>
                  <div className="flex items-center">
                    <span className="text-[18px] text-[#797979]">
                      -{prettyNumber(+totalValues.promoCode)} EGP (
                      {+((totalValues.promoCode / totalValues?.subTotal) * 100).toFixed(2)} %)
                    </span>
                  </div>
                  <div></div>
                </>
              ) : null}
            </div>

            <div className="mt-4 text-[18px]">Amount paid</div>

            <div className="flex items-center text-[18px]  gap-2">
              <div className="text-[#011516] text-[25px] " style={{ fontFamily: "gilroy-bold" }}>
                {prettyNumber(calculateTotalPaid())} EGP
              </div>
              <div className="text-[#797979]">from</div>
              <div className="text-[#011516] text-[25px] " style={{ fontFamily: "gilroy-bold" }}>
                {" "}
                {prettyNumber(+totalValues.total)} EGP
              </div>
            </div>
          </div>

          <div className="flex w-full justify-end  mt-4 gap-5 pb-6">
            {["Area Manager", "Land Lord"].includes(auth().role) ? null : (
              <Button secondary={true} invert={true} onClick={() => setOpenDeleteModal(true)}>
                Delete
              </Button>
            )}
            {calculateTotalPaid() >= responseData.totalWithDiscount
              ? auth().role !== "Land Lord" && (
                <Button disabled secondary={true}>
                  Completed
                </Button>
              )
              : auth().role !== "Land Lord" &&
              (responseData?.totalWithDiscount > calculateTotalPaid() &&
                !responseData?.receivable ? (
                <Button
                  loading={submitLoading}
                  type="submit"
                  secondary={true}
                  onClick={() => {
                    setSteps(1);
                  }}
                >
                  Pay now
                </Button>
              ) : null)}
          </div>
        </div>
      ) : (
        <CalendarFormStep2
          subTotal={responseData?.subTotal}
          vat={responseData?.vat}
          total={responseData?.totalAmount - calculateTotalPaid()}
          discount={responseData?.discount}
          amountPaid={calculateTotalPaid()}
          responseData={responseData}
          selectedTimeSlotData={selectedTimeSlotData}
          open={open}
          close={close}
          facilities={facilities}
          paymentMethods={paymentMethods}
          fetchAppointmentsData={fetchAppointmentsData}
        />
      )}
    </>
  );
};

export default BookingsDetails;
