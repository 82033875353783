import React, { useEffect, useState } from "react";
import { Layout } from "../../../components/Layout/Layout";
import { Button } from "../../../components/Button/Button";
import { TextInput } from "../../../components/TextInput/TextInput";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import PromoTarget from "./PromoTarget";
import NewPromoCodeConfirmation from "./NewPromoCodeConfirmation";
import moment from "moment/moment";

const NewPromoCode = () => {
  const navigator = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({});
  const [step, setStep] = useState(0);

  // selected clients states
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [showCountInput, setShowCountInput] = useState(false);

  const countValidation = () => {
    if (showCountInput) {
      return yup.number().required().typeError("Count is a required field").positive().integer();
    } else {
      return yup.string().notRequired();
    }
  };

  const schema = yup
    .object({
      name: yup
        .string()
        .required("Name is a required filed")
        .typeError("Name is a required filed "),
      code: yup
        .string()
        .required("Code is a required filed ")
        .typeError("Code is a required filed "),
      type: yup
        .string()
        .required("Promo Code type is a required filed ")
        .typeError("promo code type is a required filed "),
      value: yup
        .number()
        .required()
        .typeError("Promo Code Value is a required filed and it must be a positive number")
        .positive()
        .integer(),
      usage: yup
        .string()
        .required("Usage is a required filed")
        .typeError("Usage is a required filed"),
      count: countValidation(),
      startDate: yup
        .string()
        .required("Start Date is a required filed ")
        .typeError("Start Date is a required filed "),
      endDate: yup
        .string()
        .required("End Date is a required filed")
        .typeError("End Date is a required filed "),
    })
    .required();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const usageValue = useWatch({
    control,
    name: "usage",
  });

  useEffect(() => {
    if (usageValue === "multi") {
      setShowCountInput(true);
    } else {
      clearErrors("count");
      setShowCountInput(false);
    }
    countValidation();
  }, [usageValue, clearErrors]);

  const setCustomValue = (id, value) => {
    setValue(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const onSubmit = async (passedData) => {
    setFormData({
      name: passedData?.name,
      code: passedData?.code,
      usage: {
        type: passedData?.usage,
        count: parseInt(passedData?.count) ? parseInt(passedData?.count) : 1,
      },
      type: passedData?.type,
      value: parseInt(passedData?.value),
      startDate: moment(passedData?.startDate).format("YYYY-MM-DD"),
      endDate: moment(passedData?.endDate).format("YYYY-MM-DD"),
    });
    setStep(1);
  };

  const vasTypes = [
    { name: "percent", id: 1 },
    { name: "flat", id: 2 },
  ];
  const vasUnits = [
    { name: "multi", id: 1 },
    { name: "single", id: 2 },
  ];

  //   const startDate = watch("startDate");
  const [startDate, setStartDate] = useState("");
  const handleChangeStartDate = (newValue) => {
    setCustomValue("startDate", newValue);
    setStartDate(newValue);
  };

  const [endDate, setEndDate] = useState("");
  const handleChangeEndDate = (newValue) => {
    setCustomValue("endDate", newValue);
    setEndDate(newValue);
  };

  return (
    <Layout>
      <Toaster />
      <div className="flex justify-start mx-10">
        {step === 0 ? (
          <div className="w-[60%] bg-white p-4 ">
            <div className="flex flex-col">
              <div className="w-full  flex flex-col justify-between">
                <div className=" text-[26px]  my-2" style={{ fontFamily: "gilroy-bold" }}>
                  Create Promo Code
                </div>
                <div className=" text-[18px]  " style={{ fontFamily: "gilroy-bold" }}>
                  About the promo code
                </div>
              </div>
            </div>
            {/* form  */}
            <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-4">
                <TextInput
                  label={"Name*"}
                  {...register("name", { required: "input name is required" })}
                />
                <p className="text-red-500 text-[16px]">{errors.name?.message}</p>
              </div>
              <div className="mt-3">
                <TextInput
                  label={"Code*"}
                  {...register("code", { required: "Code  is required" })}
                />
                <p className="text-red-500 text-[16px]">{errors.code?.message}</p>
              </div>
              <div className="mt-3">
                <div>
                  <FormControl fullWidth sx={{ border: "none !important" }}>
                    <InputLabel>Code Type</InputLabel>
                    <Controller
                      control={control}
                      name="type"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <Select
                          sx={{
                            color: "black",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EFEFEF",
                            },
                          }}
                          onBlur={onBlur}
                          onChange={onChange}
                          variant="outlined"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={value}
                          label="Code type"
                          inputRef={ref}
                          defaultValue={""}
                        >
                          <MenuItem value=""> None </MenuItem>
                          {vasTypes?.map((type) => (
                            <MenuItem key={type.name} value={type.name}>
                              {type.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <p className="text-red-500 text-[16px]">{errors.type?.message}</p>
                </div>
              </div>
              <div className="mt-3">
                <TextInput
                  type="number"
                  label={"Value*"}
                  {...register("value", { required: "Value  is required" })}
                />
                <p className="text-red-500 text-[16px]">{errors.value?.message}</p>
              </div>
              <div className="mt-3">
                <div>
                  <FormControl fullWidth sx={{ border: "none !important" }}>
                    <InputLabel>Usage</InputLabel>
                    <Controller
                      control={control}
                      name="usage"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <Select
                          sx={{
                            color: "black",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EFEFEF",
                            },
                          }}
                          onBlur={onBlur}
                          onChange={onChange}
                          variant="outlined"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={value}
                          label="Usage"
                          inputRef={ref}
                          defaultValue=""
                        >
                          <MenuItem value=""> None </MenuItem>
                          {vasUnits?.map((unit) => (
                            <MenuItem key={unit.name} value={unit.name}>
                              {unit.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <p className="text-red-500 text-[16px]">{errors.usage?.message}</p>
                </div>
              </div>
              <div>
                {showCountInput && (
                  <div className="mt-3">
                    <TextInput label={"Count*"} type="number" {...register("count")} />
                    <p className="text-red-500 text-[16px]">{errors.count?.message}</p>
                  </div>
                )}
              </div>
              {/* Dates */}
              <div className="flex  gap-4">
                <div className="mt-3 w-full">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Start Date"
                      inputFormat="MM/DD/YYYY"
                      value={startDate}
                      onChange={handleChangeStartDate}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            width: "100%",
                            color: "#000000cc",
                            "& .MuiFormControl-root ": {
                              borderColor: "#EFEFEF",
                            },
                            "& .MuiInputBase-root": {
                              "& fieldset": {
                                borderColor: "#EFEFEF !important",
                              },
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#207beb !important",
                              },
                            },
                            "& .MuiFormLabel-root ": {
                              color: "#000000cc !important",
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <p className="text-red-500 text-[16px]">{errors.startDate?.message}</p>
                </div>
                <div className="mt-3 w-full">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="End Date"
                      inputFormat="MM/DD/YYYY"
                      value={endDate}
                      onChange={handleChangeEndDate}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            width: "100%",
                            color: "#000000cc",
                            "& .MuiFormControl-root ": {
                              borderColor: "#EFEFEF",
                            },
                            "& .MuiInputBase-root": {
                              "& fieldset": {
                                borderColor: "#EFEFEF !important",
                              },
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#207beb !important",
                              },
                            },
                            "& .MuiFormLabel-root ": {
                              color: "#000000cc !important",
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <p className="text-red-500 text-[16px]">{errors.endDate?.message}</p>
                </div>
              </div>
              <div className="mt-6 mb-10 gap-[50px] flex justify-end">
                <Button
                  onClick={() => navigator("/operations")}
                  secondary={true}
                  disabled={isSubmitting}
                  invert={true}
                >
                  <span className="text-[16px]">Cancel</span>
                </Button>
                <Button loading={isSubmitting} secondary={true} type="submit">
                  <span className="text-[16px]">Next</span>
                </Button>
              </div>
            </form>
          </div>
        ) : step === 1 ? (
          <PromoTarget
            setStep={setStep}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
          />
        ) : (
          <NewPromoCodeConfirmation
            formData={formData}
            setStep={setStep}
            rowSelectionModel={rowSelectionModel}
          />
        )}
      </div>
    </Layout>
  );
};

export default NewPromoCode;
