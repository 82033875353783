import React from "react";
import RentContractsTable from "./RentContractsTable";

const RentContracts = () => {
  return (
    <>
      <RentContractsTable />
    </>
  );
};

export default RentContracts;
