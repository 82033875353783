import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Layout } from "../../../../components/Layout/Layout";
import useFetchData from "../../../../hooks/FetchData";
import whiteEdit from "../../../../assets/icons/whiteEdit.png";
import callIcon from "../../../../assets/icons/call.png";
import atIcon from "../../../../assets/icons/@.png";
import locationIcon from "../../../../assets/icons/location.png";
import profileIcon from "../../../../assets/icons/profile.png";
import WhiteTrash from "../../../../assets/icons/whiteTrash.png";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import { http } from "../../../../utils/httpCommon";
import { toast } from "react-hot-toast";
import ProfileImg from "../../../../assets/profile.png";
import { useAuthUser } from "react-auth-kit";

const Admin = () => {
  const params = useParams();
  const navigator = useNavigate();
  const { response, loading } = useFetchData(`/admin/${params.id}`);
  const auth = useAuthUser();
  //handle delete
  const [openedDelete, setOpenedDelete] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const handleDelete = async (item) => {
    setDeletedItem(item);
    setOpenedDelete(true);
  };

  const [deletedLoading, setDeleteLoading] = useState(false);
  const handleSubmitDelete = async () => {
    setDeleteLoading(true);
    try {
      const { data } = await http.delete(`/admin/${params.id}`);
      setOpenedDelete(false);
      setDeleteLoading(false);
      toast.success(data?.message || "Admin has been deleted successfully");
      navigator("/settings");
    } catch (error) {
      setDeleteLoading(false);
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    }
  };

  return (
    <Layout>
      <DeleteModal
        loading={deletedLoading}
        opened={openedDelete}
        setOpened={setOpenedDelete}
        deletedItem={deletedItem}
        handleSubmitDelete={handleSubmitDelete}
        deleteMessage="Please note that you will be deleting this Admin"
      />
      {!loading ? (
        <div className="flex p-6">
          <div className="w-full bg-white rounded-[20px] min-h-[460px] p-8 flex gap-[40px] ">
            <div className="w-[45%]">
              <img
                className=" h-[300px] w-[320px]  rounded-[20px] object-cover"
                src={response?.imageUrl ? response?.imageUrl : ProfileImg}
                alt={response?.name}
              />

              <div className="mt-4 text-[40px] font-bold">{response?.name}</div>
            </div>
            <div className="w-full">
              {auth().role !== "Super Admin" || auth().role === "Land Lord" ? null : (
                <div className="flex  items-center gap-[30px]">
                  <button
                    onClick={() => navigator(`/settings/edit-admin/${params.id}`)}
                    className="group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                  >
                    <div className="flex justify-center items-center bg-[#686868] w-[34px] h-[34px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                      <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                    </div>
                    Edit Profile
                  </button>

                  <button
                    onClick={() => handleDelete(response)}
                    className="group text-[#686868] flex mt-0 items-center hover:text-[#32B3BE] transition-all "
                  >
                    <div className="flex justify-center items-center bg-[#686868] w-[34px] h-[34px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                      <img className=" w-[18px] " src={WhiteTrash} alt="edit" />
                    </div>
                    Delete Profile
                  </button>
                </div>
              )}
              <div className=" font-semibold text-[16px] w-fit text-[white] bg-[#32B3BE] px-4 py-2 mt-4 rounded-[20px] ">
                {response?.role}
              </div>
              <div className="mt-4 flex flex-col gap-[16px]">
                <div className="flex items-center gap-[10px] ">
                  <img className="w-[16px]" src={callIcon} alt={response?.phone} />
                  <span>{response?.phone}</span>
                </div>

                <div className="flex items-center gap-[10px] ">
                  <img className="w-[16px]" src={atIcon} alt={response?.email} />
                  <span>{response?.email}</span>
                </div>

                <div className="flex items-center gap-[10px] ">
                  <img className="w-[16px]" src={locationIcon} alt={response?.name} />
                  <span>{response?.locations?.map((l) => l?.name)}</span>
                </div>

                {response?.birthday && (
                  <div className="flex items-center gap-[10px] ">
                    <img className="w-[16px]" src={profileIcon} alt={response?.name} />
                    <span className="text-[#8E8E8E]"> Born on </span>
                    <span>{new Date(response?.birthday).toLocaleDateString()}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h1>Loading...</h1>
      )}
    </Layout>
  );
};

export default Admin;
