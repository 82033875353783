import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../../../components/Button/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Carousel from "react-grid-carousel";
import AsyncSelect from "react-select/async";
import { http } from "../../../../utils/httpCommon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { toast } from "react-hot-toast";
// import CalendarFormStep2 from "./CalendarFormStep2";
import stopIcon from "../../../../assets/stop.svg";
import { SelectedLocationContext } from "../../../../Context/LocationProvider";
import { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FormControl } from "@mui/material";
import { TextInput } from "../../../../components/TextInput/TextInput";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";
import useFetchPostData from "../../../../hooks/FetchPostData";
import LoadingCircle from "../../../../components/LoadingCircle/LoadingCircle";
import useFetchData from "../../../../hooks/FetchData";
import CalendarFormStep2 from "./CalendarFormStep2";

const PREFIX = "Demo";
export const classes = {
  content: `${PREFIX}-content`,
  header: `${PREFIX}-header`,
  closeButton: `${PREFIX}-closeButton`,
  buttonGroup: `${PREFIX}-buttonGroup`,
  button: `${PREFIX}-button`,
  picker: `${PREFIX}-picker`,
  wrapper: `${PREFIX}-wrapper`,
  icon: `${PREFIX}-icon`,
  textField: `${PREFIX}-textField`,
  addButton: `${PREFIX}-addButton`,
};
export function diff_hours(dt2, dt1) {
  var diff = (new Date(dt2)?.getTime() - new Date(dt1)?.getTime()) / 1000;
  diff /= 60 * 30;
  return Math.abs(Math.ceil(Math.abs(diff)));
}

const AddBookingForm = ({ selectedTimeSlotData, open, close, client }) => {
  const schema = yup
    .object({
      facilityId: yup.string().required("Facility  is required"),
      clientId: yup.object(),
    })
    .required();
  const {
    handleSubmit,
    setValue,
    control,
    watch,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { selectedLocation } = useContext(SelectedLocationContext);

  const { response: facilities, loading: loadingFacilities } = useFetchPostData("/facility/all", {
    page: 1,
    limit: 1000,
    location: selectedLocation,
  });

  const discount = +Number(watch("discount")).toFixed(2);

  const clientId = watch("clientId", {
    id: client?._id,
    label: (
      <div key={`${client?.phone}-${client?.name}`} className="flex items-center">
        {client?.profilePicture && (
          <img
            src={client?.profilePicture}
            className="w-[45px] h-[45px] object-cover rounded-full mr-2"
            alt={client?.name}
          />
        )}
        {client?.name} / {client?.phone}
      </div>
    ),
  });
  const setCustomValue = (id, value) => {
    setValue(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const { response: paymentMethods, loading: loadingPaymentMethod } = useFetchData(
    `financials/${selectedLocation || "none"}/payment-method-accounts`
  );
  const [checkedFacility, setCheckedFacility] = useState("");
  const handleCheckedFacility = (facility) => {
    if (checkedFacility._id === facility._id) {
      setCheckedFacility("");
      setCustomValue("facilityId", "");
    } else {
      setCheckedFacility(facility);
      setCustomValue("facilityId", facility._id);
    }
  };
  const navigator = useNavigate();
  const CustomNoOptionsMessage = ({ inputValue, onResetClick }) => (
    <div className="p-2">
      No results found{" "}
      <button
        className=" text-[18px]  text-[#34B2C0]  hover:opacity-95"
        style={{ fontFamily: "gilroy-bold" }}
        onClick={() => navigator(`/community/clients/new-client`)}
      >
        Add new client
      </button>
    </div>
  );

  const loadOptions = async (inputText, callback) => {
    let res = null;
    if (inputText.length > 0) {
      let response = await http.get(`/client/find?q=${inputText}`);
      res = response.data.data;
      callback(
        res.map((i) => ({
          label: (
            <div key={`${i.phone}-${i?.name}`} className="flex items-center">
              {i?.profilePicture && (
                <img
                  src={i?.profilePicture}
                  className="w-[45px] h-[45px] object-cover rounded-full mr-2 object-cover"
                  alt={i.name}
                />
              )}
              {i.name} / {i.phone}
            </div>
          ),
          value: i._id,
        }))
      );
    }
  };

  //   calender
  const [components, setComponents] = useState([
    {
      dateValue: selectedTimeSlotData?.date ? moment(selectedTimeSlotData?.date) : moment(),
      startTimeValue: selectedTimeSlotData?.time ? moment(selectedTimeSlotData?.time) : moment(),
      endTimeValue: selectedTimeSlotData?.time
        ? moment(selectedTimeSlotData?.time).add(1, "hours")
        : moment().add(1, "hours"),
    },
  ]);

  const [dateValue] = useState(
    selectedTimeSlotData?.date ? moment(selectedTimeSlotData?.date) : moment()
  );
  const [startTimeValue] = useState(
    selectedTimeSlotData?.time ? moment(selectedTimeSlotData?.time) : moment()
  );
  const [endTimeValue] = useState(
    selectedTimeSlotData?.time
      ? moment(selectedTimeSlotData?.time).add(1, "hours")
      : moment().add(1, "hours")
  );

  const [overlayStep, setOverlayStep] = useState(0);
  const [responseData, setResponseDate] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const onSubmit = async (passedData) => {
    try {
      setSubmitLoading(true);
      const res = await http.post(
        `/bookings`,
        JSON.stringify({
          location: selectedLocation,
          facility: passedData.facilityId,
          notes: passedData.notes,
          user: client?._id,
          days: components.map((c) => ({
            date: moment(c.dateValue).format("YYYY-MM-D"),
            startTime: moment(c.startTimeValue).unix(),
            endTime: moment(c.endTimeValue).unix(),
          })),
          discount: passedData.discount,
        })
      );

      setOverlayStep(1);
      setResponseDate(res?.data);

      // fetchAppointmentsData();
      toast.success("Booking created successfully");
    } catch (error) {
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleAddComponent = () => {
    setComponents([
      ...components,
      {
        dateValue,
        startTimeValue,
        endTimeValue,
      },
    ]);
  };

  const handleDeleteComponent = (index) => {
    if (index === 0) {
      return; // Prevent deleting the first component
    }

    const updatedComponents = [...components];
    updatedComponents.splice(index, 1);
    setComponents(updatedComponents);
  };

  const bookingDuration = components.reduce(
    (prev, curr) => prev + diff_hours(curr?.endTimeValue, curr?.startTimeValue),
    0
  );

  const [pricing, setPricing] = useState({
    subTotal: null,
    vat: null,
    totalWithVat: null,
    totalDiscounted: null,
  });

  const calcPrice = async () => {
    if (!checkedFacility?._id) return false;
    try {
      const result = await http.get(
        `/bookings/calc?facility=${checkedFacility?._id}&discount=${discount}&duration=${bookingDuration}`
      );
      setPricing(result.data);
    } catch (error) {
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  };

  useEffect(() => {
    calcPrice();
  }, [checkedFacility?._id, discount, bookingDuration]);

  const auth = useAuthUser();
  const discountList = [0, 5, 10, 15, 20, 25, 30];

  return (
    <>
      {loadingFacilities || loadingPaymentMethod ? (
        <LoadingCircle />
      ) : (
        <div className="pb-4">
          {/* {overlayStep === 0 ? ( */}
          {overlayStep === 0 ? (
            <div className="py-0 px-4">
              <div className="flex justify-between mt-0">
                <div
                  className="text-[32px] "
                  style={{
                    fontFamily: "gilroy-bold",
                  }}
                >
                  New Booking
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className="  flex flex-col   ">
                <div className=" mt-2  gap-[2px] relative w-full h-fit pt-4 pb-2 bg-white  ">
                  <div className="absolute bg-white py-1 -top-4 text-[16px]  font-bold ">
                    Select Facility
                  </div>

                  <Carousel cols={2} rows={2} gap={5} loop style={{ padding: "50px" }}>
                    {facilities?.data?.map((item, index) => {
                      return (
                        <Carousel.Item key={index}>
                          <div
                            className={`  ml-3  p-1 cursor-pointer hover:bg-[#32b3be1a]  transition-all
                     ${checkedFacility?._id === item?._id ? "bg-[#32b3be1a] border-[#34B2C0]" : ""}
                       font-semibold
                       border-[1px] flex justify-start items-start py-2  ]
                       rounded-[10px] w-[200px] h-full `}
                            onClick={() => {
                              //   ? setValue("role", null)

                              handleCheckedFacility(item);
                            }}
                          >
                            <LazyLoadImage
                              height={70}
                              width={70}
                              src={item?.mainImage}
                              alt={item?.name}
                              className="w-[60px] h-[65px] object-cover rounded-[10px] "
                            />
                            {/* <img
                           height={70}
                           width={70}
                           loading="lazy"
                          
                         /> */}
                            <div className="flex flex-col justify-between   text-[14px]  h-full  mx-1">
                              <span className="font-bold ">{item?.name}</span>
                              <span className="text-[#34B2C0]  text-[14px] underline ">
                                Quick View
                              </span>
                            </div>
                          </div>
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                  <p className="text-red-500 mt-1">{errors?.facilityId?.message}</p>
                </div>

                <div className=" w-full bg-white py-1   text-[18px]  font-bold ">
                  Client Information
                </div>
                <div className="w-full">
                  <AsyncSelect
                    isClearable
                    defaultOptions
                    components={{
                      NoOptionsMessage: ({ inputValue }) => (
                        <CustomNoOptionsMessage inputValue={inputValue} onResetClick={() => {}} />
                      ),
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: "56px",
                        borderRadius: "8px",
                        borderColor: "black",
                        "&:hover": {},
                      }),
                      option: (styles) => ({
                        ...styles,
                        zIndex: "999 !important",
                        backgroundColor: "white !important",
                      }),
                    }}
                    value={clientId}
                    isDisabled={true}
                    onChange={
                      (value) => {
                        setCustomValue("clientId", value);
                      }
                      // setSelectedUser(value)
                    }
                    placeholder={"Search for Client"}
                    loadOptions={loadOptions}
                  />
                </div>
                <p className="text-red-500 mt-2">{errors.clientId?.message}</p>

                <div className="w-full  mt- bg-white py-1  text-[18px]  font-bold ">Booking</div>

                <div className="border-[1px] border-[#79797933] rounded-[10px] px-2 pt-1 pb-4">
                  {components.map((component, index) => (
                    <div className="flex mt-4 items-center gap-3 " key={index}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Date"
                          renderInput={(props) => (
                            <TextField
                              className={classes.picker}
                              style={{ fontSize: "14px" }}
                              {...props}
                            />
                          )}
                          value={component.dateValue}
                          onChange={(newValue) => {
                            const updatedComponents = [...components];
                            updatedComponents[index].dateValue = newValue;
                            const currentDate = newValue.format("YYYY-MM-DD");
                            const startTimeHrs =
                              updatedComponents[index].startTimeValue.format("THH:mm");
                            const endTimeHrs =
                              updatedComponents[index].endTimeValue.format("THH:mm");
                            const startTime = moment(currentDate + startTimeHrs);
                            const endTime = moment(currentDate + endTimeHrs);
                            updatedComponents[index].startTimeValue = startTime;
                            updatedComponents[index].endTimeValue = endTime;
                            setComponents(updatedComponents);
                          }}
                        />

                        <TimePicker
                          label="From"
                          renderInput={(props) => (
                            <TextField className={classes.picker} {...props} />
                          )}
                          value={component.startTimeValue}
                          onChange={(newValue) => {
                            const updatedComponents = [...components];
                            const currentDate =
                              updatedComponents[index]?.dateValue?.format("YYYY-MM-DD");
                            const currentTime = newValue?.format("THH:mm");
                            const value = moment(currentDate + currentTime);

                            updatedComponents[index].startTimeValue = value;
                            setComponents(updatedComponents);
                          }}
                        />

                        <TimePicker
                          label="To"
                          renderInput={(props) => (
                            <TextField className={classes.picker} {...props} />
                          )}
                          value={component.endTimeValue}
                          onChange={(newValue) => {
                            const updatedComponents = [...components];
                            const currentDate =
                              updatedComponents[index]?.dateValue?.format("YYYY-MM-DD");
                            const currentTime = newValue?.format("THH:mm");
                            const value = moment(currentDate + currentTime);

                            updatedComponents[index].endTimeValue = value;
                            setComponents(updatedComponents);
                          }}
                        />
                      </LocalizationProvider>

                      {index > 0 && (
                        <div className="">
                          <img
                            src={stopIcon}
                            onClick={() => handleDeleteComponent(index)}
                            className="w-[30px] h-[30px] object-contain cursor-pointer"
                            alt="deleted"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div
                  onClick={handleAddComponent}
                  className="flex w-full justify-end text-[16px] mt-2 text-[#34B2C0]  cursor-pointer"
                  style={{ fontFamily: "gilroy-bold" }}
                >
                  + Add more days
                </div>
                <div className="mt-2 w-full">
                  <div className="mt- bg-white py-1  text-[18px]  font-bold ">Notes</div>
                  <textarea
                    className="w-full"
                    {...register("notes")}
                    rows={5}
                    style={{ borderRadius: 8, border: "1px solid black", padding: 8 }}
                  ></textarea>
                </div>

                {/* Total */}
                {checkedFacility && (
                  <div className="border-[1px] rounded-[10px]  mt-4 p-4">
                    <div className="flex gap-[10px]">
                      {auth()?.role !== "Experience Officer" ? (
                        <FormControl fullWidth sx={{ border: "none !important" }}>
                          <TextInput label={"Discount %"} {...register("discount")} />
                        </FormControl>
                      ) : (
                        <>
                          <FormControl fullWidth sx={{ border: "none !important" }}>
                            <InputLabel>Discount</InputLabel>
                            <Controller
                              control={control}
                              name="discount"
                              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                <Select
                                  sx={{
                                    color: "black",
                                    ".MuiOutlinedInput-notchedOutline": {
                                      borderColor: "#EFEFEF",
                                    },
                                  }}
                                  name={name}
                                  onBlur={onBlur}
                                  onChange={(e, { props }) => {
                                    onChange(props.value);
                                  }}
                                  variant="outlined"
                                  labelId="discount label"
                                  id="discount"
                                  value={value}
                                  label="discount"
                                  inputRef={ref}
                                >
                                  <MenuItem value={""}>
                                    <em>None</em>
                                  </MenuItem>
                                  {discountList.map((d) => (
                                    <MenuItem key={d} value={d}>
                                      {d}%
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />
                          </FormControl>
                        </>
                      )}
                    </div>
                    <div className="text-[16px] mt-2" style={{ fontFamily: "gilroy-bold" }}>
                      Total Due
                    </div>
                    <div className="mt-[10px]">
                      <span className=" font-extrabold">Subtotal</span>
                      <span className="text-[#797979] mx-[10px] ">
                        {pricing.subTotal}
                        EGP
                      </span>
                    </div>
                    <div className="mt-[10px]">
                      <span className=" font-extrabold">VAT</span>
                      <span className="text-[#797979] mx-[10px] ">{pricing.vat} EGP</span>
                    </div>
                    <div className="mt-[15px]">
                      <span className=" font-semibold">Total</span>
                      <br />
                      <span className="text-[36px] " style={{ fontFamily: "gilroy-bold" }}>
                        {pricing.totalDiscounted} EGP
                      </span>
                    </div>
                  </div>
                )}

                <div className="flex mt-4 w-full justify-end gap-10 ">
                  <Button
                    secondary={true}
                    invert={true}
                    onClick={() => {
                      close();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button loading={submitLoading} secondary={true} type="submit">
                    Confirm Booking
                  </Button>
                </div>
              </form>
            </div>
          ) : (
            // Step2

            <CalendarFormStep2
              subTotal={pricing.subTotal}
              vat={pricing.vat}
              total={pricing.totalDiscounted}
              responseData={responseData}
              selectedTimeSlotData={selectedTimeSlotData}
              open={open}
              close={close}
              facilities={facilities}
              paymentMethods={paymentMethods}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AddBookingForm;
