import React, { useEffect } from "react";
import { Layout } from "./../../../components/Layout/Layout";
import { useParams } from "react-router-dom";
import useFetchData from "../../../hooks/FetchData";
import moment from "moment";
import ProfileImg from "../../../assets/profile.png";
import StopImg from "../../../assets/icons/stop.svg";
import { http } from "../../../utils/httpCommon";
import refershIcon from "../../../assets/icons/refresh-cw.svg";
import { useAuthUser } from "react-auth-kit";
import { prettyNumber } from "../../../lib/prettyNumber";

const PromoCode = () => {
  const params = useParams();
  const auth = useAuthUser();
  const { response, loading, fetchData } = useFetchData(`/promo-codes/${params.id}`);
  const TogglePromoCode = async () => {
    let data;
    response?.status === "inactive"
      ? (data = JSON.stringify({
          status: "active",
        }))
      : (data = JSON.stringify({
          status: "inactive",
        }));

    try {
      await http.patch(`/promo-codes/${params.id}`, data);
      fetchData();
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [loading]);
  return (
    <Layout>
      <div className="w-[60%] m-4 flex flex-col gap-3">
        <div
          className=" text-[26px] flex  justify-between gap-4  my-2"
          style={{ fontFamily: "gilroy-bold" }}
        >
          <div className="flex items-center gap-2" style={{ fontFamily: "gilroy-bold" }}>
            Promo Code
            <div className="flex gap-[10px] items-center ">
              <div
                className={` ${
                  response?.status === "active"
                    ? "text-[#00AD3B]  bg-[#00ad3b1a] "
                    : "text-[#797979]  bg-[#E4E4E4]"
                } text-[16px]  font-semibold py-1 px-2 flex  justify-center items-center rounded-[25px]`}
              >
                {response?.status}
              </div>
            </div>
          </div>
          {auth().role !== "Land Lord" && (
            <>
              {response?.status === "active" ? (
                <button
                  onClick={TogglePromoCode}
                  className="flex gap-3 text-[20px] items-center text-[#011516]"
                >
                  <img src={StopImg} className="w-[30px] h-[30px]" alt="stop" />
                  Stop promo Code
                </button>
              ) : (
                <button
                  onClick={TogglePromoCode}
                  className="flex gap-3 text-[20px]  items-center text-[#00AD3B]"
                >
                  <img src={refershIcon} className="w-[30px] h-[30px]" alt="resume" />
                  Resume code
                </button>
              )}
            </>
          )}
        </div>
        <div className="bg-white w-fit min-w-[60%]  px-6 py-4  ">
          <div className="flex gap-6">
            <div className="text-[18px]" style={{ fontFamily: "gilroy-bold" }}>
              Basic info{" "}
            </div>
            {/* <button
            onClick={() => {
              setStep(0);
            }}
            className="group text-[16px] text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
          >
            <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
              <img className=" w-[18px] " src={whiteEdit} alt="edit" />
            </div>
            Edit
          </button> */}
          </div>
          <div className="flex flex-col gap-1 text-[18px] ">
            <div className="flex flex-col mt-2 ">
              <span className="text-[#797979]">Name</span>
              <span style={{ fontFamily: "gilroy-bold" }}>{response?.name}</span>
            </div>
            <div className="flex flex-col mt-2 ">
              <span className="text-[#797979]">Code</span>
              <span style={{ fontFamily: "gilroy-bold" }}>{response?.code}</span>
            </div>
            <div className="flex flex-col mt-2 ">
              <span className="text-[#797979]">Code Type</span>
              <span style={{ fontFamily: "gilroy-bold" }}>{response?.type}</span>
            </div>
            <div className="flex flex-col mt-2 ">
              <span className="text-[#797979] font-extrabold">Value</span>
              <span style={{ fontFamily: "gilroy-bold" }}>{prettyNumber(response?.value)}</span>
            </div>
            <div className=" mt-2 flex gap-16 ">
              <div className="flex flex-col">
                <span className="text-[#797979]">Starting Date</span>
                <span style={{ fontFamily: "gilroy-bold" }}>
                  {moment(response?.startDate).format("LL")}
                  {/* 4th, Sept 2021 */}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-[#797979]">Ending Date</span>
                <span style={{ fontFamily: "gilroy-bold" }}>
                  {moment(response?.endDate).format("LL")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white w-fit   min-w-[60%]  px-6 py-4 ">
          <div className="flex gap-6">
            <div className="text-[18px]" style={{ fontFamily: "gilroy-bold" }}>
              Recipients
            </div>
            {/* <button
            onClick={() => {
              setStep(1);
            }}
            className="group text-[16px] text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
          >
            <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
              <img className=" w-[18px] " src={whiteEdit} alt="edit" />
            </div>
            Edit
          </button> */}
          </div>
          <div className="flex flex-col w-fit   justify-center ">
            {response?.utilization?.length > 5 ? null : (
              <div
                style={{ fontFamily: "gilroy-bold" }}
                className="text-[#797979] flex items-center  gap-2 mt-2"
              >
                <div className="flex relative min-w-[220px] ">
                  {response?.utilization?.map((r) => (
                    <img
                      key={r?._id}
                      alt={r?._id}
                      src={r?.profilePicture ? r?.profilePicture : ProfileImg}
                      className={`w-[60px] h-[60px]  object-cover relative top-0 left-0   rounded-full`}
                    />
                  ))}
                </div>
                {response?.utilization?.length ? response?.utilization?.length : "All"} Clients
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PromoCode;
