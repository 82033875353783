import { isNumber } from "lodash";

export function prettyNumber(number) {
  if (isNumber(number))
    return new Intl.NumberFormat("en-GB", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      minimumIntegerDigits: 1,
    }).format(number);

  return "Error parsing number, Contact developement team.";
}
