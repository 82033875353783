import React, { useState } from "react";
import { Button } from "../../components/Button/Button";
import { http } from "../../utils/httpCommon";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ModalComponent from "../../components/Modal/Modal2";
import { toast } from "react-hot-toast";
import { TextField } from "@mui/material";
import { TextInput } from "../../components/TextInput/TextInput";
import { useNavigate } from "react-router-dom";

// Move schema declaration here
const schema = yup.object({
    title: yup.string().required("Title is required"),
    url: yup.string().url("Please enter a valid URL").required("URL is required"),
    role: yup.string().required().typeError("Role is a required filed "),
}).required();

const AddTutorialModal = ({ opened, setOpened, fetchData }) => {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const navigator = useNavigate();
    const [submitLoading, setSubmitLoading] = useState(false);

    const onSubmit = async (passedData) => {
        setSubmitLoading(true);
        try {
            await http.post( // Use POST for creating a new tutorial
                `/tutorials`,
                JSON.stringify({
                    title: passedData.title,
                    url: passedData.url,
                    role: passedData.role,
                })
            );
            setSubmitLoading(false);
            handleOnClose();
            fetchData();
            reset();
            navigator("/tutorials");
            toast.success("Tutorial created successfully");
        } catch (error) {
            setSubmitLoading(false);
            console.log(error);
            toast.error(error.response?.data.message || error.message || "Something went wrong");
        }
    };

    const handleOnClose = () => {
        setOpened(false);
        reset();
    };


    const setCustomValue = (id, value) => {
        setValue(id, value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        });
    };

    let roles = ["Super Admin", "Area Manager", "Branch Manager", "Experience Officer", "Land Lord"];
    const [userRole, setUserRole] = useState("");
    const [checkedRole, setCheckedRole] = useState("");
    const handleCheckedRole = (role) => {
        if (checkedRole === role) {
            setCheckedRole("");
            setUserRole("");
        } else {
            setUserRole(role);
            setCheckedRole(role);
        }
    };



    return (
        <ModalComponent opened={opened} setOpened={setOpened} onClose={handleOnClose}>
            <div className="flex flex-col">
                <div className="text-[24px] font-bold">Add Tutorial</div>

                <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-6">
                        <TextInput {...register("title")} label={"Title"} />
                        <p className="text-red-500 text-[16px]">{errors.title?.message}</p>
                    </div>

                    <div className="mt-4">
                        <TextField
                            style={{ width: "100%" }}
                            variant="outlined"
                            rows={2}
                            multiline
                            label="URL"
                            {...register("url")}
                        />
                        <p className="text-red-500">{errors.url?.message}</p>
                    </div>

                    <div className="mt-4">
                        <div className=" mt-6 flex flex-wrap gap-[2px] relative w-full h-fit py-6 bg-white border-[1px] border-[#EFEFEF] rounded-[5px] hover:border-[black] ">
                            <div className="absolute bg-white p-1 -top-4 text-[16px] left-2 text-[#00000099] ">
                                Role (You can select only one role)
                            </div>

                            {roles.map((item) => {
                                return (
                                    <div
                                        key={item}
                                        className={` ml-2 mt-1 cursor-pointer hover:bg-[#32B3BE] hover:text-[white] transition-all 
                                    ${checkedRole === item ? "bg-[#32B3BE]" : ""} ${checkedRole === item ? "text-[#FFFFF1]" : ""
                                            }
                                    ${checkedRole === item ? "font-semibold" : "font-semibold"}
                                    text-[#32B3BE] border-[1px] text-[16px] flex justify-center items-center py-3  border-[#32B3BE] 
                                    rounded-full w-[160px]  `}
                                        onClick={() => {
                                            item === checkedRole
                                                ? setCustomValue("role", null)
                                                : setCustomValue("role", item);
                                            handleCheckedRole(item);
                                        }}
                                        {...register("role", { required: "role is required" })}
                                    >
                                        {item}
                                    </div>
                                );
                            })}
                        </div>
                        <p className="text-red-500 text-[16px]">{errors.role?.message}</p>
                    </div>

                    <div className="mt-6 mb-8 flex justify-end gap-10">
                        <Button onClick={() => setOpened(false)} secondary={true} invert={true}>
                            Cancel
                        </Button>
                        <Button loading={submitLoading} type="submit" secondary={true}>
                            Confirm
                        </Button>
                    </div>

                </form>
            </div>
        </ModalComponent>
    );
};

export default AddTutorialModal;
