import React from "react";
import ModalComponent from "../Modal/Modal";
import DeleteImage from "../../assets/icons/deleteIcon1.png";
import { Button } from "../Button/Button";
const DeleteModal = ({ loading, opened, setOpened, deletedItem, handleSubmitDelete, deleteMessage }) => {
  return (
    <ModalComponent opened={opened} setOpened={setOpened}>
      <div className="flex justify-center flex-col items-center gap-[25px]">
        <img className="w-[80px]" src={DeleteImage} alt="delete" />
        <div className=" text-[25px] font-bold ">Delete "{deletedItem.name || deletedItem.title}"</div>
        <div className="text-center text-[18px]">{deleteMessage}</div>
        <div className="flex  gap-[40px]">
          <Button onClick={() => setOpened(false)} secondary={true} invert={true}>
            Cancel
          </Button>
          <Button loading={loading} onClick={handleSubmitDelete} secondary>
            Confirm
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default DeleteModal;
