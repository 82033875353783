import React, { useState } from "react";
import { Button } from "../../../../components/Button/Button";
import ModalComponent from "../../../../components/Modal/Modal2";
import { http } from "../../../../utils/httpCommon";
import { toast } from "react-hot-toast";
import { TextInput } from "../../../../components/TextInput/TextInput";

const EditCoinBank = ({ opened, setOpened, response, refetch }) => {
  const [value, setValue] = useState(response?.wallet?.balance);
  const [disableButton, setDisableButton] = useState(false);
  const onSubmit = async () => {
    setDisableButton(true);
    try {
      await http.put(
        `/client/${response?._id}/coin-bank`,
        JSON.stringify({
          coinBalance: value,
        })
      );
      refetch();
      setOpened(false);
      toast.success("Coin Bank Updated Successfully ");
    } catch (error) {
      toast.error(
        error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong"
      );
    } finally {
      setDisableButton(false);
    }
  };

  const handleOnClose = () => {
    setOpened(false);
  };

  return (
    <ModalComponent opened={opened} setOpened={setOpened} onClose={handleOnClose}>
      <div className="flex justify-center flex-col items-center gap-[25px]">
        <div className=" text-[25px] font-bold ">Edit {response?.name} coin Balance </div>
        <div className="text-center text-[18px] w-[75%] my-4">
          <TextInput label={"Balance"} value={value} onChange={(e) => setValue(e.target.value)} />
        </div>
        <div className="flex  gap-[40px]">
          <Button
            disabled={disableButton}
            onClick={() => setOpened(false)}
            secondary={true}
            invert={true}
          >
            Cancel
          </Button>
          <Button disabled={disableButton} onClick={onSubmit} secondary type="submit">
            Confirm
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default EditCoinBank;
