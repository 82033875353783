import React from "react";

const ComingSoon = () => {
  // Function to generate a random hex color
  const getRandomColor = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(255, 255, 255, 0.9)",
      }}
    >
      <div
        style={{
          padding: "20px",
          borderRadius: "8px",
          background: "#f0f0f0",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          maxWidth: "400px",
        }}
      >
        <h2 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}>Coming Soon</h2>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: getRandomColor(),
              borderRadius: "50%",
              marginRight: "10px",
            }}
          ></div>
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: getRandomColor(),
              borderRadius: "50%",
              marginRight: "10px",
            }}
          ></div>
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: getRandomColor(),
              borderRadius: "50%",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
