import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { http } from "../../../utils/httpCommon";
import { debounce } from "lodash";
import Table from "../../../components/DataGrid/Table";
import { toast } from "react-hot-toast";
import { SelectedLocationContext } from "../../../Context/LocationProvider";

const Subscriptions = () => {
  const { selectedLocation } = useContext(SelectedLocationContext);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const [searchQuery, setSearchQuery] = React.useState("");

  const handleChange = (event) => {
    debounced(event.target.value, 600);
    setSearchQuery(event.target.value);
  };

  const columns = [
    {
      field: "client",
      headerName: "Client",
      width: 380,
      renderCell: (params) => {
        return (
          <Link
            to={`/finance/subscriptions/${params.row?._id}`}
            className="flex flex-col gap-[5px] h-[80px] text-[16px]  justify-center hover:text-[#34B2C0]"
          >
            <div className="font-semibold">
              {params?.row?.clientType === "company"
                ? params?.row?.company?.name
                : params.row?.client?.name}
            </div>
          </Link>
        );
      },
    },
    {
      field: "passes",
      headerName: "Passes",
      width: 150,

      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] ">{params?.row?.passess}</div>
        );
      },
    },
    {
      field: "usedPasses",
      headerName: "Used Passes",
      width: 150,

      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] ">
            {params?.row?.usedPassess}
          </div>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] font-semibold">
            {params?.row?.isActive ? "Active" : "In Active"}
          </div>
        );
      },
      // editable: true,
    },

    {
      field: "startDate",
      headerName: "Start Date",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {params?.row?.startDate ? new Date(params?.row?.startDate).toLocaleDateString() : "-"}
          </div>
        );
      },
      // editable: true,
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {params?.row?.endDate ? new Date(params?.row?.endDate).toLocaleDateString() : "-"}
          </div>
        );
      },
      // editable: true,
    },
  ];

  const fetchData = async (searchValues) => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const res = await http.get(
        `/packages/subscriptions/location/${selectedLocation}?page=${pageState.page}&limit=${pageState.pageSize}&location=${selectedLocation}&isActive=true`,
      );
      const rowsData = res.data?.data?.subscriptions?.map((d) => ({
        id: d._id,
        ...d,
      }));
      console.log(rowsData, res?.data, "rowsData");
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.data?.totalCount,
      }));
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  };
  const debounced = useCallback(debounce(fetchData, 1000), []);

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize, selectedLocation, searchQuery]);

  return (
    <div>
      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={Math.ceil(pageState.total / pageState.pageSize)}
        isLoading={pageState.isLoading}
        data={pageState?.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        keys={["client", "passes", "usedPasses", "isActive", "startDate", "endDate"]}
        title={"Subscriptions"}
        removeActionButton={true}
      />
    </div>
  );
};

export default Subscriptions;
