import React, { useState, useEffect, useContext, useCallback } from "react";
import { SelectedLocationContext } from "../../Context/LocationProvider";
import { http } from "../../utils/httpCommon";
import toast from "react-hot-toast";
import { Layout } from "../../components/Layout/Layout";
import { debounce } from "lodash";
import LoadingCircle from "../../components/LoadingCircle/LoadingCircle";
import { useLocation, useNavigate } from "react-router-dom";
import ComingSoon from "./commingSoon";
import { useAuthUser } from "react-auth-kit";
import "./Dashboard.css";

const METABASE_DASHBOARD_TYPES = {
  REVENUE: "REVENUE",
  FINANCE: "FINANCE",
  COMMUNITY: "COMMUNITY",
  EXPERIENCE: "EXPERIENCE",
};

const Dashboard = () => {
  const [iframeUrl, setIFrameUrl] = useState("");
  const [currentDashboardType, setCurrentDashboardType] = useState(
    METABASE_DASHBOARD_TYPES.REVENUE
  );
  const location = useLocation();
  const navigator = useNavigate();
  const comingSoonTabsIndexes = [2];
  const comingSoonAllLocationsTabsIndexes = [2, 3];

  const [isLoading, setIsLoading] = useState(false);
  const [isAllLocationsSelected, setIsAllLocationsSelected] = useState(false);
  const { selectedLocation, setSelectedLocation } = useContext(SelectedLocationContext);
  const [activeTab, setActiveTab] = useState(0);
  const [taps, setTaps] = useState([
    { name: "Revenues", index: 0, path: "/dashboard" },
    { name: "Experience", index: 1, path: "/dashboard/experience" },
    { name: "Community", index: 2, path: "/dashboard/community" },
    { name: "Finance", index: 3, path: "/dashboard/finance" },
  ]);

  const auth = useAuthUser();
  const isSuperAdmin = auth()?.role === "Super Admin";

  const handleActiveLink = (index) => {
    setActiveTab(index);
    const tabName = taps[index].name.toLowerCase().replace(" ", "-");
    const newPath = `/dashboard/${tabName}`;
    navigator(newPath);
  };

  const handleToggleChange = () => {
    const toBeChangedValue = !isAllLocationsSelected;
    if (toBeChangedValue === true) {
      setSelectedLocation(null);
    } else {
      setSelectedLocation(auth()?.locations[0]?._id);
    }
    setIsAllLocationsSelected(toBeChangedValue);
  };

  useEffect(() => {
    const currentTab = taps.findIndex((tab) => location.pathname === tab.path);
    if (currentTab !== -1) {
      setActiveTab(currentTab);
    }
  }, [location]);

  useEffect(() => {
    const currentTab = taps.findIndex((tab) => location.pathname === tab.path);
    switch (currentTab) {
      case 1:
        setCurrentDashboardType(METABASE_DASHBOARD_TYPES.EXPERIENCE);
        break;
      case 2:
        setCurrentDashboardType(METABASE_DASHBOARD_TYPES.COMMUNITY);
        break;
      case 3:
        setCurrentDashboardType(METABASE_DASHBOARD_TYPES.FINANCE);
        break;
      default:
        setCurrentDashboardType(METABASE_DASHBOARD_TYPES.REVENUE);
        break;
    }
  }, [activeTab]);

  const getCurrentLocationIFrame = async (currentSelectedLocation, dashboardType) => {
    setIsLoading(true);
    setIFrameUrl("");
    try {
      const { data } = await http.get(
        `/dashboard/${currentSelectedLocation ?? "all-locations"
        }/iframe?dashboardType=${dashboardType}`
      );
      if (data?.data?.iframeUrl) {
        setIFrameUrl(data?.data?.iframeUrl);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedGetCurrentLocationIFrame = useCallback(
    debounce(
      (currentSelectedLocation, dashboardType) =>
        getCurrentLocationIFrame(currentSelectedLocation, dashboardType),
      500
    ),
    []
  );

  useEffect(() => {
    if (isAllLocationsSelected) {
      if (!comingSoonAllLocationsTabsIndexes.includes(activeTab)) {
        debouncedGetCurrentLocationIFrame(selectedLocation, currentDashboardType);
      }
    } else {
      if (!comingSoonTabsIndexes.includes(activeTab)) {
        debouncedGetCurrentLocationIFrame(selectedLocation, currentDashboardType);
      }
    }
    // Cleanup function
    return () => {
      if (!selectedLocation || isAllLocationsSelected) {
        setSelectedLocation(auth()?.locations[0]?._id);
      }
    };
  }, []);

  useEffect(() => {
    if (isAllLocationsSelected) {
      if (!comingSoonAllLocationsTabsIndexes.includes(activeTab)) {
        debouncedGetCurrentLocationIFrame(null, currentDashboardType);
      }
    } else {
      if (!comingSoonTabsIndexes.includes(activeTab)) {
        debouncedGetCurrentLocationIFrame(selectedLocation, currentDashboardType);
      }
    }
  }, [currentDashboardType]);

  useEffect(() => {
    if (isAllLocationsSelected) {
      if (!comingSoonAllLocationsTabsIndexes.includes(activeTab)) {
        debouncedGetCurrentLocationIFrame(null, currentDashboardType);
      }
    } else {
      if (!comingSoonTabsIndexes.includes(activeTab)) {
        debouncedGetCurrentLocationIFrame(selectedLocation, currentDashboardType);
      }
    }
  }, [selectedLocation]);

  return (
    <Layout>
      <div className="pb-10" style={{ position: "relative", height: "100%", overflow: "hidden" }}>
        {isLoading ? (
          <div
            style={{
              position: "absolute",
              top: "30%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <LoadingCircle />
          </div>
        ) : (
          <>
            <div className="mt-[0px] mx-[2%] relative flex flex-col h-full gap-4">
              <div>
                <div className="flex items-center justify-between">
                  <div className="text-[16px] font-semibold text-[#797979] flex items-center">
                    Dashboard
                    {isSuperAdmin && (
                      <div className="flex items-center ml-4">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isAllLocationsSelected}
                            onChange={handleToggleChange}
                          />
                          <span className="slider round"></span>
                        </label>
                        <label className="ml-4 mr-2 text-[16px] font-semibold text-[#797979]">
                          All Locations
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="text-[28px] font-semibold  " style={{ fontFamily: "gilroy-bold" }}>
                  {taps[activeTab].name}
                </div>
                <div className="mt-2 flex w-full max-w-[300px]   text-[16px] font-semibold      ">
                  {taps.map((t, index) => {
                    if (t.name != 'Experience') {
                      return (
                        <button
                          onClick={() => handleActiveLink(index)}
                          className={` ${activeTab === t.index
                            ? "text-black border-b-[#32B3BE] border-b-[6px]"
                            : " text-[#8E8E8E]  border-b-[#EFEFEF]  border-b-[6px]"
                            } flex   font-semibold  w-full transition-all duration-150 
                              underline-offset-4
                              mb-2 relative
                              `}
                          key={index}
                        >
                          <div className="px-1  text-[15px] w-[120px]">{t.name}</div>
                        </button>
                      )
                    }
                  })}
                </div>
              </div>
              <div className="relative flex-1">
                {activeTab === 0 &&
                  (window.location.pathname === "/" ||
                    window.location.pathname === "/dashboard/revenues") ? (
                  <iframe
                    src={iframeUrl}
                    title="Bookings"
                    frameBorder="0"
                    width="100%"
                    height="100%"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100%",
                      overflow: "auto",
                    }}
                    allowTransparency="true"
                  ></iframe>
                ) : activeTab === 1 && window.location.pathname === "/dashboard/experience" ? (
                  <iframe
                    src={iframeUrl}
                    title="Day Passes"
                    frameBorder="0"
                    width="100%"
                    height="100%"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      overflow: "auto",
                    }}
                    allowTransparency="true"
                  ></iframe>
                ) : activeTab === 2 && window.location.pathname === "/dashboard/community" ? (
                  <ComingSoon />
                ) : // <iframe
                  //   src={iframeUrl}
                  //   frameBorder="0"
                  //   title="Offices"
                  //   width="100%"
                  //   height="100%"
                  //   style={{
                  //     position: "absolute",
                  //     top: 0,
                  //     left: 0,
                  //     overflow: "auto",
                  //   }}
                  //   allowTransparency="true"
                  // ></iframe>
                  activeTab === 3 && window.location.pathname === "/dashboard/finance" ? (
                    !isAllLocationsSelected ? (
                      <iframe
                        src={iframeUrl}
                        frameBorder="0"
                        title="Packages"
                        width="100%"
                        height="100%"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          overflow: "auto",
                        }}
                        allowTransparency="true"
                      ></iframe>
                    ) : (
                      <ComingSoon />
                    )
                  ) : null}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

//   return (
//     <Layout>
//       <div className="pb-10">
//         <div className="text-[40px]  " style={{ fontFamily: "gilroy-bold" }}>
//           Revenues
//         </div>

//         <div style={{ display: "flex", flexWrap: "wrap" }}>
//           <div
//             style={{
//               height: "30vh",
//               position: "relative",
//               marginBottom: "1%",
//               padding: "1%",
//               marginTop: "30px",
//               width: "70vh",
//             }}
//           >
//             <div className="text-[20px]" style={{ fontFamily: "gilroy-bold" }}>
//               Total Sales
//             </div>

//             <div className="text-[16px] text-[#797979]">
//               Total Revenues from paid and unpaid invoices
//             </div>

//             <Bar
//               data={{
//                 labels: ["Industry 1", "Industry 2", "Industry 3", "Industry 4", "Industry 5"],
//                 datasets: [
//                   {
//                     label: "sales",
//                     data: [30, 45, 33, 20, 20, 20],
//                     backgroundColor: ["#C9C9C9", "#34B2C0", "#D9B777", "#AADDE3", "#66CE89"],
//                     // borderColor: [
//                     //   "rgba(0, 0, 0, 1)",
//                     //   "rgba(0, 0, 0, 1)",
//                     //   "rgba(0, 0, 0, 1)",
//                     // ],
//                     borderWidth: 0,

//                     borderRadius: "10px !important",
//                   },

//                   // {
//                   //   label: "total number",
//                   //   data: [22, 25, 20],
//                   //   backgroundColor: [
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //   ],
//                   //   borderColor: [
//                   //     "rgba(173, 173, 173, 1)",
//                   //     "rgba(173, 173, 173, 1)",
//                   //     "rgba(173, 173, 173, 1)",
//                   //   ],
//                   //   borderWidth: 1,
//                   // },
//                 ],
//               }}
//               height={400}
//               width={"30%"}
//               options={{
//                 maintainAspectRatio: false,
//                 scales: {
//                   y: {
//                     beginAtZero: true,
//                   },
//                 },
//               }}
//             />
//           </div>
//           {/* bookings */}

//           <div
//             style={{
//               height: "30vh",
//               position: "relative",
//               marginBottom: "1%",
//               padding: "1%",
//               width: "70vh",
//               marginTop: "30px",
//             }}
//           >
//             <div className="text-[20px]" style={{ fontFamily: "gilroy-bold" }}>
//               Total Actual Revenues
//             </div>

//             <div className="text-[16px] text-[#797979]">
//               Total Revenues from paid and unpaid invoices
//             </div>

//             <Bar
//               data={{
//                 labels: ["Industry 1", "Industry 2", "Industry 3", "Industry 4", "Industry 5"],
//                 datasets: [
//                   {
//                     label: "Actual Revenues",
//                     data: [20, 35, 30, 40, 20],
//                     backgroundColor: ["#D9B777", "#EFEFEF", "#AADDE3", "#34B2C0", "#66CE89"],
//                     // borderColor: [
//                     //   "rgba(0, 0, 0, 1)",
//                     //   "rgba(0, 0, 0, 1)",
//                     //   "rgba(0, 0, 0, 1)",
//                     // ],
//                     borderWidth: 0,

//                     borderRadius: "10px !important",
//                   },

//                   // {
//                   //   label: "total number",
//                   //   data: [22, 25, 20],
//                   //   backgroundColor: [
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //   ],
//                   //   borderColor: [
//                   //     "rgba(173, 173, 173, 1)",
//                   //     "rgba(173, 173, 173, 1)",
//                   //     "rgba(173, 173, 173, 1)",
//                   //   ],
//                   //   borderWidth: 1,
//                   // },
//                 ],
//               }}
//               height={400}
//               width={"30%"}
//               options={{
//                 maintainAspectRatio: false,
//                 scales: {
//                   y: {
//                     beginAtZero: true,
//                   },
//                 },
//               }}
//             />
//           </div>

//           <div
//             style={{
//               height: "30vh",
//               position: "relative",
//               marginBottom: "1%",
//               padding: "1%",
//               width: "70vh",
//               marginTop: "100px",
//             }}
//           >
//             <div className="text-[20px]" style={{ fontFamily: "gilroy-bold" }}>
//               REV/SQM
//             </div>

//             <div className="text-[16px] text-[#797979]">
//               Total Revenues from paid and unpaid invoices
//             </div>

//             <Bar
//               data={{
//                 labels: ["Industry 1", "Industry 2", "Industry 3", "Industry 4", "Industry 5"],
//                 datasets: [
//                   {
//                     label: "REV/SQM",
//                     data: [20, 35, 30, 40, 20],
//                     backgroundColor: ["#D9B777", "#EFEFEF", "#AADDE3", "#34B2C0", "#66CE89"],
//                     // borderColor: [
//                     //   "rgba(0, 0, 0, 1)",
//                     //   "rgba(0, 0, 0, 1)",
//                     //   "rgba(0, 0, 0, 1)",
//                     // ],
//                     borderWidth: 0,

//                     borderRadius: "10px !important",
//                   },

//                   // {
//                   //   label: "total number",
//                   //   data: [22, 25, 20],
//                   //   backgroundColor: [
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //   ],
//                   //   borderColor: [
//                   //     "rgba(173, 173, 173, 1)",
//                   //     "rgba(173, 173, 173, 1)",
//                   //     "rgba(173, 173, 173, 1)",
//                   //   ],
//                   //   borderWidth: 1,
//                   // },
//                 ],
//               }}
//               height={400}
//               width={"30%"}
//               options={{
//                 maintainAspectRatio: false,
//                 scales: {
//                   y: {
//                     beginAtZero: true,
//                   },
//                 },
//               }}
//             />
//           </div>

//           <div
//             style={{
//               height: "30vh",
//               position: "relative",
//               marginBottom: "1%",
//               padding: "1%",
//               width: "70vh",
//               marginTop: "100px",
//             }}
//           >
//             <div className="text-[20px]" style={{ fontFamily: "gilroy-bold" }}>
//               REV/NSQM
//             </div>

//             <div className="text-[16px] text-[#797979]">
//               Total Revenues from paid and unpaid invoices
//             </div>

//             <Bar
//               data={{
//                 labels: ["Industry 1", "Industry 2", "Industry 3", "Industry 4", "Industry 5"],
//                 datasets: [
//                   {
//                     label: "REV/NSQM",
//                     data: [20, 35, 30, 40, 20],
//                     backgroundColor: ["#D9B777", "#EFEFEF", "#AADDE3", "#34B2C0", "#66CE89"],
//                     // borderColor: [
//                     //   "rgba(0, 0, 0, 1)",
//                     //   "rgba(0, 0, 0, 1)",
//                     //   "rgba(0, 0, 0, 1)",
//                     // ],
//                     borderWidth: 0,

//                     borderRadius: "10px !important",
//                   },

//                   // {
//                   //   label: "total number",
//                   //   data: [22, 25, 20],
//                   //   backgroundColor: [
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //   ],
//                   //   borderColor: [
//                   //     "rgba(173, 173, 173, 1)",
//                   //     "rgba(173, 173, 173, 1)",
//                   //     "rgba(173, 173, 173, 1)",
//                   //   ],
//                   //   borderWidth: 1,
//                   // },
//                 ],
//               }}
//               height={400}
//               width={"30%"}
//               options={{
//                 maintainAspectRatio: false,
//                 scales: {
//                   y: {
//                     beginAtZero: true,
//                   },
//                 },
//               }}
//             />
//           </div>
//           {/* Bookings */}

//           <div
//             style={{
//               height: "30vh",
//               position: "relative",
//               marginBottom: "1%",
//               padding: "1%",
//               width: "70vh",
//               marginTop: "100px",
//             }}
//           >
//             <div className="text-[20px]" style={{ fontFamily: "gilroy-bold" }}>
//               REV/Weekday
//             </div>

//             <div className="text-[16px] text-[#797979]">
//               Total Revenues from paid and unpaid invoices
//             </div>

//             <Bar
//               data={{
//                 labels: ["Industry 1", "Industry 2", "Industry 3", "Industry 4", "Industry 5"],
//                 datasets: [
//                   {
//                     label: "REV/Weekday",
//                     data: [20, 35, 30, 40, 20],
//                     backgroundColor: ["#D9B777", "#EFEFEF", "#AADDE3", "#34B2C0", "#66CE89"],
//                     // borderColor: [
//                     //   "rgba(0, 0, 0, 1)",
//                     //   "rgba(0, 0, 0, 1)",
//                     //   "rgba(0, 0, 0, 1)",
//                     // ],
//                     borderWidth: 0,

//                     borderRadius: "10px !important",
//                   },

//                   // {
//                   //   label: "total number",
//                   //   data: [22, 25, 20],
//                   //   backgroundColor: [
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //   ],
//                   //   borderColor: [
//                   //     "rgba(173, 173, 173, 1)",
//                   //     "rgba(173, 173, 173, 1)",
//                   //     "rgba(173, 173, 173, 1)",
//                   //   ],
//                   //   borderWidth: 1,
//                   // },
//                 ],
//               }}
//               height={400}
//               width={"30%"}
//               options={{
//                 maintainAspectRatio: false,
//                 scales: {
//                   y: {
//                     beginAtZero: true,
//                   },
//                 },
//               }}
//             />
//           </div>

//           <div
//             style={{
//               height: "30vh",
//               position: "relative",
//               marginBottom: "1%",
//               padding: "1%",
//               width: "70vh",
//               marginTop: "100px",
//             }}
//           >
//             <div className="text-[20px]" style={{ fontFamily: "gilroy-bold" }}>
//               REV/Bookings
//             </div>

//             <div className="text-[16px] text-[#797979]">
//               Total Revenues from paid and unpaid invoices
//             </div>

//             <Bar
//               data={{
//                 labels: ["Industry 1", "Industry 2", "Industry 3", "Industry 4", "Industry 5"],
//                 datasets: [
//                   {
//                     label: "REV/Bookings",
//                     data: [20, 35, 30, 40, 20],
//                     backgroundColor: ["#D9B777", "#EFEFEF", "#AADDE3", "#34B2C0", "#66CE89"],
//                     // borderColor: [
//                     //   "rgba(0, 0, 0, 1)",
//                     //   "rgba(0, 0, 0, 1)",
//                     //   "rgba(0, 0, 0, 1)",
//                     // ],
//                     borderWidth: 0,

//                     borderRadius: "10px !important",
//                   },

//                   // {
//                   //   label: "total number",
//                   //   data: [22, 25, 20],
//                   //   backgroundColor: [
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //     "rgba(173, 173, 173, 0.8)",
//                   //   ],
//                   //   borderColor: [
//                   //     "rgba(173, 173, 173, 1)",
//                   //     "rgba(173, 173, 173, 1)",
//                   //     "rgba(173, 173, 173, 1)",
//                   //   ],
//                   //   borderWidth: 1,
//                   // },
//                 ],
//               }}
//               height={400}
//               width={"30%"}
//               options={{
//                 maintainAspectRatio: false,
//                 scales: {
//                   y: {
//                     beginAtZero: true,
//                   },
//                 },
//               }}
//             />
//           </div>
//         </div>
//       </div>
//     </Layout>
//   );
// };

export default Dashboard;
