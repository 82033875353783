import React, { useState } from "react";
import { Layout } from "../../components/Layout/Layout";
import AdminsTable from "./Admins/AdminsTable";
import Configurations from "./Configurations";
import { useAuthUser } from "react-auth-kit";

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);
  const auth = useAuthUser();
  const taps =
    auth().role === "Land Lord"
      ? [{ name: "Admins", index: 0 }]
      : [
          { name: "Admins", index: 0 },
          { name: "Configurations", index: 1 },
        ];

  const handleActiveLink = (index) => {
    setActiveTab(index);
  };
  return (
    <Layout>
      <div className="mt-[0px] mx-[2%]">
        <div className="text-[16px] font-semibold text-[#797979]  ">Settings</div>

        <div className="text-[28px] font-semibold  " style={{ fontFamily: "gilroy-bold" }}>
          {taps[activeTab].name}
        </div>
        <div className="mt-2 mb-0 flex w-full max-w-[500px]   text-[18px] font-semibold     ">
          {taps.map((t, index) => (
            <button
              onClick={() => handleActiveLink(index)}
              className={` ${
                activeTab === t.index
                  ? "text-black border-b-[#32B3BE] border-b-[6px]"
                  : " text-[#8E8E8E]  border-b-[#EFEFEF]  border-b-[6px]"
              } flex   font-semibold  w-full transition-all duration-150 
                  underline-offset-8 
                  `}
              key={index}
            >
              <div className="px-2 w-[115px]">{t.name}</div>
            </button>
          ))}
        </div>
        <div>{activeTab === 0 ? <AdminsTable /> : <Configurations />}</div>
      </div>
    </Layout>
  );
};

export default Settings;
