import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { useVasCart } from "../../../Context/VasCart";
import EditButton from "../../../assets/EditButton.svg";
import { prettyNumber } from "../../../lib/prettyNumber";
import deleteIcon from "../../../assets/icons/whiteTrash.png";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import { http } from "../../../utils/httpCommon";
import { toast } from "react-hot-toast";
import { ADMIN_ROLES } from "../../../lib/enums";
import { useAuthUser } from "react-auth-kit";

const VasCard = ({ data, fetchData }) => {
  const { getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart } = useVasCart();
  const quantity = getItemQuantity(data);
  const auth = useAuthUser();

  const [openedDelete, setOpenedDelete] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});

  const handleDelete = (item) => {
    setDeletedItem(item);
    setOpenedDelete(true);
  };

  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleSubmitDelete = async () => {
    setDeleteLoading(true);
    try {
      console.log(deletedItem)
      await http.delete(`/vas/${deletedItem?._id}`);
      setDeleteLoading(false);
      setOpenedDelete(false);
      toast.success("VAS item has been deleted successfully");
      fetchData()
    } catch (error) {
      setDeleteLoading(false);
      toast.error("Error deleting tutorial");
    }
  };

  return (
    <div className="w-[200px] border-[1px] border-[#EFEFEF] bg-[#FFF] py-2 px-3  rounded-[10px]">
      {/* images */}
      <div className="mt-[5px] relative">
        <LazyLoadImage
          className="h-[110px] w-[160px] object-contain rounded-[10px]"
          height={70}
          width={70}
          src={data?.image}
          alt={data?.name}
        />
        <div className="flex justify-end">
          <Link
            className="absolute top-0 z-20 text-[16px] rounded-[10px] px-2 py-1 font-semibold text-[#34B2C0] bg-[#D6F0F2]"
            to={`/operations/vas/${data?._id}`}
          >
            More info
          </Link>
        </div>
      </div>

      <div className="flex justify-between items-center mt-[20px]">
        <div className="mt-[10px] text-[16px] font-semibold text-[#797979]">
          {data?.name}
        </div>

        {auth().role === ADMIN_ROLES.SA && (
          <div className="flex gap-2 items-center">
            <Link to={`/operations/vas/edit-vas/${data?._id}`}>
              <img src={EditButton} alt="edit" className="h-[24px]" />
            </Link>
            <button
              style={{ backgroundColor: 'red' }}
              onClick={() => handleDelete(data)}
              className="p-1 rounded"
            >
              <img src={deleteIcon} alt="delete" className="h-[24px]" />
            </button>
          </div>
        )}
      </div>

      <div className="text-[#011516] text-[25px]" style={{ fontFamily: "gilroy-bold" }}>
        {prettyNumber(data?.price)} EGP
      </div>
      <div className="text-[15px] text-[#797979]">{data?.unit}</div>

      {/* cart items */}
      <div className="mt-auto">
        {quantity === 0 ? (
          <button
            className="w-full bg-black text-white text-[16px] rounded-[10px] font-bold py-1 mt-2 border-[1px] border-[black]"
            onClick={() => increaseCartQuantity(data)}
          >
            Add to Order
          </button>
        ) : (
          <div className="flex flex-col items-center border-[1px] rounded-[10px] border-[#E4E4E4] p-1">
            <div className="flex justify-between w-full items-center gap-2">
              <button
                className="bg-black w-[30px] h-[30px] flex justify-center items-center rounded-[10px] text-[white] font-bold"
                onClick={() => decreaseCartQuantity(data)}
              >
                -
              </button>
              <span className="text-[16px] font-bold">{quantity}</span>
              <button
                className="bg-black w-[30px] h-[30px] flex justify-center items-center rounded-[10px] text-[white] font-bold"
                onClick={() => increaseCartQuantity(data)}
              >
                +
              </button>
            </div>
          </div>
        )}
      </div>

      <DeleteModal
        opened={openedDelete}
        setOpened={setOpenedDelete}
        deletedItem={deletedItem}
        loading={deleteLoading}
        handleSubmitDelete={handleSubmitDelete}
        deleteMessage="permanently remove the tutorial from the system."
      />
    </div>
  );
};

export default VasCard;
