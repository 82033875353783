import React, { useCallback, useEffect, useState } from "react";
import { Button } from "../../../../components/Button/Button";
import { Link } from "react-router-dom";
import moment from "moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextInput } from "../../../../components/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import backArrow from "../../../../assets/backarrow.svg";
import { Controller } from "react-hook-form";
import { http } from "../../../../utils/httpCommon";
import { Checkbox, PasswordInput } from "@mantine/core";
import { prettyNumber } from "../../../../lib/prettyNumber";

const CalendarFormStep2 = ({
  responseData,
  close,
  paymentMethods,
  fetchAppointmentsData,
  subTotal,
  vat,
  total,
  wht,
  amountPaid,
  discount,
  promoCodeAmount,
}) => {
  const [steps, setSteps] = useState(0);
  const [payWithCredit, setPayWithCredit] = useState(false);

  // const paidAmountValidation = useCallback(() => {
  //   if (payWithCredit) {
  //     return yup.string().optional();
  //   } else {
  //     return yup.string().typeError("Amount paid is required").required("Amount paid is required");
  //   }
  // }, [payWithCredit]);

  const paymentMethodIdValidation = useCallback(() => {
    if (payWithCredit) {
      return yup.string().optional();
    } else {
      return yup
        .number()
        .typeError("Payment method is required")
        .required("Payment method is required");
    }
  }, [payWithCredit]);

  const schema = yup
    .object({
      paidAmount: yup
        .string()
        .typeError("Amount paid is required")
        .required("Amount paid is required"),
      adminPassword:
        steps === 0
          ? yup.string()
          : yup
            .string()
            .typeError("Admin password is required")
            .required("Admin password is required"),
      paymentMethodId: paymentMethodIdValidation(),
      invoice: yup.boolean(),
      vat: yup.boolean(),
      wht: yup.boolean(),
    })
    .required();
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    // paidAmountValidation();
    paymentMethodIdValidation();
    watch("paymentMethodId");
    watch("paidAmount");
  }, [
    payWithCredit,
    watch,
    // paidAmountValidation,
    paymentMethodIdValidation,
  ]);

  const [submitLoading, setSubmitLoading] = useState(false);
  const onSubmit = async (passedData) => {
    if (steps === 0) {
      setSteps(1);
    } else {
      setSubmitLoading(true);
      try {
        await http.put(
          `bookings/${responseData?._id}/pay`,
          payWithCredit
            ? JSON.stringify({
              adminPassword: passedData.adminPassword,
              promoCode: passedData?.promoCode,
              payUsingCredit: payWithCredit,
              paidAmount: passedData?.paidAmount,
            })
            : JSON.stringify({
              adminPassword: passedData.adminPassword,
              paidAmount: passedData?.paidAmount,
              paymentMethodId: passedData?.paymentMethodId,
              promoCode: passedData?.promoCode,
              payUsingCredit: payWithCredit,
            })
        );

        fetchAppointmentsData();
        toast.success("Booking payment created successfully");
        close();
      } catch (error) {
        toast.error(
          error?.response?.data?.message || error?.response?.message || "Something went wrong"
        );
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  return (
    <>
      {steps === 0 ? (
        <div className="px-4 pb-4 ">
          <div className="flex justify-between ">
            <div
              className="text-[32px] "
              style={{
                fontFamily: "gilroy-bold",
              }}
            >
              Confirm Booking
            </div>
          </div>

          <div className="my-2 flex flex-col gap-3">
            <div className="text-[16px] text-[#797979] font-semibold">Facility</div>
            <div className="border-[#E4E4E4] border-[1px] rounded-[10px] p-[6px] flex gap-3">
              <img
                src={responseData?.facility?.mainImage}
                alt={responseData?.facility?.name}
                className="rounded-[10px] w-[100px] object-cover h-[75px] "
              />
              <div className="flex flex-col justify-between w-full  ">
                <div className="text-[18px] " style={{ fontFamily: "gilroy-bold" }}>
                  {responseData?.facility?.name}
                </div>

                <Link
                  to={`/inventory/facility/${responseData?.facility?._id}`}
                  className="text-[16px] text-[#34B2C0] underline"
                >
                  {" "}
                  Quick view
                </Link>
              </div>
            </div>
          </div>

          <div className="my-2 flex flex-col gap-3">
            <div className="text-[#797979] text-[16px] font-semibold">Client information</div>
            <Link
              to={`/community/client/${responseData?.user?._id}`}
              className="border-[#E4E4E4] border-[1px] rounded-[10px] p-[6px] flex gap-3"
            >
              {responseData?.user?.profilePicture && (
                <img
                  src={responseData?.user?.profilePicture}
                  alt={responseData?.user?.name}
                  className="rounded-[10px] w-[65px] object-cover h-[65px] "
                />
              )}
              <div className="flex flex-col justify-center w-full  ">
                <div className="text-[16px] " style={{ fontFamily: "gilroy-bold" }}>
                  {responseData?.user?.name}
                </div>
                {responseData?.user?.company && (
                  <div className="text-[#797979]">
                    Member at " {responseData?.user?.company?.name}"
                  </div>
                )}
              </div>
            </Link>
          </div>

          <div className="my-2 flex flex-col gap-3">
            <div className="text-[#797979] text-[16px] font-semibold">Bookings</div>

            <div className="border-[#E4E4E4] border-[1px] rounded-[10px] px-4 py-2 flex flex-col gap-2">
              <div className="text-[14px] " style={{ fontFamily: "gilroy-bold" }}>
                {responseData?.days?.length}{" "}
                {responseData?.days?.length > 1 ? "Days between" : "Day between"}{" "}
                {moment(responseData?.days[responseData?.days?.length - 1]?.date).format("ddd")}{" "}
                {moment(responseData?.days[responseData?.days?.length - 1]?.date).format(
                  "Do MMM  YYYY"
                )}{" "}
                to{" "}
                {moment(responseData?.days?.map((d, index) => d?.startTime)[0] * 1000).format(
                  "ddd"
                )}{" "}
                {moment(responseData?.days?.map((d, index) => d?.startTime)[0] * 1000).format(
                  "Do MMM  YYYY"
                )}{" "}
              </div>
              <div className="px-4 py-2 text-[14px]">
                <ul className="list-disc">
                  {responseData?.days?.map((d) => (
                    <li key={d?._id}>
                      {moment(d?.date).format("dddd")} {moment(d?.date).format("LL.")}{" "}
                      {moment(d?.startTime * 1000).format("LT")}
                      {"  -  "}
                      {moment(d?.endTime * 1000).format("LT")}{" "}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
            <div className="text-[20px]  mt-5 mb-2 " style={{ fontFamily: "gilroy-bold" }}>
              Payment
            </div>
            {!payWithCredit && !responseData?.receivable && (
              <div className="mt-4 mb-2">
                <div>
                  <FormControl fullWidth sx={{ border: "none !important" }}>
                    <InputLabel>Payment Method</InputLabel>
                    <Controller
                      control={control}
                      name="paymentMethodId"
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <Select
                          sx={{
                            color: "black",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EFEFEF",
                            },
                          }}
                          onBlur={onBlur}
                          onChange={onChange}
                          variant="outlined"
                          labelId="Payment Method-label"
                          id="Payment Method"
                          value={value}
                          label="Payment Method"
                          inputRef={ref}
                          defaultValue=""
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {paymentMethods?.map((l) => {
                            return (
                              (l?.name.toLowerCase() != 'receivable') &&
                              <MenuItem key={l?.qboId} value={l?.qboId}>
                                {l?.name}
                              </MenuItem>
                            )

                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <p className="text-red-500">{errors.paymentMethodId?.message}</p>
                </div>
              </div>
            )}
            {/* <div className="flex gap-6  mt-3">
              <div className="flex gap-[5px] items-center">
                <input
                  type="checkbox"
                  name="needInvoice"
                  id="needInvoice"
                  {...register("invoice")}
                />
                <label htmlFor="needInvoice" className="text-[16px]">
                  Needs Invoice
                </label>
                <div className="invalid-feedback"></div>
              </div>

              <div className="flex gap-[5px] items-center">
                <input type="checkbox" name="vat" id="vat" {...register("vat")} />
                <label htmlFor="vat" className="text-[16px]">
                  Exempt from VAT
                </label>
              </div>

              <div className="flex gap-[5px] items-center">
                <input type="checkbox" name="wht" id="wht" {...register("wht")} />
                <label htmlFor="needInvoice" className="text-[16px]">
                  Apply WHT
                </label>
              </div>
            </div> */}

            <div className="border-[1px]   rounded-[10px]  mt-6 px-4 py-2">
              <div className="text-[16px] " style={{ fontFamily: "gilroy-bold" }}>
                Total Due
              </div>
              <div
                className="mt-4"
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto auto",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <span className=" font-extrabold text-[14px]">Subtotal</span>
                <div className="flex items-center">
                  <span className="text-[#797979]   mx-[10px] ">{prettyNumber(subTotal)} EGP</span>
                </div>
                <div></div>

                {discount && !isNaN(discount) && Number(discount) > 0 ? (
                  <>
                    <div className="font-extrabold">Discount</div>
                    <div className="flex items-center">
                      <span className="text-[#797979] mx-[10px]">
                        -{prettyNumber(discount)} EGP
                      </span>
                    </div>
                    <div></div>
                  </>
                ) : null}

                {wht ? (
                  <>
                    <div className="font-extrabold">WHT</div>
                    <div className="flex items-center">
                      <span className="text-[#797979] mx-[10px]">{prettyNumber(wht)} EGP</span>
                    </div>
                    <div></div>
                  </>
                ) : null}

                {promoCodeAmount && !isNaN(promoCodeAmount) && Number(promoCodeAmount) > 0 ? (
                  <>
                    <div className="font-extrabold">Promo Code</div>
                    <div className="flex items-center">
                      <span className="text-[#797979] mx-[10px]">
                        -{prettyNumber(promoCodeAmount)} EGP
                      </span>
                    </div>
                    <div></div>
                  </>
                ) : null}

                <span className="font-extrabold">VAT</span>
                <div className="flex items-center">
                  <span className="text-[#797979] mx-[10px] ">{prettyNumber(vat)} EGP</span>
                </div>
                <div></div>

                {amountPaid && !isNaN(amountPaid) && Number(amountPaid) > 0 ? (
                  <>
                    <div className="font-extrabold">Amount Paid</div>
                    <div className="flex items-center">
                      <span className="text-[#797979] mx-[10px]">{prettyNumber(amountPaid)}</span>
                    </div>
                    <div></div>
                  </>
                ) : null}
              </div>

              <div className="mt-[15px]">
                <span className="text-[16px] font-semibold">Total</span>
                <br />
                <span className="text-[32px] " style={{ fontFamily: "gilroy-bold" }}>
                  {prettyNumber(total)} EGP
                </span>
              </div>
            </div>
            {/* {!payWithCredit && ( */}
            {!responseData?.receivable && (
              <div className="mt-5 ">
                <TextInput label={"Amount Paid"} {...register("paidAmount")} />
                <p className="text-red-500">{errors.paidAmount?.message}</p>
              </div>
            )}
            {/* )} */}

            {!responseData?.receivable && (
              <div className="flex items-center gap-2 my-4">
                <Checkbox
                  label="Pay with credit"
                  checked={payWithCredit}
                  onChange={(event) => setPayWithCredit(event.currentTarget.checked)}
                  color="rgba(0, 0, 0, 1)"
                  size={"md"}
                />
              </div>
            )}
            <div className="flex w-full justify-end  mt-4 gap-5">
              {/* {!isNewAppointment ? ( */}
              <Button
                secondary={true}
                invert={true}
                onClick={() => {
                  fetchAppointmentsData();
                  setTimeout(() => {
                    close();
                  }, 1000);
                }}
              >
                {responseData?.receivable ? "Close" : "Cancel"}
              </Button>

              {!responseData?.receivable && (
                <Button type="submit" secondary={true}>
                  Pay now
                </Button>
              )}
            </div>
          </form>
        </div>
      ) : (
        <div className="px-10 relative">
          <div
            onClick={() => setSteps(0)}
            className="cursor-pointer flex justify-center items-center w-[40px] aspect-square rounded-full bg-[#EFEFEF] hover:[##686868] "
          >
            <img src={backArrow} alt="back" className="w-[8px] h-[15px] object-contain" />
          </div>
          <div className="flex justify-between ">
            <div
              className="text-[38px] "
              style={{
                fontFamily: "gilroy-bold",
              }}
            >
              Security Confirmation
            </div>
          </div>

          <div className="my-2 flex flex-col gap-3">
            <div className="text-[#797979] text-[16px] font-semibold">Client information</div>
            <Link
              to={`/community/client/${responseData?.user?._id}`}
              className="border-[#E4E4E4] border-[1px] rounded-[10px] p-[5px] flex gap-2"
            >
              <img
                src={responseData?.user?.profilePicture}
                alt={responseData?.user?.name}
                className="rounded-[10px] w-[65px] object-cover h-[65px] "
              />
              <div className="flex flex-col justify-center w-full  ">
                <div className="text-[18px] " style={{ fontFamily: "gilroy-bold" }}>
                  {responseData?.user?.name}
                </div>
                {responseData?.user?.company && (
                  <div className="text-[#797979]">
                    Member at " {responseData?.user?.company?.name}"
                  </div>
                )}
              </div>
            </Link>
          </div>

          <div className="text-[16px]">
            I Admin confirm the payment of "{prettyNumber(+getValues().paidAmount)}" EGP by{" "}
            {responseData?.user?.name} for Booking facility at {responseData?.location?.name} branch
            for {responseData?.days?.length} days
          </div>

          <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-6 ">
              <PasswordInput
                required
                size="lg"
                radius={"md"}
                placeholder="Admin Passowrd"
                {...register("adminPassword")}
              />
              <p className="text-red-500">{errors.adminPassword?.message}</p>
            </div>

            <div className="flex w-full justify-end mt-4 gap-5">
              <Button
                secondary={true}
                invert={true}
                onClick={() => {
                  close();
                  fetchAppointmentsData();
                }}
              >
                Cancel
              </Button>

              <Button loading={submitLoading} type="submit" secondary={true}>
                Confirm
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default CalendarFormStep2;
