import React, { useEffect } from "react";
import { Layout } from "../../components/Layout/Layout";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AmenitiesTable from "./Amenities/AmenitiesTable";
import ResourcesTable from "./Resources/ResourcesTable";
import FacilitiesTable from "./Facilities/FacilitiesTable";
import Locations from "./Locations/Locations";
import PackagesTable from "../Operations/Packages/index";

const Inventory = () => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  // const { tabValue } = useParams();
  //
  // const auth = useAuthUser();

  const taps = [
    { name: "Locations", index: 0 },
    { name: "Facilities", index: 1 },
    { name: "Resources", index: 2 },
    { name: "Amenities", index: 3 },
    { name: "Packages", index: 4, path: "/inventory/packages" },
  ];
  //
  // const areaManagerTaps = [
  //   { name: "Facilities", index: 0 },
  //   { name: "Resources", index: 1 },
  // ];

  const handleActiveLink = (index) => {
    setActiveTab(index);
    if (index === 4) {
      // only for packages i have to navigate to packages page
      // if you want to do for all tabs then you should create routes for all tabs in routes.jsx file
      navigate(`/inventory/packages`, { replace: false });
    }
    // navigate(`/inventory/${taps[index].name}`,{ replace: false });
  };

  const location = useLocation();
  useEffect(() => {
    const currentTab = taps.findIndex((tab) => location.pathname === tab.path);
    if (currentTab !== -1) {
      setActiveTab(currentTab);
    }
  }, [location]);

  return (
    <Layout>
      <div className="mt-[0px] mx-[2%]">
        <div className="text-[16px] font-semibold text-[#797979]  ">Inventory</div>
        {/* 
        {auth().role === "Branch Manager" ? (
          <>
            <div
              className="text-[32px] font-semibold  "
              style={{ fontFamily: "gilroy-bold" }}
            >
              Facility
            </div>
            <FacilitiesTable />
          </>
        ) : auth().role === "Area Manager" ? (
          <>
            <div
              className="text-[32px] font-semibold  "
              style={{ fontFamily: "gilroy-bold" }}
            >
              {taps[activeTab].name}
            </div>
            <div className="mt-2 mb-0 flex w-full max-w-[300px]   text-[18px] font-semibold     ">
              {areaManagerTaps.map((t, index) => (
                <button
                  onClick={() => handleActiveLink(index)}
                  className={` ${
                    activeTab === t.index
                      ? "text-black border-b-[#32B3BE] border-b-[6px]"
                      : " text-[#8E8E8E]  border-b-[#EFEFEF]  border-b-[6px]"
                  } flex   font-semibold  w-full transition-all duration-150 
                  underline-offset-8 
                  `}
                  key={index}
                >
                  <div className="px-2 w-[115px]">{t.name}</div>
                </button>
              ))}
            </div>
            <div>
              {activeTab === 0 ? <FacilitiesTable /> : <ResourcesTable />}
            </div>
          </>
        ) : (
        )} */}

        <>
          <div className="text-[32px] font-semibold  " style={{ fontFamily: "gilroy-bold" }}>
            {taps[activeTab].name}
          </div>
          <div className="mt-2 mb-0 flex w-full max-w-[500px]   text-[18px] font-semibold     ">
            {taps.map((t, index) => (
              <button
                onClick={() => handleActiveLink(index)}
                className={` ${
                  activeTab === t.index
                    ? "text-black border-b-[#32B3BE] border-b-[6px]"
                    : " text-[#8E8E8E]  border-b-[#EFEFEF]  border-b-[6px]"
                } flex   font-semibold  w-full transition-all duration-150 
                  underline-offset-8 
                  `}
                key={index}
              >
                <div className="px-2 w-[115px]">{t.name}</div>
              </button>
            ))}
          </div>
          <div>
            {activeTab === 0 ? (
              <Locations />
            ) : activeTab === 1 ? (
              <FacilitiesTable />
            ) : activeTab === 2 ? (
              <ResourcesTable />
            ) : activeTab === 4 ? (
              <PackagesTable isEdit={true} />
            ) : (
              <AmenitiesTable />
            )}
          </div>
        </>
      </div>
    </Layout>
  );
};

export default Inventory;
