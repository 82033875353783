import React, { useCallback, useState, useEffect } from "react";
import { Layout } from "../../../components/Layout/Layout";
import { Button } from "../../../components/Button/Button";
import { http } from "../../../utils/httpCommon";
import { TextInput } from "../../../components/TextInput/TextInput";
import CustomTextInput from "../../../components/CustomTextInput/CustomTextInput";
import { useForm } from "react-hook-form";
import useFetchData from "../../../hooks/FetchData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import plus from "../../../assets/icons/plus.png";
import { Controller } from "react-hook-form";
import DropFile from "../../../components/DropFile/DropFile";
import { useNavigate, useParams } from "react-router-dom";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import useFetchPostData from "../../../hooks/FetchPostData";
import { toast } from "react-hot-toast";

const EditFacility = () => {
  const navigator = useNavigate();
  const params = useParams();
  const {
    response: facility,
    loading: facilityLoading,
    fetchData: facilityFetchData,
  } = useFetchData(`/facility/${params.id}`);

  //get all amenities
  const { response: amenities, loading } = useFetchPostData(
    "/amenity/all",
    JSON.stringify({
      page: 1,
      limit: 10000,
    })
  );

  const { response: resourses, loading: loadingResources } = useFetchPostData(
    "/resource/all",
    JSON.stringify({
      page: 1,
      limit: 10000,
    })
  );
  const { response: locations, loading: loadingLocations } = useFetchData("/location/me");

  const schema = yup
    .object({
      name: yup.string().required(),
      area: yup
        .number()
        .positive()
        .integer()
        .required()
        .typeError("Area is a required filed and must be positive integer"),
      netArea: yup
        .number()
        .positive()
        .typeError("Net Area is a required filed and must be positive integer")
        .integer()
        .required(),
      rentPricePerMonth: yup
        .number()
        .positive()
        .typeError("Rent price per month is a required filed and must be positive integer")
        .integer()
        .required(),
      bookingPricePerHalfHour: yup
        .number()
        .positive()
        .typeError("Booking price per half hour is a required filed and must be positive integer")
        .integer()
        .required(),
      location: yup.string().required().default(facility?.location),
      notes: yup.string(),
      resource: yup.string(),
    })
    .required();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [checkedAmenities, setCheckedAmenities] = useState(facility?.amenities);
  const handleCheckAmenities = (event) => {
    var updatedList = [...checkedAmenities];
    if (event.target.checked) {
      updatedList = [...checkedAmenities, event.target.value];
    } else {
      updatedList.splice(checkedAmenities.indexOf(event.target.value), 1);
    }
    setCheckedAmenities(updatedList);
  };

  const [checkedResources, setCheckedResources] = useState([facility?.resource]);

  const [mainImage, setMainImages] = useState([]);
  const [galleryImage, setGalleryImage] = useState([]);
  const [galleryImage2, setGalleryImage2] = useState([]);
  const [galleryImage3, setGalleryImage3] = useState([]);
  const [galleryImage4, setGalleryImage4] = useState([]);
  const [galleryImage5, setGalleryImage5] = useState([]);

  const [galleryCount, setGalleryCount] = useState(1);
  const handleAddGalleryImage = () => {
    setGalleryCount(galleryCount + 1);
  };

  const [checkedResource, setCheckedResource] = useState("");
  const handleCheckedResource = (resource) => {
    if (checkedResource === resource) {
      setCheckedResource("");
    } else {
      setCheckedResource(resource?._id);
    }
  };

  useEffect(() => {
    setCheckedResource(facility?.resource?._id);
    setCheckedAmenities(facility?.amenities?.map((a) => a._id));
    setCheckedResources([facility?.resource?._id]);
    setMainImages([{ name: facility?.mainImage, preview: facility?.mainImage }]);

    if (!facilityLoading) {
      setGalleryImage(
        facility?.gallery.length > 0
          ? [
              {
                name: facility?.gallery[0],
                preview: facility?.gallery[0],
              },
            ]
          : []
      );
      setGalleryImage2(
        facility?.gallery.length > 1
          ? [
              {
                name: facility?.gallery[1],
                preview: facility?.gallery[1],
              },
            ]
          : []
      );
      setGalleryImage3(
        facility?.gallery.length > 2
          ? [
              {
                name: facility?.gallery[2],
                preview: facility?.gallery[2],
              },
            ]
          : []
      );
      // if (!facility?.gallery[3] ) {
      setGalleryImage4(
        facility?.gallery.length > 3
          ? [
              {
                name: facility?.gallery[3],
                preview: facility?.gallery[3],
              },
            ]
          : []
      );
      // }
      setGalleryImage5(
        facility?.gallery.length > 4
          ? [
              {
                name: facility?.gallery[4],
                preview: facility?.gallery[4],
              },
            ]
          : []
      );
    }
  }, [facilityLoading]);

  const [submitLoading, setSubmitLoading] = useState(false);

  const onSubmit = async (passedData) => {
    setSubmitLoading(true);

    var data = new FormData();
    data.append("image", mainImage[0]);

    var gallery = new FormData();
    gallery.append("image", galleryImage[0]);

    var gallery2 = new FormData();
    gallery2.append("image", galleryImage2[0]);

    var gallery3 = new FormData();
    gallery3.append("image", galleryImage3[0]);

    var gallery4 = new FormData();
    gallery4.append("image", galleryImage4[0]);

    var gallery5 = new FormData();
    gallery5.append("image", galleryImage5[0]);

    try {
      let res = await http.post("/image", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      let galleryRes;
      if (galleryImage.length > 0) {
        galleryRes = await http.post("/image", gallery, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      let galleryRes2;
      if (galleryImage2.length > 0) {
        galleryRes2 = await http.post("/image", gallery2, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      let galleryRes3;
      if (galleryImage3.length > 0) {
        galleryRes3 = await http.post("/image", gallery3, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      let galleryRes4;
      if (galleryImage4.length > 0) {
        galleryRes4 = await http.post("/image", gallery4, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      let galleryRes5;
      if (galleryImage5.length > 0) {
        galleryRes5 = await http.post("/image", gallery5, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }

      let galleryImagesUploaded = [
        galleryRes?.data?.url,
        galleryRes2?.data?.url,
        galleryRes3?.data?.url,
        galleryRes4?.data?.url,
        galleryRes5?.data?.url,
      ];

      let filteredUploadGallery = galleryImagesUploaded.filter((g) => g !== undefined);

      try {
        var data2 = JSON.stringify({
          name: passedData.name,
          area: passedData.area,
          netArea: passedData.netArea,
          location: passedData.location,
          amenities: checkedAmenities,
          resource: passedData?.resource,
          mainImage: res.data.url,
          gallery: filteredUploadGallery,
          rentPricePerMonth: passedData.rentPricePerMonth,
          bookingPricePerHalfHour: passedData.bookingPricePerHalfHour,
          notes: passedData.notes,
        });
        await http.put(`/facility/${params.id}`, data2, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        setSubmitLoading(false);
        // navigator("/inventory");
        toast.success("Facility has been edited successfully");
      } catch (error) {
        toast.success("Something went wrong");
      }
    } catch (error) {
      toast.success("Something went wrong");
    }
  };

  return (
    <Layout>
      {!loading && !loadingResources && !loadingLocations && !facilityLoading ? (
        <div className="flex justify-center">
          <div className=" w-[75%]  bg-white p-4 m-1">
            <div className="flex flex-col">
              <div className="w-full flex justify-between">
                <span className=" text-[22px] font-bold my-2">Edit Facility</span>
              </div>
              <span className="text-[16px]">Upload Images</span>
              <span className="text-[16px]">Supported formats: JPEG, PNG</span>
            </div>
            <div className="flex gap-[15px] ">
              <div className="w-full min-w-[60%] h-[315px] py-2">
                <div className="font-semibold my-1 text-[16px]">Upload Main image</div>
                <DropFile
                  image={mainImage}
                  setImage={setMainImages}
                  height={"315px"}
                  width={"600px"}
                />
              </div>

              {/* Gallery */}
              <div className="  w-full py-2  overflow-auto ">
                <div className="font-semibold my-1 text-[16px]">Upload Gallery images</div>
                <div className="w-fit gap-[20px] grid  grid-rows-2  grid-flow-col   ">
                  <DropFile
                    image={galleryImage}
                    setImage={setGalleryImage}
                    height={"150px"}
                    width={"275px"}
                  />

                  {galleryImage.length > 0 ? (
                    <DropFile
                      image={galleryImage2}
                      setImage={setGalleryImage2}
                      height={"150px"}
                      width={"275px"}
                    />
                  ) : (
                    <></>
                  )}

                  {galleryImage2.length > 0 ? (
                    <DropFile
                      image={galleryImage3}
                      setImage={setGalleryImage3}
                      height={"150px"}
                      width={"275px"}
                    />
                  ) : (
                    <></>
                  )}

                  {galleryImage3.length > 0 ? (
                    <DropFile
                      image={galleryImage4}
                      setImage={setGalleryImage4}
                      height={"150px"}
                      width={"275px"}
                    />
                  ) : (
                    <></>
                  )}

                  {galleryImage4.length > 0 ? (
                    <DropFile
                      image={galleryImage5}
                      setImage={setGalleryImage5}
                      height={"150px"}
                      width={"275px"}
                    />
                  ) : (
                    <></>
                  )}

                  {galleryImage4.length > 0 ? (
                    <></>
                  ) : (
                    <button
                      style={{ backgroundColor: "rgba(50, 179, 190, 0.1)" }}
                      onClick={() => handleAddGalleryImage}
                      className=" h-[150px] w-[275px]   flex flex-col  items-center justify-center  p-4 rounded-[5px]   border-[1px] border-[#32B3BE] border-dashed "
                    >
                      <img className="w-[45px] h-[45px]" src={plus} alt="plus" />
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* form  */}
            <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-6">
                <TextInput
                  label={"Name*"}
                  defaultValue={facility?.name}
                  {...register("name", { required: "input name is required" })}
                />

                <p className="text-red-500 text-[16px]">{errors.name?.message}</p>
              </div>

              <div className="mt-4">
                <div className=" flex flex-wrap gap-[5px] relative w-full h-fit py-3 bg-white border-[1px] border-[#EFEFEF] rounded-[5px] hover:border-[black] ">
                  <div className="absolute bg-white p-1 -top-4 text-[16px] left-2 text-[#00000099] ">
                    Amenities
                  </div>
                  {amenities?.data
                    ?.filter((a) => !a.isDeleted)
                    .map((item, index) => (
                      <div key={index} className=" ml-4">
                        <input
                          checked={checkedAmenities?.includes(item._id) ? true : false}
                          id={`cb${item._id}`}
                          className="hidden  peer "
                          value={item._id}
                          type="checkbox"
                          onChange={handleCheckAmenities}
                        />
                        <label
                          className="  text-[16px] cursor-pointer hover:bg-[#32B3BE] hover:text-[white] transition-all peer-checked:bg-[#32B3BE] peer-checked:text-[white] peer-checked:font-semibold  text-[#32B3BE] border-[1px] flex justify-center items-center py-1 px-2 border-[#32B3BE] rounded-full min-w-[80px]  "
                          htmlFor={`cb${item._id}`}
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}
                </div>
              </div>

              <div className="mt-4 mb-2 text-[20px] font-bold">Area</div>
              <div className="flex gap-4 w-full">
                <div className="w-1/2">
                  <CustomTextInput
                    label={"Area*"}
                    defaultValue={facility?.area}
                    adornment={
                      <>
                        m<sup>2</sup>
                      </>
                    }
                    {...register("area", { required: "Area is required" })}
                  />
                  <p className="text-red-500 text-[16px]">{errors.area?.message}</p>
                </div>
                <div className="w-1/2">
                  <CustomTextInput
                    label={"Net Area*"}
                    defaultValue={facility?.netArea}
                    adornment={
                      <>
                        m<sup>2</sup>
                      </>
                    }
                    {...register("netArea", {
                      required: "Net Area is required",
                    })}
                  />
                  <p className="text-red-500 text-[16px]">{errors.netArea?.message}</p>
                </div>
              </div>

              <div className="mt-4 flex w-full mb-2 text-[20px] font-bold">Pricing</div>
              <div className="flex w-full gap-4">
                <div className=" w-1/2  ">
                  <CustomTextInput
                    label={"Rent per month*"}
                    defaultValue={facility?.rentPricePerMonth}
                    adornment={<>EGP </>}
                    {...register("rentPricePerMonth", {
                      required: "Rent Price Per Month is required",
                    })}
                  />
                  <p className="text-red-500 text-[16px]">{errors.rentPricePerMonth?.message}</p>
                </div>

                <div className="w-1/2">
                  <CustomTextInput
                    label={"Booking price per half hour*"}
                    defaultValue={facility?.bookingPricePerHalfHour}
                    adornment={<>EGP</>}
                    {...register("bookingPricePerHalfHour", {
                      required: "Booking Price Per Half Hour is required",
                    })}
                  />
                  <p className="text-red-500 text-[16px]">
                    {errors.bookingPricePerHalfHour?.message}
                  </p>
                </div>
              </div>
              <div className="my-4">
                <div>
                  <FormControl fullWidth sx={{ border: "none !important" }}>
                    <InputLabel>Select Location*</InputLabel>
                    <Controller
                      control={control}
                      name="location"
                      defaultValue={facility?.location?._id}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <>
                          <Select
                            sx={{
                              color: "black",
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#EFEFEF",
                              },
                            }}
                            onBlur={onBlur} // notify when input is touched
                            onChange={onChange} // send value to hook form
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value}
                            label="Select Location"
                            inputRef={ref}
                            //   defaultValue={facility?.location}
                          >
                            <MenuItem value="">
                              {" "}
                              <em>None</em>{" "}
                            </MenuItem>
                            {locations
                              ?.filter((l) => !l.isDeleted)
                              ?.map((l) => (
                                <MenuItem key={l._id} value={l._id}>
                                  {l.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </>
                      )}
                    />
                  </FormControl>
                  <p className="text-red-500 text-[16px]">{errors.location?.message}</p>
                </div>
              </div>

              <div className=" mt-4 flex flex-wrap gap-[5px] relative w-full h-fit py-3 bg-white border-[1px] border-[#EFEFEF] rounded-[5px] hover:border-[black] ">
                <div className="absolute bg-white p-1 -top-4 text-[16px] left-2 text-[#00000099] ">
                  Resource
                </div>

                {resourses?.data?.map((item) => {
                  return (
                    <div
                      key={item?._id}
                      className={` ml-2 mt-1 cursor-pointer hover:bg-[#32B3BE] hover:text-[white] transition-all
                    ${checkedResource === item?._id ? "bg-[#32B3BE]" : ""} ${
                        checkedResource === item?._id ? "text-[#FFFFF1]" : ""
                      }
                      ${checkedResource === item?._id ? "font-semibold" : "font-semibold"}
                     text-[#32B3BE] border-[1px] flex justify-center items-center py-1 px-2 border-[#32B3BE]
                    rounded-full  w-fit min-w-[100px] text-[16px] `}
                      onClick={() => {
                        item?._id === checkedResource
                          ? setValue("resource", null)
                          : setValue("resource", item?._id);
                        handleCheckedResource(item);
                      }}
                      {...register("resource", {
                        required: "Resource is required",
                      })}
                    >
                      {item?.name}
                    </div>
                  );
                })}
              </div>

              <div className="mt-4 ">
                <TextInput label={"Notes"} defaultValue={facility?.notes} {...register("notes")} />
                <p className="text-red-500 text-[16px]">{errors.notes?.message}</p>
              </div>
              <div className="mt-4 mb-8 flex justify-end gap-10">
                <Button onClick={() => navigator("/inventory")} secondary={true} invert={true}>
                  Cancel
                </Button>
                <Button loading={submitLoading} secondary={true} type="submit">
                  Edit
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <LoadingCircle />
      )}
    </Layout>
  );
};

export default EditFacility;
