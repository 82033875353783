import React from "react";
import CompaniesTable from "./CompaniesTable";

const Companies = () => {
  return (
    <>
      <CompaniesTable />
    </>
  );
};

export default Companies;
