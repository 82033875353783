import moment from "moment";
import React, { useEffect, useState } from "react";
import RightArrow from "../../../assets/icons/RightButton.svg";
import LeftArrow from "../../../assets/icons/LeftButton.svg";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Link } from "react-router-dom";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { http } from "../../../utils/httpCommon";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { getDates } from "../../../utils/getDatesDifference";

const ListView = () => {
  const currentDate = new Date();

  const [startDateValue, setStartDateValue] = React.useState(
    new Date(currentDate.setDate(currentDate.getDate() - 7)).toDateString()
  );
  const [endDateValue, setEndDateValue] = React.useState(
    new Date().toDateString()
  );

  const handleChangeStartDateValue = (newValue) => {
    setStartDateValue(newValue);
  };

  const handleChangeEndDateValue = (newValue) => {
    setEndDateValue(newValue);
  };

  const handlePreviousDay = () => {
    const prevDay = new Date(
      new Date(startDateValue).getFullYear(),
      new Date(startDateValue).getMonth(),
      new Date(startDateValue).getDate() - 1
    );
    setStartDateValue(prevDay);
  };

  const handleNextDay = () => {
    const nextDay = new Date(
      new Date(startDateValue).getFullYear(),
      new Date(startDateValue).getMonth(),
      new Date(startDateValue).getDate() + 1
    );
    setStartDateValue(nextDay);
  };

  const [appointmentsDataLoading, setAppointmentsDataLoading] = useState(false);
  const [appointmentsData, setAppointmentsData] = useState([]);

  //   to do fetch the right data
  const fetchAppointmentsData = async () => {
    setAppointmentsDataLoading(true);
    try {
      const res = await http.get(
        `/bookings`,
        JSON.stringify({
          page: 1,
          limit: 10000,
          from: moment(startDateValue).format("YYYY-MM-DD"),
          to: moment(new Date(endDateValue)).format("YYYY-MM-DD"),
        })
      );

      let flatedData = res?.data?.data.flatMap((item) => {
        const days = item.days.map((day) => {
          return {
            ...day,
            location: item?.location,
            isConfirmed: item?.isConfirmed,
            isDeleted: item?.isDeleted,
            facility: item?.facility,
            user: item?.user,
            createdAt: item?.createdAt,
            updatedAt: item?.updatedAt,
          };
        });
        return days;
      });
      setAppointmentsData(
        flatedData.map((a) => ({
          ...a,
          id: a?._id,
          startDate: new Date(a.startTime * 1000),
          endDate: new Date(a.endTime * 1000),
          roomId: a?.facility?._id,
          location: a?.facility?.name,
        }))
      );
      setAppointmentsDataLoading(false);
    } catch (error) {
      setAppointmentsDataLoading(false);
    }
  };

  useEffect(() => {
    fetchAppointmentsData();
  }, [startDateValue, endDateValue]);

  const dates = getDates(startDateValue, new Date(endDateValue));

  const renderList = (date) => {
    const filteredAppointments = appointmentsData?.filter(
      (appointment) =>
        moment(appointment?.startDate).format("YYYY-MM-DD") ===
        moment(date).format("YYYY-MM-DD")
    );

    return filteredAppointments?.map((appointment) => (
      <>
        <div
          key={appointment?.id}
          className="flex items-center w-full py-2  [&:nth-child(odd)]:bg-[#EFEFEF] bg-opacity-50 min-w-[600px] justify-start gap-[50px] "
        >
          <div className="flex items-center font-bold  text-[16px] w-[250px]">
            <div className="bg-[#D9D9D9] w-[10px]  h-[10px] rounded-full mx-4"></div>
            {appointment?.allDay && (
              <div className="text-[#34B2C0]">Fully booked Day</div>
            )}
            {moment(appointment?.startTime * 1000).format("LT")} -{" "}
            {moment(appointment?.endTime * 1000).format("LT")}
          </div>
          <div className="text-[18px]  w-[250px]">
            {appointment?.facility?.name}
          </div>
          <Link
            className="hover:text-[#34B2C0] text-[18px]  w-[200px] "
            to={`/community/client/${appointment?.user?._id}`}
          >
            <span className="text-[#686868]">booked by : </span>
            {appointment?.user?.name}
          </Link>
          <div className="text-[#797979] text-[16px] mx-10">Userbooking</div>
        </div>
      </>
    ));
  };
  return (
    <div className="relative">
      <div className="relative w-full flex justify-center items-center">
        <div className="flex   items-center -top-10 absolute justify-center  min-w-[700px] left-[20%] right-[50%] mb-[20px] mt-[0px]">
          <button
            className="text-[28px] w-[44px] flex justify-center items-center h-[44px] hover:bg-gray-200 rounded-full transition duration-75"
            onClick={handlePreviousDay}
          >
            <img src={LeftArrow} alt="Back" className="w-[12px]" />
          </button>
          <button
            className="text-[28px] w-[44px] flex justify-center items-center h-[44px] hover:bg-gray-200 rounded-full transition duration-75"
            onClick={handleNextDay}
          >
            <img src={RightArrow} alt="next" className="w-[12px]" />
          </button>

          <div className="relative flex gap-[40px] items-center">
            <div className=" text-[20px] text-[#011516]  relative flex w-fit">
              <div
                className=" text-[20px] text-[#011516]  relative"
                style={{ fontFamily: "gilroy-bold" }}
              >
                {new Date(startDateValue).toDateString()}
              </div>
              <div className="relative">
                <div className="absolute -bottom-[10px] -right-12">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label=""
                      placeholder=""
                      value={startDateValue}
                      onChange={handleChangeStartDateValue}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            width: "100%",
                            color: "#000000cc",
                            "& .MuiFormControl-root ": {
                              borderColor: "none",
                            },
                            "& .MuiOutlinedInput-input": {
                              visibility: "hidden !important",
                            },
                            "& .MuiInputBase-root": {
                              "& fieldset": {
                                border: "none !important",
                              },
                              "&:hover fieldset": {
                                border: "none !important",
                              },
                              "&.Mui-focused fieldset": {
                                border: "none !important",
                              },
                            },
                            "& .MuiFormLabel-root ": {
                              display: "none",
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="relative left-4 text-[30px]">{" - "}</div>
            {/* End */}
            <div className=" text-[20px] text-[#011516]  relative flex w-fit">
              <div
                className=" text-[20px] text-[#011516]  relative"
                style={{ fontFamily: "gilroy-bold" }}
              >
                {new Date(endDateValue).toDateString()}
              </div>
              <div className="relative">
                <div className="absolute -bottom-[10px] -right-12">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label=""
                      placeholder=""
                      value={endDateValue}
                      onChange={handleChangeEndDateValue}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            width: "100%",
                            color: "#000000cc",
                            "& .MuiFormControl-root ": {
                              borderColor: "none",
                            },
                            "& .MuiOutlinedInput-input": {
                              visibility: "hidden !important",
                            },
                            "& .MuiInputBase-root": {
                              "& fieldset": {
                                border: "none !important",
                              },
                              "&:hover fieldset": {
                                border: "none !important",
                              },
                              "&.Mui-focused fieldset": {
                                border: "none !important",
                              },
                            },
                            "& .MuiFormLabel-root ": {
                              display: "none",
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>
          {/* New Date */}
        </div>
      </div>
      {/* List */}
      {appointmentsDataLoading ? (
        <LoadingCircle />
      ) : (
        dates.map((date) => (
          <div className="flex flex-col mt-3">
            <div
              className="text-[#34B2C0] text-[22px] mx-4"
              style={{ fontFamily: "gilroy-bold" }}
            >
              {moment(date).format("ddd ,")}{" "}
              {moment(date).format("MMM DD , YYYY")}
            </div>
            <div>{renderList(date)}</div>

            <hr className="text-[#DEDEDE] my-8" />
          </div>
        ))
      )}
    </div>
  );
};

export default ListView;
