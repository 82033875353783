import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "../../../assets/icons/DeleteIcon.png";
import uploadIcon from "../../../assets/uploadIcon.svg";
import { TextInput } from "../../../components/TextInput/TextInput";
import ModalComponent from "../../../components/Modal/Modal";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Button } from "../../../components/Button/Button";
import { http } from "../../../utils/httpCommon";
import { toast } from "react-hot-toast";
const NewAmenity = ({ opened, setOpened, fetchData }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    name: "",
  });

  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    // Do something with the files
  }, []);
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

  const images = files.map((file) => <img className=" h-[120px] w-[150px] object-contain" key={file.name} src={file.preview} alt={file.name} />);

  const [submitLoading, setSubmitLoading] = useState(false);
  const onSubmit = async (passedData) => {
    setSubmitLoading(true);
    var data = new FormData();
    data.append("image", files[0]);
    try {
      const res = await http.post("/image", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      try {
        var data2 = JSON.stringify({
          name: passedData.name,
          icon: res.data.url,
        });
        await http.post("/amenity", data2, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setSubmitLoading(false);
        setOpened(false);
        setFiles([]);
        fetchData();
        reset();
        setError("");
        toast.success("New Amenity has been added successfully");
      } catch (error) {
        toast.error("Something went wrong");
        setError(error.response.data.message);
      }
    } catch (error) {}
  };

  return (
    <ModalComponent opened={opened} setOpened={setOpened}>
      <div className="text-[24px] font-bold">Add Amenity</div>
      <form onSubmit={handleSubmit(onSubmit)} className="  flex flex-col justify-center items-center gap-[40px] mt-4">
        <div className="relative  flex   justify-center items-start mt-4 w-full">
          <div
            className="flex flex-col items-center p-5  max-h-[250px] rounded-[20px] w-[60%]   border-[1px] border-[#4987FF] border-dashed "
            style={{ backgroundColor: "rgba(73, 135, 255, 0.05" }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <>
                {/* {images.length > 0 ? (
                  <div className="relative">{images}</div>
                ) : ( */}
                <>
                  <img className="w-[100px] " src={uploadIcon} alt="upload icon" />
                  <p className=" font-medium  text-[16px] ">Drop the icon here ...</p>
                </>
                {/* // )} */}
              </>
            ) : (
              <>
                {images.length > 0 ? (
                  <div className="relative">{images}</div>
                ) : (
                  <>
                    <img className="w-[70px] " src={uploadIcon} alt="upload icon" />
                    <p className=" font-medium text-[16px] ">Upload Amenity Icon</p>
                  </>
                )}
              </>
            )}
          </div>
          {images.length > 0 ? (
            <button
              onClick={() => {
                setFiles([]);
              }}
              className="relative  flex justify-center items-center mx-2 top-6 right-16 bg-[#E4E4E4] w-[40px] h-[40px] rounded-full z-50  hover:bg-gray-100 transition-all"
            >
              <img className=" w-[15px] h-[15px]  " src={DeleteIcon} alt="delete" />
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className="w-[90%]">
          <TextInput label={"Amenity name"} {...register("name", { required: "Amenity Name is required" })}></TextInput>
          <ErrorMessage name="name" errors={errors} render={({ message }) => <p className="text-red-500">{message}</p>} />
          <p className="text-red-500">{error}</p>
        </div>

        <div className="flex justify-around  w-full">
          <Button secondary={true} invert={true} onClick={() => setOpened(false)}>
            Cancel
          </Button>
          <Button loading={submitLoading} secondary={true} type="submit">
            Add
          </Button>
        </div>
      </form>
    </ModalComponent>
  );
};

export default NewAmenity;
