import React from "react";
import { Link } from "react-router-dom";

const RentContract = () => {
  return (
    <div>
      <div className="flex gap-5">
        <span style={{ fontFamily: "gilroy-bold" }}> Contract Details </span>
        <span className="text-[#00AD3B] font-semibold">*Active Contract</span>
      </div>
      <div className="flex flex-col mt-6 ">
        <span className="text-[#686868] font-semibold">Facility</span>
        <span style={{ fontFamily: "gilroy-bold" }}> Facility Name </span>
      </div>

      <div className="flex flex-col mt-6 ">
        <span className="text-[#686868] font-semibold">Starting Date</span>
        <span style={{ fontFamily: "gilroy-bold" }}> 23 | 12 | 2022 </span>
      </div>

      <div className="flex flex-col mt-6 ">
        <span className="text-[#686868] font-semibold">Status</span>
        <span style={{ fontFamily: "gilroy-bold" }}> Status Type </span>
      </div>

      <div className="flex flex-col mt-6 ">
        <span className="text-[#686868] font-semibold">Payment Status</span>
        <span style={{ fontFamily: "gilroy-bold" }}> Type </span>
      </div>

      <div className="flex flex-col mt-6 ">
        <Link className=" text-[#32B3BE] font-semibold underline">
          Rent contract details
        </Link>
      </div>
    </div>
  );
};

export default RentContract;
