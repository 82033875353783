/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { PasswordInput, Paper, Title, Text, Button } from "@mantine/core";
import { Link } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/loginlogo.jpg";
import { useParams } from "react-router-dom";

const SetPassword = () => {
  const [value, setValue] = useState("");
  const [confirmValue, setConfirmValue] = useState("");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSucess] = useState(false);
  const { token } = useParams();

  //handel submit login for reset password
  const handleSubmit = async (e) => {
    e.preventDefault();
    //check if the two password matches
    if (value != confirmValue) {
      setError(true);
      setMessage("Passwords does't match");
    } else {
      var data = JSON.stringify({
        password: value,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_URL}/admin/resetpassword`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          setError(false);
          setIsSucess(true);
          setMessage("Password is set successfully!");
        })
        .catch(function (error) {
          
          setError(true);
          setMessage(error);
        });
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alginItem: "center",
          width: "50%",
        }}
      >
        <img width={700} height={700} src={logo} alt="Logo" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alginItem: "center",
          position: "relative",
          height: "100%",
          width: "50%",
        }}
      >
        <Paper
          sx={{
            backgroundColor: "black",
            borderRadius: 0,
            height: "100vh",
            width: "100vw",
            margin: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title
            align="center"
            sx={(theme) => ({
              fontFamily: `Greycliff CF, ${theme.fontFamily}`,
              fontWeight: 900,
              color: "white",
            })}
          >
            <span>Welcome to</span>
            <br />
            <span>MQR Operations Portal</span>
          </Title>
          <Paper
            style={{ height: "300px", width: "350px" }}
            withBorder
            shadow="md"
            p={35}
            mt={35}
            radius="md"
          >
            <form onSubmit={handleSubmit}>
              <Text size="md" align="center" mt={5}>
                Please set your password
              </Text>
              <PasswordInput
                className="password"
                placeholder="Password"
                label="Password"
                required
                value={value}
                onChange={(event) => setValue(event.currentTarget.value)}
              />
              <PasswordInput
                placeholder="Password"
                label="Confirm Password"
                required
                value={confirmValue}
                onChange={(event) => setConfirmValue(event.currentTarget.value)}
              />
              <Button
                fullWidth
                mt="xl"
                type="submit"
                style={{
                  backgroundColor: "black",
                }}
              >
                Submit new User
              </Button>
            </form>
            {error ? (
              <div
                style={{
                  margin: "10px",
                  fontSize: "14px",
                  textAlign: "center",
                  color: "red",
                }}
              >
                {message}
              </div>
            ) : (
              <></>
            )}
            {!error && isSuccess ? (
              <div
                style={{
                  margin: "10px",
                  fontSize: "14px",
                  textAlign: "center",
                  color: "#1c7ed6",
                }}
              >
                {message}
                <Link style={{ margin: "0 0px 0 5px" }} to="/login">
                  Login
                </Link>
              </div>
            ) : (
              <></>
            )}
          </Paper>
        </Paper>
      </div>
    </div>
  );
};

export default SetPassword;
