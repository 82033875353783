import React from "react";
import CircularProgress from '@mui/material/CircularProgress';

const LoadingCircle = () => {
  return (
    <div className="flex justify-center items-center h-full w-full">
      <CircularProgress color="inherit" />
    </div>
  );
};

export default LoadingCircle;
