import React from "react";
import CheckedInTable from "./CheckedInTable";

const CheckedIn = () => {
  return (
    <>
      <CheckedInTable />
    </>
  );
};

export default CheckedIn;
