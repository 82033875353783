import React, { useEffect, useState } from "react";
import { Layout } from "../../../../components/Layout/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import useFetchData from "../../../../hooks/FetchData";
import whiteEdit from "../../../../assets/icons/whiteEdit.png";
import archiveImg from "../../../../assets/icons/archive.png";
import phoneIcon from "../../../../assets/icons/call.png";
import atIcon from "../../../../assets/icons/@.png";
import plusIcon from "../../../../assets/icons/whitePlus.png";
import MqrCoin from "../../../../assets/icons/mqrCoin.png";
// import DeleteImage from "../../../../assets/icons/deleteIcon1.png";
import DeleteImage from "../../../../assets/icons/DeleteIcon.png";
import notificationIcon from "../../../../assets/icons/notificationIcon.png";
// import NewCheckIn from "./NewCheckIn";
// import ArchiveClient from "./ArchiveClient";
// import SendClientNotification from "./SendClientNotification";
import AsyncSelect from "react-select/async";
import LoadingCircle from "../../../../components/LoadingCircle/LoadingCircle";
import downloadIcon from "../../../../assets/icons/downloadIcon.png";
import fileIcon from "../../../../assets/icons/fileIcon.png";
import ActivityLogs from "../../../../components/ActivityLogs/ActivityLogs";
import RentContract from "../../../../components/RentContract/RentContract";
import ArchiveCompany from "./ArchiveCompany";
import SendCompanyNotification from "./SendCompanyNotification";
import DeleteCompany from "./DeleteClient";
import Trash from "../../../../assets/icons/DeleteIcon.png";
import { useAuthUser } from "react-auth-kit";
import ModalComponent from "../../../../components/Modal/Modal2";
import { Button } from "../../../../components/Button/Button";
import Table from "../../../../components/DataGrid/Table";
import { CustomNoOptionsMessage } from "../../../Operations/CheckedIn/CheckinModal";
import { http } from "../../../../utils/httpCommon";
import toast from "react-hot-toast";
import ClientSubscriptions from "../../Clients/Client/ClientSubscriptions";

const Company = () => {
  const params = useParams();
  const { response, error, loading, fetchData } = useFetchData(`/company/${params.id}`);
  const auth = useAuthUser();
  const navigator = useNavigate();

  const tabs = {
    CompanyInfo: "Company info",
    CoinBank: "Coin Bank",
    Employees: "Employees",
    RentContracts: "Rent Contracts",
    ActivityLogs: "Activity logs",
    Subscriptions: "Subscriptions",
  };

  const [activeTab, setActiveTab] = useState(tabs.CompanyInfo);
  const handleActiveLink = (index) => {
    setActiveTab(index);
  };

  //handle Archive
  const [openArchiveCompanyModal, setOpenArchiveCompanyModal] = useState(false);
  const [openSendNotificationModal, setOpenSendNotificationModal] = useState(false);

  //handle members
  const e = useFetchData(`/company/${params.id}/employees`);
  const employeesColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => {
        return (
          <button
            onClick={() => deleteFromCompany(params?.id)}
            className="group my-2 font-semibold text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
          >
            <div className="flex justify-center text-[16px] items-center bg-red-500 w-[34px] h-[34px] transition-all rounded-[10px] mr-2">
              <img className=" w-[18px] " src={DeleteImage} alt="delete" />
            </div>
            Remove
          </button>
        );
      },
    },
  ];

  const [employeeModal, toggleEmployeeModal] = useState(false);

  const [openDeleteCompanyModal, setOpenDeleteCompanyModal] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (image) => {
    setModalIsOpen(true);
    setSelectedImage(image);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage("");
  };

  const loadOptions = async (inputText, callback) => {
    if (inputText.length > 0) {
      const r = await http.get(`/client/find?q=${inputText}`);
      const d = r.data.data;
      callback(
        d.map((i) => ({
          label: (
            <div key={`${i.phone}-${i?.name}`}>
              {i.name}/{i.phone}
            </div>
          ),
          value: i._id,
        })),
      );
    }
  };

  const [saving, setSaving] = useState(false);
  const [selectedEmps, setSelectedEmps] = useState(false);

  const saveToCompany = async () => {
    try {
      setSaving(true);
      await http.post("/company/employees/assign", {
        company: params.id,
        employees: selectedEmps,
      });
      toast.success("Assigned successfully");
      e.fetchData();
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || "An error occurred");
    } finally {
      setSaving(false);
    }
  };

  const deleteFromCompany = async (id) => {
    try {
      setSaving(true);
      await http.post("/company/employees/unassign", {
        company: params.id,
        employees: [id],
      });
      toast.success("Unassigned successfully");
      e.fetchData();
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || "An error occurred");
    } finally {
      setSaving(false);
    }
  };
  return (
    <>
      <Layout>
        {!loading ? (
          <>
            <ModalComponent
              opened={modalIsOpen}
              setOpened={setModalIsOpen}
              onClose={closeModal}
              size="40%"
            >
              <div className="flex justify-center items-center flex-col w-full">
                <img src={selectedImage} alt={selectedImage} />

                <div className="flex  gap-[30px] py-6">
                  <Button secondary={true} invert={true} onClick={closeModal}>
                    Close
                  </Button>
                  <Button onClick={() => window.open(selectedImage, "_blank")}>Download</Button>
                </div>
              </div>
            </ModalComponent>
            <ArchiveCompany
              opened={openArchiveCompanyModal}
              setOpened={setOpenArchiveCompanyModal}
              name={response?.name}
              id={response?._id}
            />
            <DeleteCompany
              opened={openDeleteCompanyModal}
              setOpened={setOpenDeleteCompanyModal}
              name={response?.name}
              id={response?._id}
            />
            <SendCompanyNotification
              opened={openSendNotificationModal}
              setOpened={setOpenSendNotificationModal}
              res={response}
            />
            <div className="bg-white m-4 gap-[50px] rounded-[20px] flex py-4 px-6   w-[90%] min-h-[400px] ">
              <div className="flex flex-col h-full  ">
                {response?.logo && (
                  <img
                    className="w-full h-[250px]  object-cover rounded-[20px] "
                    src={response?.logo}
                    alt={response?.name}
                  />
                )}

                <div className="mt-4 text-[24px] font-bold  " style={{ fontFamily: "gilroy-bold" }}>
                  {response?.name}
                </div>
              </div>

              <div className="flex flex-col h-full w-full  overflow-x-auto  ">
                {auth().role !== "Land Lord" && (
                  <div className="flex justify-between items-center w-full">
                    <div className="flex gap-[40px] my-2">
                      <button
                        onClick={() => navigator(`/community/edit-company/${response._id}`)}
                        className="group text-[#686868] flex items-center text-[16px] hover:text-[#32B3BE] transition-all "
                      >
                        <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                          <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                        </div>
                        Edit Profile
                      </button>

                      {auth().role === "Experience Officer" ||
                      auth().role === "Branch Manager" ||
                      auth().role === "Area Manager" ? null : (
                        <>
                          <button
                            onClick={() => setOpenArchiveCompanyModal(true)}
                            className="group text-[#686868] flex items-center text-[16px] hover:text-[#32B3BE] transition-all "
                          >
                            <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                              <img className=" w-[18px] " src={archiveImg} alt="Archive" />
                            </div>
                            Archive
                          </button>
                          {/* <button
                      onClick={() => setOpenSendNotificationModal(true)}
                      className="group text-[#686868] flex items-center text-[16px] hover:text-[#32B3BE] transition-all "
                    >
                      <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                        <img
                          className=" w-[18px] "
                          src={notificationIcon}
                          alt="notificationIcon"
                        />
                      </div>
                      Send Notifications
                    </button> */}

                          <button
                            onClick={() => setOpenDeleteCompanyModal(true)}
                            className="text-[16px] group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                          >
                            <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                              <img className=" w-[18px] " src={Trash} alt="Archive" />
                            </div>
                            Delete
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
                {/* Taps */}
                <div className="my-2  flex    text-[14px] font-semibold     ">
                  {Object.values(tabs).map((value) => (
                    <button
                      onClick={() => handleActiveLink(value)}
                      className={` ${
                        activeTab === value
                          ? "text-black border-b-[#32B3BE] border-b-[6px]"
                          : " text-[#8E8E8E]  border-b-[#EFEFEF]  border-b-[6px]"
                      } flex   font-semibold  w-full transition-all duration-150 
                  underline-offset-8 
                  `}
                      key={value}
                    >
                      <div className="px-1 w-[120px]">{value}</div>
                    </button>
                  ))}
                </div>

                <div>
                  {activeTab === tabs.CompanyInfo ? (
                    // Personal info
                    <div className="flex flex-col my-3">
                      {response?.onBoarded ? (
                        <div
                          style={{ fontFamily: "gilroy-bold" }}
                          className=" text-[16px] mb-2 text-[#34B2C0] w-fit bg-[#3bb2c0] bg-opacity-20  p-2 flex  justify-center items-center rounded-[25px]   "
                        >
                          On the App
                        </div>
                      ) : (
                        <div className="text-[16px] font-semibold bg-[#EFEFEF] w-fit p-2 rounded-[20px] my-2 text-[#797979] ">
                          Not yet on the app
                        </div>
                      )}{" "}
                      {response?.industry?.name && (
                        <div className="font-semibold my-2">{response?.industry?.name}</div>
                      )}
                      <div
                        style={{
                          borderBottom: " 2px solid rgba(228, 228, 228, 0.59)",
                        }}
                      />
                      {response?.phone && (
                        <div className="flex items-start my-2 gap-[10px]">
                          <img className="w-[15px] mt-1" src={phoneIcon} alt={response?.phone} />
                          <div className="flex flex-col">
                            <span className="font-semibold">{response?.phone}</span>{" "}
                            {response?.phoneVerified ? (
                              <span className="text-[#00AD3B] font-medium">*Status Verified </span>
                            ) : (
                              <span className="text-[#B3B3B3] text-[16px] font-medium">
                                *Pending Verification
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      {response?.email && (
                        <div className="flex items-start my-2 gap-[10px]">
                          <img className="w-[15px] mt-1" src={atIcon} alt={response?.email} />
                          <div className="flex flex-col">
                            <span className="font-semibold">{response?.email}</span>
                            {response?.emailVerified ? (
                              <span className="text-[#00AD3B] font-medium">*Status Verified </span>
                            ) : (
                              <span className="text-[#B3B3B3] text-[16px] font-medium">
                                *Pending Verification
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      <div
                        style={{
                          borderBottom: " 2px solid rgba(228, 228, 228, 0.59)",
                        }}
                      />
                      <div className="my-2">
                        <div className="flex gap-[10px] w-full flex-wrap">
                          <div className="mt-2 flex items-center gap-3  border-[1px] border-[#EFEFEF] rounded-[20px] w-fit p-2">
                            <div className="bg-[#3bb2c0]  bg-opacity-20 w-[72px] h-[72px] flex items-center justify-center rounded-[20px] ">
                              <img className="w-[23px] " src={fileIcon} alt="file" />
                            </div>

                            <div>
                              <span className="text-[16px]" style={{ fontFamily: "gilroy-bold" }}>
                                Document
                              </span>
                              <div className="mt-2 text-[16px] flex">
                                <button
                                  onClick={() => openModal(response?.registrationDocument)}
                                  className=" mr-2 text-[#8E8E8E] "
                                >
                                  View
                                </button>
                                <button
                                  onClick={() =>
                                    window.open(response?.registrationDocument, "_blank")
                                  }
                                  className="flex  items-center font-semibold gap-2 text-[#32B3BE]"
                                >
                                  Download
                                  <img className="w-[14px] " src={downloadIcon} alt="download" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : activeTab === tabs.CoinBank ? (
                    <div>
                      <div className="mt-6 ">
                        <div
                          className="text-[18px] flex gap-10 "
                          style={{ fontFamily: "gilroy-bold" }}
                        >
                          Coin Bank
                          <button className="text-[#34B2C0]">Purchase Coins</button>
                        </div>
                        <div className=" border-[1px] border-[#79797933] w-fit my-3 p-5 rounded-[10px] ">
                          <span className=" text-[#797979] font-semibold">
                            Current Coin Balance
                          </span>
                          <div className="flex gap-[10px] items-center mt-2">
                            <img className="w-[40px] " src={MqrCoin} alt="Mqr Coin" />
                            <span className="text-[40px]" style={{ fontFamily: "gilroy-bold" }}>
                              {response?.publicCoinBalance
                                ? response?.publicCoinBalance
                                : response?.publicCoinBalance}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 ">
                        <div
                          className="text-[18px] flex gap-10 "
                          style={{ fontFamily: "gilroy-bold" }}
                        >
                          Custom Balances
                        </div>
                        <div className="flex gap-10">
                          <div className=" border-[1px] border-[#79797933] w-fit my-3 p-5 rounded-[10px] ">
                            <span className=" text-[#797979] font-semibold">Admins’ Balance</span>
                            <div className="flex gap-[10px] items-center mt-2">
                              <img className="w-[40px] " src={MqrCoin} alt="Mqr Coin" />
                              <span className="text-[40px]" style={{ fontFamily: "gilroy-bold" }}>
                                {response?.privateCoinBalance
                                  ? response?.privateCoinBalance
                                  : response?.privateCoinBalance}
                              </span>
                            </div>
                          </div>

                          <div className=" border-[1px] border-[#79797933] w-fit my-3 p-5 rounded-[10px] ">
                            <span className=" text-[#797979] font-semibold">Spokes’ Balance</span>
                            <div className="flex gap-[10px] items-center mt-2">
                              <img className="w-[40px] " src={MqrCoin} alt="Mqr Coin" />
                              <span className="text-[40px]" style={{ fontFamily: "gilroy-bold" }}>
                                {response?.coins ? response?.coins : response?.coins}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : activeTab === tabs.Employees ? (
                    <div className="mt-6 ">
                      <Button
                        onClick={() => toggleEmployeeModal(true)}
                        className="mb-4 group font-semibold text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                      >
                        <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                          <img className=" w-[18px] " src={plusIcon} alt="Add" />
                        </div>
                        Add Employee
                      </Button>

                      <ModalComponent
                        opened={employeeModal}
                        setOpened={toggleEmployeeModal}
                        onClose={() => toggleEmployeeModal(false)}
                        size="40%"
                      >
                        <div>
                          {/* select client or add new */}
                          <h1 className="font-bold mb-4">Select or add new clients</h1>
                          <AsyncSelect
                            isDisabled={saving}
                            className="w-full"
                            components={{
                              NoOptionsMessage: ({ inputValue }) => (
                                <CustomNoOptionsMessage
                                  inputValue={inputValue}
                                  onResetClick={() => {}}
                                  navigator={navigator}
                                />
                              ),
                            }}
                            isMulti={true}
                            isClearable
                            defaultOptions
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                minHeight: "48px",
                                borderRadius: "8px",
                                width: "100%",
                              }),
                              option: (styles) => ({
                                ...styles,
                                zIndex: "999 !important",
                                backgroundColor: "white !important",
                              }),
                            }}
                            placeholder={"Search for Clients"}
                            onChange={(newValue) => setSelectedEmps(newValue.map((v) => v.value))}
                            loadOptions={loadOptions}
                          />

                          <div className="flex justify-end gap-[30px] py-6">
                            <Button
                              secondary={true}
                              invert={true}
                              onClick={() => toggleEmployeeModal(false)}
                            >
                              Close
                            </Button>
                            <Button secondary={true} onClick={() => saveToCompany()}>
                              Save
                            </Button>
                          </div>
                        </div>
                      </ModalComponent>

                      <div>
                        <Table
                          isLoading={e.loading}
                          data={e.response?.[0]?.employees || []}
                          removeSearch={true}
                          disableFilters={true}
                          removeActionButton={true}
                          columns={employeesColumns}
                          title={"Employees"}
                          actionButton={"Add Employee"}
                          hidePagination={true}
                        />
                      </div>
                    </div>
                  ) : activeTab === tabs.RentContracts ? (
                    <div className="mt-6 ">
                      <RentContract />
                    </div>
                  ) : activeTab === tabs.ActivityLogs ? (
                    <div className="mt-6 ">
                      <ActivityLogs />
                    </div>
                  ) : activeTab === tabs.Subscriptions ? (
                    <ClientSubscriptions clientId={params.id} />
                  ) : null}
                </div>
              </div>
            </div>{" "}
          </>
        ) : (
          <LoadingCircle />
        )}
      </Layout>
    </>
  );
};

export default Company;
