import React from "react";
import { Layout } from "../../components/Layout/Layout";
import { useState } from "react";
import RentContracts from "./Rent/RentContracts";
import CheckedIn from "./CheckedIn/CheckedIn";
import Calender from "./NewCalendar";
import Vas from "./Vas/VasItems";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Packages from "./Packages";
import Credit from "./Credit/Credit";

const Operations = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const [taps, setTaps] = useState([
    { name: "Bookings", index: 0, path: "/operations" },
    { name: "Day passes", index: 1, path: "/operations/day-passes" },
    { name: "Offices", index: 2, path: "/operations/offices" },
    { name: "Packages", index: 3, path: "/operations/packages" },
    { name: "VAS", index: 4, path: "/operations/vas" },
    { name: "Credit", index: 5, path: "/operations/credit" },
  ]);

  const handleActiveLink = (index) => {
    setActiveTab(index);
    const tabName = taps[index].name.toLowerCase().replace(" ", "-");
    const newPath = `/operations/${tabName}`;
    navigator(newPath);
  };

  useEffect(() => {
    const currentTab = taps.findIndex((tab) => location.pathname === tab.path);
    if (currentTab !== -1) {
      setActiveTab(currentTab);
    }
  }, [location]);

  return (
    <Layout>
      <div className="mt-[0px] mx-[2%]">
        <div className="text-[16px] font-semibold text-[#797979]  ">Experience</div>
        <div className="text-[28px] font-semibold  " style={{ fontFamily: "gilroy-bold" }}>
          {taps[activeTab].name}
        </div>
        <div className="mt-2 flex w-full max-w-[300px]   text-[16px] font-semibold ">
          {taps.map((t, index) => (
            <button
              onClick={() => handleActiveLink(index)}
              className={` ${
                activeTab === t.index
                  ? "text-black border-b-[#32B3BE] border-b-[6px]"
                  : " text-[#8E8E8E]  border-b-[#EFEFEF]  border-b-[6px]"
              } flex   font-semibold  w-full transition-all duration-150 
                  underline-offset-4
                   mb-2
                  `}
              key={index}
            >
              <div className="px-1  text-[15px] w-[120px]">{t.name}</div>
            </button>
          ))}
        </div>
        <div>
          {activeTab === 0 &&
          (window.location.pathname === "/operations" ||
            window.location.pathname === "/operations/bookings") ? (
            <Calender />
          ) : activeTab === 1 && window.location.pathname === "/operations/day-passes" ? (
            <CheckedIn />
          ) : activeTab === 2 && window.location.pathname === "/operations/offices" ? (
            <RentContracts />
          ) : activeTab === 3 && window.location.pathname === "/operations/packages" ? (
            <Packages />
          ) : activeTab === 4 && window.location.pathname === "/operations/vas" ? (
            <Vas />
          ) : activeTab === 5 && window.location.pathname === "/operations/credit" ? (
            <Credit />
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default Operations;
