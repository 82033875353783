import axios from "axios";

export const http = axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    "Content-type": "application/json",
  },
});

// Intercept the request and add the token to the headers
http.interceptors.request.use((config) => {
  // const token = Cookies.get("_auth");
  const token = localStorage.getItem("_auth");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response?.data?.message === "Token invalid or expired" ||
      error.response?.data?.error?.message === "jwt expired" ||
      error.response?.data?.message === "Admin might be deleted"
    ) {
      logoutUser();
    }
    return Promise.reject(error);
  }
);

// Function to log out the user
function logoutUser() {
  localStorage.removeItem("_auth_state");
  localStorage.removeItem("_auth_type");
  localStorage.removeItem("_auth_storage");
  localStorage.removeItem("selectedLocation");
  localStorage.removeItem("_auth");
  localStorage.removeItem("tap");
  localStorage.removeItem("vas-cart");
  window.location.href = "/login";
}
