import React, { useState } from "react";
import { RadioGroup, RadioGroupItem } from "../../../components/ui/RadioButton";
import { Button } from "../../../components/Button/Button";
import ClientNotification from "./notifications/ClientNotification";
import backButton from "../../../assets/backButton.png";

const PromoTarget = ({ setStep, rowSelectionModel, setRowSelectionModel }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [radioValue, setRadioValue] = useState("all");
  return (
    <div className="w-full">
      <button className="flex mb-4 items-center gap-[10px]" onClick={() => setStep(0)}>
        <img className="w-[48px]" src={backButton} alt="Back" />
        Back
      </button>
      <div className=" text-[20px]  my-2" style={{ fontFamily: "gilroy-bold" }}>
        Target
      </div>
      <div className="flex gap-5">
        <RadioGroup className="flex gap-5" onValueChange={setRadioValue} defaultValue={radioValue}>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="all" id="all" />
            <label htmlFor="all">All</label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="client" id="client" />
            <label htmlFor="client">Clients</label>
          </div>
        </RadioGroup>
      </div>

      <div>
        {radioValue === "all" ? (
          <> </>
        ) : radioValue === "client" ? (
          <div>
            <div className=" font-bold mt-2">Send promo code to </div>
            <div className="mt-1 text-[24px] " style={{ fontFamily: "gilroy-bold" }}>
              <ClientNotification
                setStep={setStep}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      {radioValue === "all" ? (
        <div className="mt-10 flex w-full justify-end">
          <Button
            onClick={() => {
              setRowSelectionModel([]);
              setStep(2);
            }}
            loading={isSubmitting}
            secondary={true}
            type="submit"
          >
            <span className="text-[16px]">Confirm Selection</span>
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default PromoTarget;
