import React, { useState, useCallback, useEffect } from "react";
import { http } from "../../utils/httpCommon";
import { toast } from "react-hot-toast";
import { debounce } from "lodash";
import { useAuthUser } from "react-auth-kit";
import whiteTrash from "../../assets/icons/whiteTrash.png";
import whiteEdit from "../../assets/icons/whiteEdit.png";
import Table from "../../components/DataGrid/Table";
import AddTutorialModal from "./addTutorialModel";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import EditTutorialModal from "./editTutoorialModel";

const TutorialsTable = () => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
    search: "",
  });
  const [openAddTutorialModel, setOpenAddTutorialModel] = useState();
  const handleAddTutorialModal = () => {
    setOpenAddTutorialModel(true);
  };

  const [openedDelete, setOpenedDelete] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const handleDelete = async (item) => {
    setDeletedItem(item);
    setOpenedDelete(true);
  };

  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleSubmitDelete = async () => {
    setDeleteLoading(true);
    try {
      await http.delete(`/tutorials/${deletedItem?.id}`);
      setDeleteLoading(false);
      setOpenedDelete(false);
      toast.success("Tutorial has been deleted successfully");
      fetchData();
    } catch (error) { }
  };

  const [openEditModal, setOpenEditModal] = useState(false);
  const [updatedItem, setUpdatedItem] = useState({});
  const handleEditModal = (updatedTutorial) => {
    setUpdatedItem(updatedTutorial);
    setOpenEditModal(true);
  };

  const auth = useAuthUser();
  const actionRoles = ["Super Admin"];

  // Function to fetch data
  const fetchData = useCallback(
    async (searchValue) => {
      try {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }));

        searchValue = searchValue ? searchValue : "";
        const res = await http.get(
          `/tutorials?page=${pageState.page}&limit=${pageState.pageSize}&search=${searchValue}`,
        );

        let filteredData = res.data.data?.filter((item) => !item?.isDeleted);
        const rows = filteredData?.map((d) => ({
          id: d?._id,
          ...d,
        }));

        console.log(res.data);

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: rows, // Fix variable name
          total: res.data.length, // Fix variable name
        }));
      } catch (error) {
        console.error(error);
        toast.error(error.response?.data.message || error.message || "Something went wrong");
      }
    },
    [pageState.page, pageState.pageSize],
  );

  // Debounced version of fetchData
  const debouncedFetchData = useCallback(debounce(fetchData, 1000), [fetchData]);

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    debouncedFetchData(value, 600);
  };

  const onFilterChange = useCallback(async (filterModel) => {
    try {
      let searchVal = filterModel?.items[0]?.value;

      if (searchVal?.length > 0) {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }));

        const res = await http.get(
          `/tutorials?page=${pageState.page}&limit=${pageState.limit}&search=${searchVal}`,
        );
        let filteredData = res.data.data?.data?.filter((item) => !item?.isDeleted);
        const rows = filteredData?.map((d) => ({
          id: d?._id,
          ...d,
        }));

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: rows,
          total: filteredData?.length,
        }));
      } else {
        debouncedFetchData();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    }
  }, []);

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 250,
      renderCell: (params) => (
        <div className="flex gap-[10px] items-center ml-4">
          <span className="font-semibold text-[16px]">{params.row.title}</span>
        </div>
      ),
    },
    // {
    //     field: "description",
    //     headerName: "Description",
    //     width: 450,
    //     renderCell: (params) => (
    //         <div className="flex gap-[10px] items-center ml-4">
    //             <span className="font-semibold text-[16px]">{params.row.description}</span>
    //         </div>
    //     ),
    // },
    {
      field: "url",
      headerName: "Link",
      width: 450,
      renderCell: (params) => (
        <div className="flex gap-[10px] items-center ml-4">
          <span className="font-semibold text-[16px]">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={params.row.url}
              style={{ color: "blue" }}
            >
              See Materials
            </a>
          </span>
        </div>
      ),
    },
    ...(actionRoles.includes(auth()?.role)
      ? [
        {
          field: "role",
          headerName: "Role",
          width: 450,
          renderCell: (params) => (
            <div className="flex gap-[10px] items-center ml-4">
              <span className="font-semibold text-[16px]">{params.row.role}</span>
            </div>
          ),
        },
      ]
      : []),

    ...(actionRoles.includes(auth()?.role)
      ? [
        {
          field: "actions",
          headerName: "Actions",
          filterable: false,
          width: 250,
          renderCell: (params) => (
            <div className="flex flex-col gap-[10px]">
              <button
                onClick={() => handleEditModal(params?.row)}
                className="group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all"
              >
                <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                  <img className="w-[18px]" src={whiteEdit} alt="edit" />
                </div>
                Edit
              </button>
              <button
                onClick={() => handleDelete(params?.row)}
                className="group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all"
              >
                <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                  <img className="w-[18px]" src={whiteTrash} alt="delete" />
                </div>
                Delete
              </button>
            </div>
          ),
        },
      ]
      : []),
  ];

  return (
    <>
      <AddTutorialModal
        opened={openAddTutorialModel}
        setOpened={setOpenAddTutorialModel}
        fetchData={fetchData}
      />

      <EditTutorialModal
        opened={openEditModal}
        setOpened={setOpenEditModal}
        data={updatedItem}
        fetchData={fetchData}
      />

      <DeleteModal
        opened={openedDelete}
        setOpened={setOpenedDelete}
        deletedItem={deletedItem}
        loading={deleteLoading}
        handleSubmitDelete={handleSubmitDelete}
        deleteMessage="permanently remove the tutorial from the system."
      />

      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState.total}
        isLoading={pageState.isLoading}
        data={pageState.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        fetchData={fetchData}
        title={"Tutorials"}
        actionButton={"Add Tutorial"}
        handleActionButton={handleAddTutorialModal}
        onFilterChange={onFilterChange}
        removeActionButton={!actionRoles.includes(auth()?.role)}
      />
    </>
  );
};

export default TutorialsTable;
