import React, { useState, useCallback, useEffect } from "react";
import ModalComponent from "../../../components/Modal/Modal";
import { http } from "../../../utils/httpCommon";
import { TextInput } from "../../../components/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Button } from "../../../components/Button/Button";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "../../../assets/icons/DeleteIcon.png";
import uploadIcon from "../../../assets/uploadIcon.svg";
import { toast } from "react-hot-toast";

const EditAmenity = ({ opened, setOpened, id, fetchData }) => {
  const [name, setName] = useState(id.name);
  const [files, setFiles] = useState([{ name: id?.icon, preview: id?.icon }]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    reset();
    setName(id.name);
    setFiles(id?.icon ? [{ name: id?.icon, preview: id?.icon }] : []);
  }, [id, opened]);

  const [error, setError] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    // Do something with the files
  }, []);
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

  const images = files.map((file, index) => <img className=" h-[120px] w-[150px] object-contain" key={index} src={file.preview} alt={file.name} />);
  const [submitLoading, setSubmitLoading] = useState(false);

  const onSubmit = async (passedData) => {
    setSubmitLoading(true);
    var data = new FormData();
    data.append("image", files[0]);
    try {
      const res = await http.post("/image", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      try {
        var data2 = JSON.stringify({
          name: passedData.name,
          icon: res.data.url === undefined ? "" : res.data.url,
        });
        await http.put(`/amenity/${id._id}`, data2, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setSubmitLoading(false);
        setOpened(false);
        setFiles([]);
        fetchData();
        reset();
        setError("");
        toast.success("New Amenity has been added successfully");
      } catch (error) {
        toast.error("Something went wrong");

        setError(error.response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <ModalComponent opened={opened} setOpened={setOpened} reset={reset}>
      <div className="text-[24px] font-bold">EditAmenity</div>

      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="  flex flex-col  justify-center items-center gap-[40px] mt-4">
          <div className="relative  flex   justify-center items-start mt-4 w-[60%]">
            <div
              className="flex flex-col items-center p-3  max-h-[250px] rounded-[20px] w-full   border-[1px] border-[#4987FF] border-dashed "
              style={{ backgroundColor: "rgba(73, 135, 255, 0.05" }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <>
                  <img className="w-[70px] " src={uploadIcon} alt="upload icon" />
                  <p className=" font-medium text-[16px] ">Drop the icon here ...</p>
                </>
              ) : (
                <>
                  {images.length > 0 ? (
                    <div className="relative">{images}</div>
                  ) : (
                    <>
                      <img className="w-[80px] " src={uploadIcon} alt="upload icon" />
                      <p className=" font-medium  text-[16px]">Upload Amenity Icon</p>
                    </>
                  )}
                </>
              )}
            </div>

            {images.length > 0 ? (
              <button
                onClick={() => {
                  setFiles([]);
                }}
                className="absolute  flex justify-center items-center mx-2 top-3 right-10 bg-[#E4E4E4] w-[40px] h-[40px] rounded-full z-50  hover:bg-gray-100 transition-all"
              >
                <img className=" w-[15px] h-[15px]  " src={DeleteIcon} alt="delete" />
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className="w-[90%]">
            <TextInput label={"Amenity name"} defaultValue={name} {...register("name", { required: "Amenity Name is required" })}></TextInput>
            <ErrorMessage name="name" errors={errors} render={({ message }) => <p className="text-red-500">{message}</p>} />
            <p className="text-red-500">{error}</p>
          </div>
          <div className="flex justify-around  w-full">
            <Button secondary={true} invert={true} onClick={() => setOpened(false)}>
              Cancel
            </Button>
            <Button loading={submitLoading} secondary={true} type="submit">
              Edit
            </Button>
          </div>
        </form>
      </div>
    </ModalComponent>
  );
};

export default EditAmenity;
