import React, { useState } from "react";
import { Button } from "../../../components/Button/Button";
import { http } from "../../../utils/httpCommon";
import { TextInput } from "../../../components/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller } from "react-hook-form";
import ModalComponent from "../../../components/Modal/Modal2";
import downloadIcon from "../../../assets/icons/downloadIcon.png";
import fileIcon from "../../../assets/icons/fileIcon.png";
import { Link } from "react-router-dom";
import phoneIcon from "../../../assets/icons/phoneIcon.png";
import backButton from "../../../assets/backButton.png";
import profileImg from "../../../assets/profile-picture.jpeg";
import locationIcon from "../../../assets/icons/locationIcon.png";
import { toast } from "react-hot-toast";

const RequestModal = ({ opened, setOpened, row, fetchData }) => {


  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (image) => {
    setModalIsOpen(true);
    setSelectedImage(image);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage("");
  };

  const handleOnClose = () => {
    setOpened(false);
    setOpenNotification(false);
    setOpenResolveFund(false);
  };

  const [openNotification, setOpenNotification] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  //company request
  const handleApprove = async () => {
    setButtonLoading(true);
    try {
      await http.put(`/request/company/${row._id}/approve`);

      setButtonLoading(false);
      toast.success("Request Approved");
      setOpened(false);
      fetchData();
    } catch (error) {
      setButtonLoading(false);
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    }
    // setOpenNotification(true);
  };

  const handleDecline = async () => {
    setButtonLoading(true);
    try {
      await http.put(`/request/company/${row._id}/disapprove`);

      setButtonLoading(false);
      toast.success("Request Declined");
      setOpened(false);
      fetchData();
    } catch (error) {
      setButtonLoading(false);
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    }
  };

  //issue requests
  const handleIssueApprove = async () => {
    setButtonLoading(true);
    try {
      await http.put(`/issue/${row._id}/resolve`);
      setButtonLoading(false);
      toast.success("Request Declined");
      setOpened(false);
      fetchData();
    } catch (error) {
      setButtonLoading(false);
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    }
  };

  const [openResolveFund, setOpenResolveFund] = useState(false);
  const handleResolveFund = () => {
    setOpenResolveFund(true);
  };

  const schema = yup
    .object({
      amount: yup.string().required(),
      action: yup.string().required(),
    })
    .required();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  let actions = ["Partial Refund", "Full Refund", "No Refund"];

  const [selected, setSelected] = useState(null);

  const onSubmitResolveFund = (passedData) => {};

  return (
    <>
      <ModalComponent opened={opened} setOpened={setOpened} onClose={handleOnClose} size="50%">
        {!openNotification ? (
          <>
            <ModalComponent opened={modalIsOpen} setOpened={setModalIsOpen} onClose={closeModal} size="40%">
              <div className="flex justify-center items-center flex-col w-full">
                <img src={selectedImage} alt={selectedImage} />

                <div className="flex  gap-[30px] py-6">
                  <Button secondary={true} invert={true} onClick={closeModal}>
                    Close
                  </Button>
                  <Button onClick={() => window.open(selectedImage, "_blank")}>Download</Button>
                </div>
              </div>
            </ModalComponent>

            {row?.type === "company" ? (
              <div className="flex flex-col px-6 pb-8 ">
                <div style={{ fontFamily: "gilroy-bold" }} className="text-[25px] ">
                  Company Request
                </div>
                <div className="flex gap-[30px] mt-2">
                  {row?.company?.logo && <img className="w-[145px] rounded-[20px] object-cover" src={row?.company?.logo} alt={row?.company?.name} />}
                  <div className="flex flex-col gap-1">
                    <span className="text-[24px]" style={{ fontFamily: "gilroy-bold" }}>
                      {row?.company?.name}
                    </span>
                    <span className="text-[#797979]">
                      status
                      <br />
                      <span>
                        <div className="flex gap-[10px] items-center text-[16px] w-[130px]">
                          <div
                            className={` ${
                              row?.approved ? " text-[#797979] bg-[#a09f9f]" : "text-[#34B2C0]  bg-[#3bb2c0]"
                            }  bg-opacity-20 capitalize font-semibold p-2 flex  mt-2 justify-center items-center rounded-[25px]   `}
                          >
                            {row?.approved ? "Resolved" : "Open"}
                          </div>
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="flex flex-col">
                  <span className="text-[18px] " style={{ fontFamily: "gilroy-bold" }}>
                    Admin
                  </span>
                  <div className=" mt-2 flex gap-[30px] min-h-[110px] border-[1px] border-[#EFEFEF] rounded-[20px] w-fit py-2 px-4">
                    <div className="flex flex-col gap-[10px]">
                      <span className="text-[18px]" style={{ fontFamily: "gilroy-bold" }}>
                        {row?.company?.leader?.name ? row?.company?.leader?.name : row?.company?.name}
                      </span>
                      <span className="flex items-center gap-[10px] text-[16px]">
                        {" "}
                        <img className="w-[16px]" src={phoneIcon} alt="phone" /> {row?.company?.leader?.phone ? row?.company?.leader?.phone : row?.company?.phone}
                      </span>
                    </div>
                    <Link to={`/community/client/${row?.company?.leader}`} className="font-semibold hover:text-[#3bb2c0] text-[16px]">
                      View profile
                    </Link>
                  </div>
                </div>

                <hr className="my-4" />
                <div className="flex flex-col">
                  <span className="text-[18px] " style={{ fontFamily: "gilroy-bold" }}>
                    Registration Documents
                  </span>

                  <div className="flex gap-[20px] w-full flex-wrap">
                    {row?.documents?.map((d, index) => (
                      <div key={d} className="mt-2 flex items-center gap-4  border-[1px] border-[#EFEFEF] rounded-[20px] w-fit p-4">
                        <div className="bg-[#3bb2c0]  bg-opacity-20 w-[72px] h-[72px] flex items-center justify-center rounded-[20px] ">
                          <img className="w-[23px] " src={fileIcon} alt="file" />
                        </div>

                        <div>
                          <span style={{ fontFamily: "gilroy-bold" }}>Document {index + 1}</span>
                          <div className="mt-3 flex">
                            <button onClick={() => openModal(d)} className=" mx-2 text-[#8E8E8E] ">
                              View
                            </button>
                            <button onClick={() => window.open(d, "_blank")} className="flex  items-center font-semibold gap-2 text-[#32B3BE]">
                              Download
                              <img className="w-[14px] " src={downloadIcon} alt="download" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="w-full flex  mt-4 gap-[30px] justify-end ">
                  <Button buttonLoading={buttonLoading} onClick={handleDecline} secondary={true} invert>
                    Decline
                  </Button>
                  <Button buttonLoading={buttonLoading} onClick={handleApprove} secondary={true}>
                    Approve
                  </Button>
                </div>
              </div>
            ) : row?.type === "invoice" ? (
              <div className="flex flex-col px-10 pb-10 ">
                <div style={{ fontFamily: "gilroy-bold" }} className="text-[25px] ">
                  Invoice Request
                </div>
                <div className="flex gap-[40px] ">
                  <div className="flex flex-col mt-4    ">
                    <span className="text-[18px] " style={{ fontFamily: "gilroy-bold" }}>
                      Company
                    </span>
                    <div className=" flex mt-4 gap-[30px] min-h-[130px] border-[1px] border-[#EFEFEF] rounded-[20px] w-fit py-4 px-6">
                      <div className="flex flex-col gap-[10px]">
                        <span className="text-[20px]" style={{ fontFamily: "gilroy-bold" }}>
                          Ahmed Nasser
                        </span>
                        <span className="flex items-center gap-[10px]">
                          {" "}
                          <img className="w-[16px]" src={phoneIcon} alt="phone" /> 02103232109
                        </span>
                      </div>
                      <Link className="font-semibold">View profile</Link>
                    </div>
                  </div>

                  <div className="flex flex-col mt-4">
                    <span className="text-[18px] " style={{ fontFamily: "gilroy-bold" }}>
                      Provided for
                    </span>
                    <div className=" flex mt-4 gap-[30px] min-h-[110px] border-[1px] border-[#EFEFEF] rounded-[20px] w-fit py-4 px-6">
                      <div className="flex flex-col gap-[10px]">
                        <span className="text-[20px]" style={{ fontFamily: "gilroy-bold" }}>
                          Service type
                        </span>
                        <span>Payment</span>
                        <span className="flex items-center gap-[10px]">
                          {" "}
                          <img className="w-[16px]" src={phoneIcon} alt="phone" /> 02103232109
                        </span>
                      </div>
                      <Link className="font-semibold">View service</Link>
                    </div>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="flex flex-col">
                  <div className="flex gap-[30px] mt-4">
                    <div className="flex flex-col gap-1">
                      <span className="text-[25px]" style={{ fontFamily: "gilroy-bold" }}>
                        Exemption Requested
                      </span>
                      <div
                        className={` ${
                          // ? " text-[#797979] bg-[#a09f9f]"
                          "text-[#34B2C0]  bg-[#3bb2c0]"
                        }   mt-3 bg-opacity-20  w-fit px-10 capitalize font-semibold py-3 flex  justify-center items-center rounded-[25px]   `}
                      >
                        open{" "}
                      </div>
                      <span className="text-[#797979]">Exempted from</span>
                      <span className="text-[25px]" style={{ fontFamily: "gilroy-bold" }}>
                        VAT
                      </span>
                    </div>
                  </div>
                </div>

                <hr className="my-4" />
                <div className="flex flex-col">
                  <span className="text-[18px] " style={{ fontFamily: "gilroy-bold" }}>
                    Documents
                  </span>

                  <div className="flex gap-[20px] w-full flex-wrap">
                    <div className="mt-2 flex items-center gap-4  border-[1px] border-[#EFEFEF] rounded-[20px] w-fit p-4">
                      <div className="bg-[#3bb2c0]  bg-opacity-20 w-[72px] h-[72px] flex items-center justify-center rounded-[20px] ">
                        <img className="w-[23px] " src={fileIcon} alt="file" />
                      </div>

                      <div>
                        <span style={{ fontFamily: "gilroy-bold" }}>Document Name</span>
                        <div className="mt-3 flex">
                          <button className=" mx-2 text-[#8E8E8E] ">View</button>
                          <button className="flex  items-center font-semibold gap-2 text-[#32B3BE]">
                            Download
                            <img className="w-[14px] " src={downloadIcon} alt="download" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2 flex items-center gap-4  border-[1px] border-[#EFEFEF] rounded-[20px] w-fit p-4">
                      <div className="bg-[#3bb2c0]  bg-opacity-20 w-[72px] h-[72px] flex items-center justify-center rounded-[20px] ">
                        <img className="w-[23px] " src={fileIcon} alt="file" />
                      </div>

                      <div>
                        <span style={{ fontFamily: "gilroy-bold" }}>Document Name</span>
                        <div className="mt-3 flex">
                          <button className=" mx-2 text-[#8E8E8E] ">View</button>
                          <button className="flex  items-center font-semibold gap-2 text-[#32B3BE]">
                            Download
                            <img className="w-[14px] " src={downloadIcon} alt="download" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full flex  mt-4 gap-[30px] justify-end ">
                  <Button onClick={() => setOpened(false)} secondary={true} invert>
                    Decline
                  </Button>
                  <Button onClick={handleApprove} secondary={true} type="submit">
                    Approve
                  </Button>
                </div>
              </div>
            ) : row?.type === "refund" ? (
              <>
                {!openResolveFund ? (
                  <div className="flex flex-col px-10 pb-10 ">
                    <div style={{ fontFamily: "gilroy-bold" }} className="text-[25px] ">
                      Refund Request
                    </div>

                    <div
                      style={{
                        fontFamily: "gilroy-bold",
                        fontSize: "20px",
                        margin: "10px 0 0 0  ",
                      }}
                    >
                      Client Information
                    </div>
                    <div className="mt-2 flex items-start gap-6  border-[1px] border-[#EFEFEF] rounded-[20px] w-[80%] p-4">
                      <div className="  bg-opacity-20 w-[72px] h-[82px] flex items-center justify-center rounded-[20px] ">
                        <img className="w-full rounded-[10px]" src={profileImg} alt="file" />
                      </div>
                      <div className="flex flex-col justify-around gap-1">
                        <div
                          style={{
                            fontFamily: "gilroy-bold",
                            fontSize: "20px",
                          }}
                        >
                          Ahmed Nasser
                        </div>
                        <div className="flex items-center gap-[10px]">
                          {" "}
                          <img className="w-[16px]" src={phoneIcon} alt="phone" /> 02103232109
                        </div>
                        <div className="text-[#797979] text-[20px] ">Member at “Company Name”</div>
                      </div>
                    </div>

                    <div className="flex flex-col mt-4   ">
                      <span className="text-[18px] " style={{ fontFamily: "gilroy-bold" }}>
                        MQR Admin info
                      </span>
                      <div className=" flex justify-between mt-4 gap-[30px] min-h-[130px] border-[1px] border-[#EFEFEF] rounded-[20px] w-[80%] p-6">
                        <div className="flex flex-col gap-[10px]">
                          <span className="text-[20px]" style={{ fontFamily: "gilroy-bold" }}>
                            Ahmed Nasser
                          </span>
                          <span className="flex items-center gap-[10px]">
                            {" "}
                            <img className="w-[16px]" src={phoneIcon} alt="phone" /> 02103232109
                          </span>
                          <span className="flex items-center gap-[10px]">
                            {" "}
                            <img className="w-[16px]" src={locationIcon} alt="locationIcon" /> Branch location
                          </span>
                        </div>
                        <Link className="font-semibold">View profile</Link>
                      </div>
                    </div>

                    <div className="flex flex-col mt-4   ">
                      <span className="text-[18px] " style={{ fontFamily: "gilroy-bold" }}>
                        The service
                      </span>
                      <div className=" flex justify-between mt-4 gap-[30px] min-h-[130px] border-[1px] border-[#EFEFEF] rounded-[20px] w-[80%] p-6">
                        <div className="flex flex-col gap-[5px]">
                          <span className="text-[20px]" style={{ fontFamily: "gilroy-bold" }}>
                            Service
                          </span>
                          <span className="flex items-center gap-[10px]">Amount</span>
                          <span className="text-[20px]" style={{ fontFamily: "gilroy-bold" }}>
                            200 EGP
                          </span>
                        </div>
                        <Link className="font-semibold">View profile</Link>
                      </div>
                    </div>

                    <hr className="my-4" />
                    <div className="flex flex-col mt-4   ">
                      <span className="text-[20px] " style={{ fontFamily: "gilroy-bold" }}>
                        Refund reason
                      </span>
                      <div className="  text-[#797979] text-[20px] w-[90%] ">
                        In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful
                        content.
                      </div>
                    </div>

                    <div className="w-full flex  mt-4 gap-[30px] justify-end ">
                      {/* <Button
                  onClick={() => setOpened(false)}
                  secondary={true}
                  invert
                >
                  Decline
                </Button> */}
                      <Button onClick={handleResolveFund} secondary={true}>
                        Resolve
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col px-10 pb-10 ">
                    <div style={{ fontFamily: "gilroy-bold" }} className="text-[25px] ">
                      Resolve Refund Request
                    </div>

                    <div
                      style={{
                        fontFamily: "gilroy-bold",
                        fontSize: "20px",
                        margin: "10px 0 0 0  ",
                      }}
                    >
                      Client Information
                    </div>
                    <div className="mt-2 flex items-start gap-6  border-[1px] border-[#EFEFEF] rounded-[20px] w-[80%] p-4">
                      <div className="  bg-opacity-20 w-[72px] h-[82px] flex items-center justify-center rounded-[20px] ">
                        <img className="w-full rounded-[10px]" src={profileImg} alt="file" />
                      </div>
                      <div className="flex flex-col justify-around gap-1">
                        <div
                          style={{
                            fontFamily: "gilroy-bold",
                            fontSize: "20px",
                          }}
                        >
                          Ahmed Nasser
                        </div>
                        <div className="flex items-center gap-[10px]">
                          {" "}
                          <img className="w-[16px]" src={phoneIcon} alt="phone" /> 02103232109
                        </div>
                        <div className="text-[#797979] text-[20px] ">Member at “Company Name”</div>
                      </div>
                    </div>

                    <div className="flex flex-col mt-4   ">
                      <span className="text-[18px] " style={{ fontFamily: "gilroy-bold" }}>
                        The service
                      </span>
                      <div className=" flex justify-between mt-4 gap-[30px] min-h-[130px] border-[1px] border-[#EFEFEF] rounded-[20px] w-[80%] p-6">
                        <div className="flex flex-col gap-[5px]">
                          <span className="text-[20px]" style={{ fontFamily: "gilroy-bold" }}>
                            Service
                          </span>
                          <span className="flex items-center gap-[10px]">Amount</span>
                          <span className="text-[20px]" style={{ fontFamily: "gilroy-bold" }}>
                            200 EGP
                          </span>
                        </div>
                        <Link className="font-semibold">View service</Link>
                      </div>
                    </div>

                    <form className="w-full " onSubmit={handleSubmit(onSubmitResolveFund)}>
                      <div className="mt-6 ">
                        <TextInput label={"Input Amount"} {...register("amount")} />
                        <p className="text-red-500">{errors.amount?.message}</p>
                      </div>
                      <div className="mt-4 text-[20px]" style={{ fontFamily: "gilroy-bold" }}>
                        Resolve Action
                      </div>
                      <div className="mt-4 flex space-x-5">
                        <Controller
                          control={control}
                          name="action"
                          render={({ field: { onChange, ...props } }) => (
                            <>
                              {actions.map((item, index) => (
                                <div className="relative" key={item}>
                                  <input
                                    {...props}
                                    type="radio"
                                    id={item}
                                    name="check-substitution-2"
                                    className=" hidden absolute top-0 left-0 w-full h-full opacity-0 z-100"
                                    htmlFor={item}
                                    value={item}
                                    checked={selected === item}
                                    onChange={(e) => {
                                      onChange(e.target.value);
                                      setSelected(item);
                                    }}
                                  />
                                  <label
                                    htmlFor={item}
                                    className={` px-10 cursor-pointer hover:bg-[#32B3BE] hover:text-[white] transition-all ${selected === item ? "bg-[#32B3BE]" : ""}
                                ${selected === item ? "text-[#fffff2]" : ""} font-semibold  text-[#32B3BE] border-[1px] 
                                flex justify-center items-center p-3 border-[#32B3BE] rounded-full min-w-[80px]  `}
                                  >
                                    {item}
                                  </label>
                                </div>
                              ))}
                            </>
                          )}
                        />
                      </div>

                      <p className="text-red-500">{errors.action?.message}</p>

                      <div className="w-full flex  mt-4 gap-[30px] justify-end ">
                        <Button onClick={() => setOpened(false)} secondary={true} invert>
                          Cancel
                        </Button>
                        <Button onClick={handleResolveFund} secondary={true} type="submit">
                          Resolve
                        </Button>
                      </div>
                    </form>
                  </div>
                )}
              </>
            ) : (
              // Issue
              <div className="flex flex-col px-10 pb-10 ">
                <div style={{ fontFamily: "gilroy-bold" }} className="text-[25px] flex items-center gap-[100px] ">
                  Issue Request
                  {/* <div
                    className={` ${
                      // ? " text-[#797979] bg-[#a09f9f]"
                      "text-[#34B2C0]  bg-[#3bb2c0]"
                    }   mt-3 bg-opacity-20  w-fit px-10  text-base capitalize font-semibold py-3 flex  justify-center items-center rounded-[25px]   `}
                  >
                    type{" "}
                  </div> */}
                </div>

                <div className="mt-6 flex flex-col">
                  <div style={{ fontFamily: "gilroy-bold" }} className="text-[20px] flex items-center gap-[100px] ">
                    Request Title
                  </div>
                  <div className="mt-2 text-[#797979] text-[20px]">{row.title}</div>
                </div>
                <hr className="my-4" />
                <div className="flex gap-[40px] ">
                  <div className="flex flex-col mt-4    ">
                    {/* <span
                      className="text-[18px] "
                      style={{ fontFamily: "gilroy-bold" }}
                    >
                      Sender
                    </span> */}
                    {/* <div className=" flex mt-4 gap-[30px] min-h-[130px] border-[1px] border-[#EFEFEF] rounded-[20px] w-fit py-4 px-6">
                      <div className="flex flex-col gap-[10px]">
                        <span
                          className="text-[20px]"
                          style={{ fontFamily: "gilroy-bold" }}
                        >
                          Ahmed Nasser
                        </span>
                        <span className="flex items-center gap-[10px]">
                          {" "}
                          <img
                            className="w-[16px]"
                            src={phoneIcon}
                            alt="phone"
                          />{" "}
                          02103232109
                        </span>
                      </div>
                      <Link className="font-semibold">View profile</Link>
                    </div> */}
                  </div>
                </div>

                <div className="w-full flex  mt-4 gap-[30px] justify-end ">
                  {/* <Button
                    onClick={() => setOpened(false)}
                    secondary={true}
                    invert
                  >
                    Decline
                  </Button> */}
                  <Button loading={buttonLoading} onClick={handleIssueApprove} secondary={true} type="submit">
                    Approve
                  </Button>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex flex-col px-10 pb-10 ">
            <button className="flex mb-4 items-center gap-[10px]" onClick={() => setOpenNotification(false)}>
              <img className="w-[48px]" src={backButton} alt="Back" />
              Back
            </button>
            <div style={{ fontFamily: "gilroy-bold" }} className="text-[25px] ">
              Send Notification to Client
            </div>

            <div className="mt-2 flex items-start gap-6  border-[1px] border-[#EFEFEF] rounded-[20px] w-[70%] p-4">
              <div className="  bg-opacity-20 w-[72px] h-[82px] flex items-center justify-center rounded-[20px] ">
                <img className="w-full rounded-[20px]" src={profileImg} alt="file" />
              </div>
              <div className="flex flex-col justify-around gap-2">
                <div
                  style={{
                    fontFamily: "gilroy-bold",
                    fontSize: "20px",
                  }}
                >
                  Ahmed Nasser
                </div>
                <div className="text-[#797979] text-[20px] ">Member at “Company Name”</div>
              </div>
            </div>

            <div
              style={{
                fontFamily: "gilroy-bold",
                fontSize: "20px",
                marginTop: "20px",
              }}
            >
              Send Message
            </div>

            <div className="mt-4 w-[70%] ">
              <TextInput
                label={"Description"}
                multiline={true}
                rows={4}
                // {...register("message")}
              />
              {/* <p className="text-red-500">{errors.message?.message}</p> */}
            </div>
            <div className="w-full flex  mt-4 gap-[30px] justify-end ">
              <Button onClick={() => setOpened(false)} secondary={true} invert>
                cancel
              </Button>
              <Button onClick={() => handleApprove} secondary={true}>
                Send now
              </Button>
            </div>
          </div>
        )}
      </ModalComponent>
    </>
  );
};

export default RequestModal;
