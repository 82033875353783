import React, { useState } from "react";
import { Button } from "../../../components/Button/Button";
import { http } from "../../../utils/httpCommon";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ModalComponent from "../../../components/Modal/Modal2";
import { toast } from "react-hot-toast";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";


const EditNoteModal = ({ opened, setOpened, data, onNotesUpdate }) => {
    const [notes, setNotes] = useState(data.notes);

    const schema = yup
        .object({
            notes: yup.string(),
        })
        .required();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const navigator = useNavigate();

    const [submitLoading, setSubmitLoading] = useState(false);
    const onSubmit = async (passedData) => {
        setSubmitLoading(true);
        try {
            await http.put(
                `/checks/${data._id}`,
                JSON.stringify({
                    notes: passedData.notes,
                })
            );
            setSubmitLoading(false);
            handleOnClose();
            navigator("/operations/day-passes");
            onNotesUpdate(passedData.notes);
            toast.success("Notes updated successfully");
        } catch (error) {
            setSubmitLoading(false);
            console.log(error)
            toast.error(error.response?.data.message || error.message || "Something went wrong");
        }
    };

    const handleOnClose = () => {
        setOpened(false);
        reset();
    };

    return (
        <ModalComponent
            opened={opened}
            setOpened={setOpened}
            onClose={handleOnClose}
        >
            <div className="flex flex-col">
                <div className="text-[24px] font-bold">Edit Notes</div>

                <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-4 ">
                        <TextField
                            style={{ width: "100%" }}
                            variant="outlined"
                            rows={4}
                            multiline
                            label="Notes"
                            defaultValue={data.notes}
                            {...register("notes")}
                        />
                        <p className="text-red-500">{errors.notes?.message}</p>
                    </div>


                    <div className="mt-6 mb-8 flex justify-end gap-10">
                        <Button
                            onClick={() => setOpened(false)}
                            secondary={true}
                            invert={true}
                        >
                            Cancel
                        </Button>
                        <Button loading={submitLoading} type="submit" secondary={true}>
                            Confirm
                        </Button>
                    </div>
                </form>
            </div>
        </ModalComponent>
    );
};

export default EditNoteModal;
