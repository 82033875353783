import React from "react";
import whiteEdit from "../../../assets/icons/whiteEdit.png";
import sendMessage from "../../../assets/icons/Send.svg";
import { Button } from "../../../components/Button/Button";
import backButton from "../../../assets/backButton.png";
import moment from "moment";
import ProfileImg from "../../../assets/profile.png";
import { useState } from "react";
import { http } from "../../../utils/httpCommon";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";

const NewPromoCodeConfirmation = ({ setStep, rowSelectionModel, formData }) => {
  const navigator = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const savePromoCode = async () => {
    setIsSubmitting(true);
    var data = JSON.stringify({
      name: formData?.name,
      code: formData?.code,
      usage: formData?.usage,
      type: formData?.type,
      value: parseInt(formData?.value),
      startDate: moment(formData?.startDate).format("YYYY-MM-DD"),
      endDate: moment(formData?.endDate).format("YYYY-MM-DD"),
    });
    try {
      await http.post("/promo-codes", data);
      sendPromoNotification();
      setIsSubmitting(false);
      toast.success("Promo code created successfully");
      navigator("/finance/promo-codes");
    } catch (error) {
      setIsSubmitting(false);
      toast.error(error.response.data.message);
    }
  };

  const sendPromoNotification = async () => {
    setIsSubmitting(true);

    if (rowSelectionModel?.length > 0) {
      let data = JSON.stringify({
        message: {
          title: "Promo Code",
          body: formData?.code + " - " + formData?.name,
        },
        receivers: rowSelectionModel?.map((r) => r?.notificationId)?.filter((r) => r),
      });
      try {
        await http.post(`notification/send/bulk`, data);
        toast.success("Promo code have been sent to clients successfully");
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      let data = JSON.stringify({
        message: {
          title: "Promo Code",
          body: formData?.code + " - " + formData?.name,
        },
        receivers: "all",
      });
      try {
        await http.post(`notification/send/bulk`, data);
        toast.success("Promo code have been sent to clients successfully");
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div className="w-full flex flex-col gap-3">
      <button className="flex mb-4 items-center gap-[10px]" onClick={() => setStep(1)}>
        <img className="w-[48px]" src={backButton} alt="Back" />
        Back
      </button>
      <div className=" text-[26px] flex gap-4  my-2" style={{ fontFamily: "gilroy-bold" }}>
        Confirm Promo Code
      </div>
      <div className="bg-white w-fit min-w-[60%]  px-6 py-4 ">
        <div className="flex gap-6">
          <div className="text-[18px]" style={{ fontFamily: "gilroy-bold" }}>
            Basic info{" "}
          </div>
          <button
            onClick={() => {
              setStep(0);
            }}
            className="group text-[16px] text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
          >
            <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
              <img className=" w-[18px] " src={whiteEdit} alt="edit" />
            </div>
            Edit
          </button>
        </div>
        <div className="flex flex-col gap-1 text-[18px] ">
          <div className="flex flex-col mt-2 ">
            <span className="text-[#797979]">Name</span>
            <span style={{ fontFamily: "gilroy-bold" }}>{formData?.name}</span>
          </div>
          <div className="flex flex-col mt-2 ">
            <span className="text-[#797979]">Code</span>
            <span style={{ fontFamily: "gilroy-bold" }}>{formData?.code}</span>
          </div>
          <div className="flex flex-col mt-2 ">
            <span className="text-[#797979]">Code Type</span>
            <span style={{ fontFamily: "gilroy-bold" }}>{formData?.type}</span>
          </div>
          <div className="flex flex-col mt-2 ">
            <span className="text-[#797979] font-extrabold">Value</span>
            <span style={{ fontFamily: "gilroy-bold" }}>{formData?.value}</span>
          </div>
          <div className=" mt-2 flex gap-16 ">
            <div className="flex flex-col">
              <span className="text-[#797979]">Starting Date</span>
              <span style={{ fontFamily: "gilroy-bold" }}>
                {moment(formData?.startDate).format("LL")}
                {/* 4th, Sept 2021 */}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-[#797979]">Ending Date</span>
              <span style={{ fontFamily: "gilroy-bold" }}>
                {moment(formData?.endDate).format("LL")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white w-fit   min-w-[60%]  px-6 py-4 ">
        <div className="flex gap-6">
          <div className="text-[18px]" style={{ fontFamily: "gilroy-bold" }}>
            Recipients
          </div>
          <button
            onClick={() => {
              setStep(1);
            }}
            className="group text-[16px] text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
          >
            <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
              <img className=" w-[18px] " src={whiteEdit} alt="edit" />
            </div>
            Edit
          </button>
        </div>
        <div className="flex flex-col w-fit   justify-center ">
          {rowSelectionModel?.length > 5 ? null : (
            <div
              style={{ fontFamily: "gilroy-bold" }}
              className="text-[#797979] flex items-center  gap-2 mt-2"
            >
              <div className="flex relative min-w-[220px] ">
                {rowSelectionModel?.map((r) => (
                  <img
                    key={r?._id}
                    alt={r?._id}
                    src={r?.profilePicture ? r?.profilePicture : ProfileImg}
                    className={`w-[60px] h-[60px]  object-cover relative top-0 left-0   rounded-full`}
                  />
                ))}
              </div>
              {rowSelectionModel?.length ? rowSelectionModel?.length : "All"} Clients
            </div>
          )}
          <div className="mt-4">
            <button
              onClick={sendPromoNotification}
              className="group text-[16px] text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
            >
              <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                <img className=" w-[18px] " src={sendMessage} alt="edit" />
              </div>
              Send Promo Code
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-end w-fit gap-2   min-w-[60%]   py-6 ">
        <Button
          className=" bg-transparent font-extrabold  px-4 "
          onClick={() => navigator("/finance/promo-codes")}
          secondary={true}
          invert={true}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button onClick={savePromoCode} disabled={isSubmitting} secondary={true} type="submit">
          {isSubmitting ? <LoadingCircle /> : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default NewPromoCodeConfirmation;
