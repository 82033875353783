import React, { useState } from "react";
import CompanyRequestsTable from "./CompnayRequestsTable";
import IssuesRequestsTable from "./IssuesRequestsTable";
import { useAuthUser } from "react-auth-kit";

const Requests = () => {
  // const { response, error, loading, fetchData } = useFetchData("/client");
  const auth = useAuthUser();
  const [viewState, setViewState] = useState(auth().role !== "Area Manager" ? "Company" : "Issues");

  return (
    <>
      <div className="flex justify-between mt-2  relative">
        <div className="flex ">
          {auth().role === "Experience Officer" || auth().role === "Area Manager" ? null : (
            <>
              <button
                className={`${
                  viewState === "Company" ? "bg-[#EFEFEF]" : "bg-[#F8F9FA]"
                }  transition-all duration-100 text-black rounded-tl-[10px] rounded-bl-[10px] border-[1px] border-[#E4E4E4] font-bold px-[20px] py-[5px] text-[18px] `}
                onClick={() => setViewState("Company")}
              >
                Company
              </button>
              <button
                className={`${
                  viewState === "Issues" ? "bg-[#EFEFEF]" : "bg-[#F8F9FA]"
                } transition-all duration-100 text-black rounded-tr-[10px] rounded-br-[10px] border-t-[1px]   border-b-[1px]  border-r-[1px] border-[#E4E4E4] font-bold  px-[20px] py-[5px] text-[18px]`}
                onClick={() => setViewState("Issues")}
              >
                Issues
              </button>
            </>
          )}
        </div>
      </div>

      {viewState === "Company" ? <CompanyRequestsTable /> : <IssuesRequestsTable />}
    </>
  );
};

export default Requests;
