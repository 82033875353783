import { createContext, useContext, useEffect, useState } from "react";
// import ShoppingCart from "../components/ShoppingCart";
// import ShoppingCart from "../components/ShoppingCart";

const VasCartContext = createContext({});

const initialCartItems = localStorage.getItem("vas-cart")
    ? JSON.parse(localStorage.getItem("vas-cart"))
    : [];

    const initialDiscountType = localStorage.getItem("vas-dicount-type")
    ? localStorage.getItem("vas-dicount-type")
    : '';

    const initialDiscountValue = localStorage.getItem("vas-dicount-value")
    ? localStorage.getItem("vas-dicount-value")
    : '';

const VasCartProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [cartItems, setCartItems] = useState(initialCartItems);
    const [discountType, setDiscountType] = useState(initialDiscountType);
    const [discountValue, setDiscountValue] = useState(initialDiscountValue);

    useEffect(() => {
        localStorage.setItem("vas-cart", JSON.stringify(cartItems));
    }, [cartItems]);

    const openCart = () => {
        setIsOpen(true);
    };

    const closeCart = () => {
        setIsOpen(false);
    };

      useEffect(() => {
        localStorage.setItem("vas-dicount-value", discountValue)
        calculateTotalPrice();
      }, [discountValue])

      useEffect(() => {
        localStorage.setItem("vas-dicount-type", discountType)
        calculateTotalPrice();
      }, [discountType])

      const calculateTotalPrice = () => {
        let subtotal = 0;
        let discountAmount = 0;
        let vat = 0;
        let total = 0;
    
        for (let i = 0; i < cartItems.length; i++) {
          subtotal += cartItems[i]?.price * cartItems[i]?.quantity;
        }
    
        if(discountValue > 0){
          if(discountType === "percent"){
            discountAmount = Math.ceil((subtotal * discountValue / 100).toFixed(2));
          }else{
            discountAmount = Math.ceil(+Number(discountValue).toFixed(2));
          }
        } 
    
        const totalAfterDiscountAmount = subtotal - discountAmount;
        vat = Math.ceil((totalAfterDiscountAmount * 0.14).toFixed(2));
    
        total = totalAfterDiscountAmount + vat;
    
        return {
          subtotal,
          discountAmount,
          vat,
          total
        }
      };


    const cartQuantity = cartItems.reduce(
        (quantity, item) => item.quantity + quantity,
        0
    );
    const getItemQuantity = (data) => {
        return cartItems.find((item) => item._id === data?._id)?.quantity || 0;
    };
    const increaseCartQuantity = (data) => {
        setCartItems((currItems) => {
            if (currItems.find((item) => item._id === data?._id) == null) {
                return [...currItems, { ...data, quantity: 1 }];
            } else {
                return currItems.map((item) => {
                    if (item._id === data?._id) {
                        return { ...item, quantity: item.quantity + 1 };
                    } else {
                        return item;
                    }
                });
            }
        });
    };
    const decreaseCartQuantity = (data) => {
        setCartItems((currItems) => {
            if (currItems.find((item) => item._id === data?._id)?.quantity === 1) {
                return currItems.filter((item) => item._id !== data?._id);
            } else {
                return currItems.map((item) => {
                    if (item._id === data?._id) {
                        return { ...item, quantity: item.quantity - 1 };
                    } else {
                        return item;
                    }
                });
            }
        });
    };
    const removeFromCart = (id) => {
        setCartItems((currItems) => currItems.filter((item) => item._id !== id));
    };
    const removeAllFromCart = () => {
        setCartItems([])
        localStorage.removeItem("vas-cart")
    }

    const changeDiscountType = (type) => {
        setDiscountType(type)
    } 

    const changeDiscountValue = (value) => {
        setDiscountValue(value)
    }

    return (
        <VasCartContext.Provider
            value={{
                cartItems,
                getItemQuantity,
                increaseCartQuantity,
                decreaseCartQuantity,
                removeFromCart,
                openCart,
                closeCart,
                removeAllFromCart,
                cartQuantity,
                discountType,
                discountValue,
                changeDiscountType,
                changeDiscountValue
            }}
        >
            {children}
            {/* <ShoppingCart isOpen={isOpen} closeCart={closeCart} /> */}
        </VasCartContext.Provider>
    );
};

export default VasCartProvider;
export const useVasCart = () => {
    return useContext(VasCartContext);
};