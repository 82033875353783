import React from "react";
import whiteTrash from "../../../assets/icons/whiteTrash.png";
import { Button } from "../../../components/Button/Button";
import DeleteImage from "../../../assets/icons/deleteIcon1.png";
import ModalComponent from "../../../components/Modal/Modal2";
import { http } from "../../../utils/httpCommon";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const DeletePackage = ({ name, id, refetch }) => {
  const [opened, setOpened] = React.useState(false);

  const navigator = useNavigate();
  const onSubmit = async () => {
    try {
      await http.delete(`/packages/${id}`);
      setOpened(false);
      toast.success("Package has been deleted successfully");
      refetch ? refetch() : navigator("/operations/packages");
    } catch (error) {
      toast.error(
        error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong",
      );
    }
  };

  const handleOnClose = () => {
    setOpened(false);
  };

  return (
    <>
      <button
        onClick={() => {
          setOpened(true);
        }}
        className="group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
      >
        <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
          <img className=" w-[18px] " src={whiteTrash} alt="edit" />
        </div>
        Delete
      </button>
      <ModalComponent opened={opened} setOpened={setOpened} onClose={handleOnClose}>
        <div className="flex justify-center flex-col items-center gap-[25px]">
          <img className="w-[80px]" src={DeleteImage} alt="delete" />
          <div className=" text-[25px] font-bold ">Delete "{name}"</div>
          <div className="text-center text-[18px]">Are you sure you want to delete {name} ?</div>
          <div className="flex  gap-[40px]">
            <Button onClick={() => setOpened(false)} secondary={true} invert={true}>
              Cancel
            </Button>
            <Button onClick={onSubmit} secondary type="submit">
              Confirm
            </Button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default DeletePackage;
