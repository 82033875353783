import "./App.css";
import { Router } from "./routes/Routes";
import "./assets/fonts/Gilroy/Gilroy-Light.otf";
import ToasterProvider from "./providers/ToasterProvider";
import { AuthProvider } from "react-auth-kit";
import { SelectedLocationProvider } from "./Context/LocationProvider";
import VasCartProvider from "./Context/VasCart";
import moment from "moment-timezone";
moment.tz.setDefault("Africa/Cairo");

function App() {
  return (
    <AuthProvider
      authType={"localstorage"}
      authName={"_auth"}
      // cookieDomain={window.location.hostname}
      // cookieSecure={window.location.protocol === "https:"}
    >
      <VasCartProvider>
        <SelectedLocationProvider>
          <ToasterProvider />
          <Router />
        </SelectedLocationProvider>
      </VasCartProvider>
    </AuthProvider>
  );
}

export default App;
