import React, { useContext, useState } from "react";
import { Button } from "../../../components/Button/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ModalComponent from "../../../components/Modal/Modal2";
import { http } from "./../../../utils/httpCommon";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";
import useFetchData from "../../../hooks/FetchData";
import { useVasCart } from "../../../Context/VasCart";

const ConfirmVasOrder = ({ opened, setOpened }) => {
  // const { selectedLocation } = useContext(SelectedLocationContext);
  const { cartItems, removeAllFromCart, discountType, discountValue } = useVasCart();
  const discountTypes = {
    Percentage: "percent",
    Amount: "amount",
  }

  console.log({discountType, discountValue, cartItems})

  const schema = yup
    .object({
      userId: yup
        .object()
        .typeError("User is a required field")
        .required("User is a required field"),
      location: yup
        .string()
        .typeError("Location is a required field")
        .required("Location is a required field"),
      paymentMethod: yup
        .number()
        .typeError("Payment method is a required field")
        .required("Payment method is a required field"),
        // discountType: yup
        // .string()
        // // .oneOf(Object.values(discountTypes), "Discount type must be either 'percent' or 'amount'")
        // .typeError("Discount type is a required field")
        // .required("Discount type is a required field"),
        // discountValue: yup
        // .number()
        // .typeError("Discount value is a required field")
        // .required("Discount value is a required field"),
    })
    .required();
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const paymentID = watch("paymentMethod") || "";
  const userId = watch("userId");
  const { response: locations, loading } = useFetchData("/location/me");
  const location = locations.find((l) => l._id === watch("location"));

  const { response: paymentMethods, loading: loadingPaymentMethod } = useFetchData(
    location?._id ? `financials/${location._id}/payment-method-accounts` : null
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = async (passedData) => {
    setIsSubmitting(true);
    try {
      await http.post(
        `/vas/purchase`,
        JSON.stringify({
          items: cartItems.map((item) => ({ vas: item?._id, qnt: item?.quantity })),
          locationId: passedData?.location,
          clientId: passedData?.userId?._id,
          paymentMethodId: passedData?.paymentMethod,
          discountType: discountType || discountTypes.Percentage,
          discountValue: +discountValue || +0,
        })
      );
      setOpened(false);
      removeAllFromCart();
      toast.success(" Ordered has been submitted successfully");
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOnClose = () => {
    setOpened(false);
    reset();
  };

  const loadOptions = async (inputText, callback) => {
    let res = null;
    if (inputText.length > 0) {
      let response = await http.get(`/client/find?q=${inputText}`);
      res = response?.data?.data;
      callback(
        res.map((i) => ({
          label: (
            <div className="flex items-center gap-2">
              {i?.profilePicture && (
                <img
                  className="w-[40px] h-[40px] rounded-full object-cover"
                  src={i?.profilePicture}
                  alt={i.name}
                />
              )}{" "}
              {i.name}
            </div>
          ),
          value: i._id,
          ...i,
        }))
      );
    }
  };

  const navigator = useNavigate();
  const CustomNoOptionsMessage = ({ inputValue, onResetClick }) => (
    <div className="p-2">
      No results found{" "}
      <button
        className=" text-[18px]  text-[#34B2C0]  hover:opacity-95"
        style={{ fontFamily: "gilroy-bold" }}
        onClick={() => navigator(`/community/clients/new-client`)}
      >
        Add new client
      </button>
    </div>
  );

  const setCustomValue = (id, value) => {
    setValue(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  return (
    <ModalComponent size="45%" opened={opened} setOpened={setOpened} onClose={handleOnClose}>
      <div className="flex flex-col">
        <div className="text-[25px] w-full text-center " style={{ fontFamily: "gilroy-bold" }}>
          Confirm your order
        </div>
        <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="mb-2  text-[16px]">Add Client</div>
            <AsyncSelect
              components={{
                NoOptionsMessage: ({ inputValue }) => (
                  <CustomNoOptionsMessage inputValue={inputValue} onResetClick={() => { }} />
                ),
              }}
              isClearable
              defaultOptions
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: "56px",
                  borderRadius: "8px",
                  borderColor: "black",
                  "&:hover": {},
                }),
                option: (styles) => ({
                  ...styles,
                  zIndex: "999 !important",
                  backgroundColor: "white !important",
                }),
              }}
              value={userId}
              onChange={(value) => {
                setCustomValue("userId", value);
              }}
              placeholder={"Search for Client"}
              loadOptions={loadOptions}
            />
            <p className="text-red-500 text-[16px]">{errors.userId?.message}</p>
          </div>
          <div className="mt-2 ">
            <div className="mb-2  text-[16px]">Location</div>
            <FormControl fullWidth sx={{ border: "none !important" }}>
              <InputLabel>Select Location</InputLabel>
              <Controller
                control={control}
                name="location"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    sx={{
                      color: "black",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EFEFEF",
                      },
                    }}
                    name={name}
                    onBlur={onBlur}
                    onChange={(e, { props }) => {
                      onChange(props.value);
                    }}
                    variant="outlined"
                    labelId="Select Location"
                    id="location"
                    value={location?._id}
                    label="Select Location"
                    inputRef={ref}
                    defaultValue={""}
                  >
                    <MenuItem value={null}>
                      <em>None</em>
                    </MenuItem>
                    {locations.map((l) => (
                      <MenuItem key={l?._id} value={l?._id}>
                        {l?.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <p className="text-red-500 text-[16px]">{errors.location?.message}</p>
          </div>
          <div className="mt-2 ">
            <div className="mb-2  text-[16px]">Payment Method</div>
            <FormControl fullWidth sx={{ border: "none !important" }}>
              <InputLabel> Select Payment Method</InputLabel>
              <Controller
                control={control}
                name="paymentMethod"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    sx={{
                      color: "black",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EFEFEF",
                      },
                    }}
                    onBlur={onBlur} // notify when input is touched
                    onChange={onChange}
                    variant="outlined"
                    labelId=" Select Payment Method"
                    id="Payment-Method"
                    value={paymentID}
                    label=" Select Payment Method"
                    inputRef={ref}
                    defaultValue=""
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {paymentMethods?.map((l) => {
                      return (
                        (l?.name.toLowerCase() != 'receivable') &&
                        <MenuItem key={l?.qboId} value={l?.qboId}>
                          {l?.name}
                        </MenuItem>
                      )

                    })}
                  </Select>
                )}
              />
            </FormControl>
            <p className="text-red-500 text-[16px]">{errors.paymentMethod?.message}</p>
          </div>
          <div className="m-6 flex justify-end gap-10">
            <Button
              onClick={() => setOpened(false)}
              secondary={true}
              invert={true}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button type="submit" secondary={true} loading={isSubmitting} disabled={isSubmitting}>
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </ModalComponent>
  );
};

export default ConfirmVasOrder;