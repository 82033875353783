import React, { useState } from "react";
import { Button } from "../../../../components/Button/Button";
import { http } from "../../../../utils/httpCommon";
import { TextInput } from "../../../../components/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ModalComponent from "../../../../components/Modal/Modal2";
import Compnaylogo from "../../../../assets/aiesec.png";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const TerminationModal = ({ opened, setOpened, data }) => {
  const navigator = useNavigate();
  //TO DO reasons for termintation

  const schema = yup
    .object({
      reasonOfTermination: yup.string(),
      info: yup.string(),
    })
    .required();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const onSubmit = async () => {
    setSubmitLoading(true);
    try {
      await http.delete(`/rent-contracts/${data._id}`);
      setSubmitLoading(false);
      navigator("/operations");
      toast.success("Rent contract deleted successfully");
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };

  const handleOnClose = () => {
    setOpened(false);
    reset();
  };

  return (
    <ModalComponent
      opened={opened}
      setOpened={setOpened}
      onClose={handleOnClose}
    >
      <div className="flex flex-col">
        <div className="text-[24px] font-bold">Termination</div>
        <div className="text-[20px] font-bold text-[#797979] ">
          Contract Number
        </div>
        <div
          className="text-[24px] font-bold"
          style={{ fontFamily: "gilroy-bold" }}
        >
          {data?.rentNumber}
        </div>

        <div className="border-[1px] rounded-[10px] w-full">
          <div className="flex items-center gap-[0px]  ">
            {data?.company?.logo && (
              <img
                src={data?.company?.logo ? data?.company?.logo : Compnaylogo}
                alt="Company-logo"
                className="w-[55px] h-[55px] object-contain "
              />
            )}

            <div
              className="text-[20px]  px-4 py-2"
              style={{ fontFamily: "gilroy-bold" }}
            >
              {data?.company?.name}
            </div>
          </div>
        </div>

        <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-4 ">
            <TextInput
              height="40px"
              label={"Reason of termination"}
              {...register("reasonOfTermination")}
            />
            <p className="text-red-500 mt-4 ">{errors.info?.message}</p>
          </div>
          {/* <div className="my-4">
              <div>
                
                <FormControl fullWidth sx={{ border: "none !important" }}>
                  <InputLabel>Reason for termination</InputLabel>
                  <Controller
                    control={control}
                    name="reasonOfTermination"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        sx={{
                          color: "black",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#EFEFEF",
                          },
                        }}
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        variant="outlined"
                        labelId="Reason for termination-label"
                        id="Reason for termination"
                        // value={value}
                        label="Reason for termination"
                        inputRef={ref}
                        defaultValue=""
                      >
                        <MenuItem value="">
                          {" "}
                          <em>None</em>{" "}
                        </MenuItem>
                        {reasons.map((l) => (
                          <MenuItem key={l} value={l}>
                            {l}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <p className="text-red-500">
                  {errors.reasonOfTermination?.message}
                </p>
              </div>
            </div> */}

          <div className="mt-6 ">
            <TextInput
              height="40px"
              label={"More information"}
              {...register("info")}
            />
            <p className="text-red-500 mt-4">{errors.info?.message}</p>
          </div>
          <div className="mt-6 mb-8 flex justify-end gap-10">
            <Button
              onClick={() => setOpened(false)}
              secondary={true}
              invert={true}
            >
              Cancel
            </Button>
            <Button loading={submitLoading} type="submit" secondary={true}>
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </ModalComponent>
  );
};

export default TerminationModal;
