import React from "react";
import LocationsTable from "./LocationsTable";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { useState } from "react";
import { http } from "../../../utils/httpCommon";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

const Locations = () => {
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await http.get("/location");
      setResponse(
        res.data.data
          ?.filter((item) => !item.isDeleted)
          .map((res) => ({
            id: res?._id,
            ...res,
          }))
      );
      setLoading(false);
    } catch (error) {
      toast.error(error.response?.data.message || error.message || "Something went wrogn");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return <>{loading ? <LoadingCircle /> : <LocationsTable response={response} fetchData={fetchData} />}</>;
};

export default Locations;
