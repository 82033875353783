import React, { useState, useCallback, useEffect, useRef } from "react";
import { http } from "../../../utils/httpCommon";
import Table from "../../../components/DataGrid/Table";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { toast } from "react-hot-toast";
import { debounce } from "lodash";
import ProfileImg from "../../../assets/profile.png";
import { Link, useNavigate } from "react-router-dom";

const CompaniesTable = () => {
  const navigator = useNavigate();

  const [searchQuery, setSearchQuery] = React.useState("");
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    debounced(event.target.value, 600);
  };

  ///onFilterChange
  const onFilterChange = useCallback(async (filterModel) => {
    try {
      let searchVal = filterModel?.items[0]?.value;
      if (searchVal?.length > 0) {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }));
        const res = await http.post(
          `company/all`,
          JSON.stringify({
            page: pageState.page,
            limit: pageState.pageSize,
            textField: searchVal,
          })
        );
        let filteredData = res?.data?.data?.data?.filter((item) => !item?.isDeleted);
        const rows = filteredData?.map((d) => ({
          id: d?._id,
          ...d,
        }));
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: rows,
          total: res?.data?.data?.count,
        }));
      } else {
        debounced();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    }
  }, []);

  //fetching pagenated Data

  const fetchData = async (searchValues) => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const res = await http.post(
        `company/all`,
        JSON.stringify({
          page: pageState.page,
          limit: pageState.pageSize,
          textField: searchValues,
        })
      );

      let filteredData = res?.data?.data?.data?.filter((item) => !item?.isDeleted);
      const rows = filteredData?.map((d) => ({
        id: d?._id,
        ...d,
      }));

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rows,
        total: res?.data?.data?.count,
      }));
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    }
  };

  const debounced = debounce(fetchData, 1000);

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize]);

  const columns = [
    {
      field: "Images",
      headerName: "",
      width: 120,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/community/company/${params.row.id}`}
            className="flex items-center h-[75px]  py-2 my-1"
          >
            {params?.row?.logo ? (
              <img
                className="w-[70px] h-[70px]  rounded-full object-cover "
                src={params?.row?.logo}
                alt={params.row.logo}
              />
            ) : (
              <div className="w-[70px] h-[70px]  rounded-full object-cover "></div>
            )}
          </Link>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 220,

      renderCell: (params) => {
        return (
          <Link
            to={`/community/company/${params.row.id}`}
            className="flex items-center  font-semibold  text-[16px]  py-2 hover:text-[#34B2C0]"
          >
            {params?.row?.name}
          </Link>
        );
      },
    },
    {
      field: "leader",
      headerName: "Leader",
      width: 180,
      renderCell: (params) => {
        return (
          <Link
            to={`/community/client/${params?.row?.leader?._id}`}
            className="flex items-center  font-semibold  text-[16px]  py-2 hover:text-[#34B2C0]"
          >
            {params?.row?.leader?.name}
          </Link>
        );
      },
    },

    {
      field: "industry",
      headerName: "Industry",
      width: 180,
      renderCell: (params) => {
        return (
          <Link
            to={`/community/client/${params?.row?.leader?._id}`}
            className="flex items-center  font-semibold  text-[16px]  py-2 hover:text-[#34B2C0]"
          >
            {params?.row?.industry?.name}
          </Link>
        );
      },
      // editable: true,
    },
    {
      field: "publicCoinBalance",
      headerName: "Coin Balance",
      width: 150,
      // editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center w-[140px]">
            <div
              className={` ${
                // params?.row?.status !== "open"
                // ?
                ""
                // : "text-[#34B2C0]  bg-[#3bb2c0]"
              } 
            text-[#797979] bg-[#a09f9f] bg-opacity-20  font-semibold p-2 text-[16px] flex  justify-center items-center rounded-[25px]   `}
            >
              Not on the app
            </div>
          </div>
        );
      },
    },
  ];

  const handleActionButton = () => {
    navigator("/community/companies/new-company");
  };

  return (
    <>
      <Table
        role={true}
        disableFilters={true}
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState.total}
        isLoading={pageState.isLoading}
        data={pageState.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        fetchData={fetchData}
        title={"Amenities"}
        actionButton={"Add Company"}
        onFilterChange={onFilterChange}
        handleActionButton={handleActionButton}
      />
    </>
  );
};

export default CompaniesTable;
