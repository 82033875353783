import React from "react";
import { Button } from "../../../../components/Button/Button";
import { TextInput } from "../../../../components/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ModalComponent from "../../../../components/Modal/Modal2";
import { http } from "./../../../../utils/httpCommon";
import { toast } from "react-hot-toast";
const ArchiveClient = ({ opened, setOpened, name, id }) => {
  const schema = yup
    .object({
      reasons: yup.string(),
    })
    .required();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (passedData) => {
    try {
      await http.put(
        `/company/${id}/archive`,
        JSON.stringify({
          archiveReason: passedData.reasons,
        })
      );
      setOpened(false);
      toast.success("Client has been archived successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const handleOnClose = () => {
    setOpened(false);
    reset();
  };

  return (
    <ModalComponent opened={opened} setOpened={setOpened} onClose={handleOnClose}>
      <div className="flex flex-col">
        <div className="text-[25px] font-semibold">Archive "{name}"</div>
        <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-4 ">
            <TextInput label={"Reasons"} {...register("reasons")} />
            <p className="text-red-500">{errors.moreInfo?.message}</p>
          </div>
          <div className="mt-4 mb-10 flex justify-end gap-10">
            <Button onClick={() => setOpened(false)} secondary={true} invert={true}>
              Cancel
            </Button>
            <Button type="submit" secondary={true}>
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </ModalComponent>
  );
};

export default ArchiveClient;
