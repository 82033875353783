import { useLocation } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button/Button";
import { http } from "../../../utils/httpCommon";
import { debounce, set } from "lodash";
import Table from "../../../components/DataGrid/Table";
import { toast } from "react-hot-toast";
import { SelectedLocationContext } from "../../../Context/LocationProvider";
import moment from "moment";
import { useAuthUser } from "react-auth-kit";
import phoneICon from "../../../assets/icons/call.png";
import { prettyNumber } from "../../../lib/prettyNumber";
import { Switch } from "@mui/material";
import ModalComponent from "../../../components/Modal/Modal";
import { useForm } from "react-hook-form";
import { TextInput } from "../../../components/TextInput/TextInput";
import { ErrorMessage } from "@hookform/error-message";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import useFetchData from '../../../hooks/FetchData';



const Receivables = () => {
  const { selectedLocation } = useContext(SelectedLocationContext);
  const auth = useAuthUser();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const [searchQuery] = React.useState("");
  const [includePaid, setIncludePaid] = useState(false);
  const [includeDeleted, setIncludeDeleted] = useState(false);

  ///onFilterChange
  const onFilterChange = useCallback(
    async (filterModel) => {
      try {
        let searchVal = filterModel?.items[0]?.value;

        if (searchVal?.length > 0) {
          setPageState((old) => ({
            ...old,
            isLoading: true,
          }));

          const unpaidStatus = (includePaid) ? "any" : "";
          const res = await http.get(
            `/receivables?page=${pageState.page}&limit=${pageState.pageSize}&status=${unpaidStatus}&location=${selectedLocation}&search=${searchQuery}&showDeleted=${includeDeleted}`,
            JSON.stringify({
              page: pageState.page,
              limit: pageState.pageSize,
              name: searchVal,
            })
          );

          const rowsData = res.data?.data?.map((d) => ({
            id: d._id,
            ...d,
          }));

          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: rowsData,
            //get from another api
            total: res?.data?.metadata?.[0]?.count,
          }));
        } else {
          debounced();
        }
      } catch (error) {
        console.error(error);
        toast.error(error.response?.data?.message || error.message || "Something went wrong");
      }
    },
    [pageState.page, pageState.pageSize, selectedLocation]
  );

  const [toPay, setToPay] = useState();
  const [toPayLocation, setToPayLocation] = useState();
  const [payModal, togglePayModal] = useState(false);
  const handlePay = async (itemId, locationId) => {
    setToPay(itemId);
    setToPayLocation(locationId);
    togglePayModal(true);
  };
  

  const columns = [
    {
      field: "client",
      headerName: "Client",
      width: 180,
      filterable: false,
      // editable: true,
      renderCell: (params) => {
        let target, id, name;
        if (params.row?.clientType === "company") {
          target = "company";
          id = params.row?.company?._id;
          name = params.row?.company?.name;
        } else if (params.row?.clientType === "client") {
          id = params.row?.client?._id;
          name = params.row?.client?.name;
        }

        return (
          <Link
            to={`/community/${target}/${id}`}
            className="flex flex-col gap-[5px] h-[80px] text-[16px]  justify-center hover:text-[#34B2C0]"
          >
            <div className="font-semibold">{name}</div>
            {params.row?.client?.phone && params.row?.clientType === "client" && (
              <div className="flex items-center">
                <img src={phoneICon} className="w-[13.5px]" alt={params.row?.client?.name} />
                {params.row?.client?.phone}
              </div>
            )}
          </Link>
        );
      },
    },
    {
      field: "finance.amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] ">
            {prettyNumber(params?.row?.finance?.amount)}
          </div>
        );
      },
    },
    {
      field: "serviceDate",
      headerName: "Service Date",
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {moment(params?.row?.serviceDate).format("MMM Do YYYY")}
          </div>
        );
      },
    },

    {
      field: "service",
      headerName: "Service",
      width: 180,
      renderCell: (params) => {
        return <div className="font-semibold text-base">{params?.row?.serviceType}</div>;
      },
    },
    {
      field: "expectedDueDate",
      headerName: "Expected Due Date",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {params?.row?.expectedDueDate
              ? moment(params?.row?.expectedDueDate).format("MMM Do YYYY")
              : "N/A"}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center w-fit text-[16px]">
            {params?.row?.isDeleted === true ? (
              <div className=" text-[#FFFFFF] bg-[#EF4444]  font-semibold p-2 text-[16px] flex  justify-center items-center rounded-[25px]  ">
                Deleted
              </div>
            ) :
              params?.row?.status === "unpaid" ? (
                <div className=" text-[#797979] bg-[#EFEFEF]  font-semibold p-2 text-[16px] flex  justify-center items-center rounded-[25px]  ">
                  {params?.row?.status}
                </div>
              ) : params?.row?.status === "paid" ? (
                <div className=" text-[#00AD3B] bg-[#00ad3b1a]  font-semibold p-2 text-[16px] flex  justify-center items-center rounded-[25px]  ">
                  {params?.row?.status}
                </div>
              ) : (
                <div className=" text-[#797979] bg-[#EFEFEF]  font-semibold p-2 text-[16px] flex  justify-center items-center rounded-[25px]  ">
                  {params?.row?.status}
                </div>
              )}
          </div>
        );
      },
      // editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 480,
      renderCell: ({ row }) => {
        {console.log(row);}
        return (
          <>
            {auth().role !== "Land Lord" && !row?.isDeleted && (
              <div className="flex gap-5">
                <Button
                  secondary={true}
                  loading={paying}
                  onClick={() => {
                    handlePay(row._id, row.location._id);
                  }}
                >
                  Pay Now
                </Button>
              </div>
            )}
          </>
        );
      },
    },
  ];

  const fetchData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));
      const unpaidStatus = (includePaid) ? "any" : "unpaid";
      const res = await http.get(
        `/receivables?page=${pageState.page}&limit=${pageState.pageSize}&status=${unpaidStatus}&location=${selectedLocation}&search=${searchQuery}&showDeleted=${includeDeleted}`
      );
      const rowsData = res.data?.data?.data?.map((d) => ({
        id: d._id,
        ...d,
      }));
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.data?.length,
      }));
    } catch (error) {
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  };
  const debounced = useCallback(debounce(fetchData, 1000), [pageState]);

  useEffect(() => {
    fetchData();
  }, [pageState.page, pageState.pageSize, selectedLocation, searchQuery, includePaid, includeDeleted]);

  const [paying, setPaying] = useState(false);

  const onPayNow = async (id) => {
    try {
      setPaying(true);
      await http.put(`/receivables/${id}/pay`);
      toast.success("Booking has been paid successfully");
      fetchData();
    } catch (error) {
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    } finally {
      setPaying(false);
    }
  };

  return (
    <div>

      <div className="flex justify-between items-center">
        <div className="w-full my-2 bg-white py-1 text-[18px] font-bold">Include paid</div>
        <Switch value={includePaid} onChange={(_, checked) => setIncludePaid(checked)} />
      </div>

      <div className="flex justify-between items-center">
        <div className="w-full my-2 bg-white py-1 text-[18px] font-bold">Show deleted</div>
        <Switch value={includeDeleted} onChange={(_, checked) => setIncludeDeleted(checked)} />
      </div>

      <PayReceivableModel
        fetchData={fetchData}
        opened={payModal}
        setOpened={togglePayModal}
        invoiceId={toPay}
        locationId={toPayLocation}
      />

      <Table
        rowCount={pageState.total}
        isLoading={pageState.isLoading}
        data={pageState?.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        removeActionButton
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        onFilterChange={onFilterChange}
        removeSearch
      />
    </div>
  );
};

function PayReceivableModel({ fetchData, opened, setOpened, invoiceId, locationId }) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const [submitLoading, setPaying] = useState(false);

  const onSubmit = async (passedData) => {
    try {
      console.log("Submitted data:", passedData, "Invoice ID:", invoiceId);
      setPaying(true);

      if (!passedData.paymentMethodId) {
        toast.error("You must select a payment method");
        return;
      }

      const response = await http.put(`/receivables/${invoiceId}/pay`, {
        paymentMethodId: passedData.paymentMethodId,
      });

      toast.success("Payment completed successfully");
      reset();
      setOpened(false);
      fetchData();
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setPaying(false);
    }
  };

  const paymentID = watch("paymentMethodId") || "";
  const { response: paymentMethods } = useFetchData(
    locationId ? `financials/${locationId}/payment-method-accounts` : null
  );

  return (
    <ModalComponent opened={opened} setOpened={setOpened}>
      <div className="text-[24px] font-bold">New Payment</div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-center gap-[40px] mt-4"
      >
        <div className="w-full">
          <FormControl fullWidth>
            <InputLabel>Select Payment Method</InputLabel>
            <Controller
              name="paymentMethodId"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  sx={{ color: "black" }}
                  label="Select Payment Method"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {paymentMethods?.map((method) => (
                    method.name.toLowerCase() !== "receivable" && method.name.toLowerCase() !== "credit" &&(
                      <MenuItem key={method.qboId} value={method.qboId}>
                        {method.name}
                      </MenuItem>
                    )
                  ))}
                </Select>
              )}
            />
            <p className="text-red-500 text-[16px]">
              {errors.paymentMethodId?.message}
            </p>
          </FormControl>
        </div>

        <div className="flex justify-around w-full">
          <Button secondary onClick={() => setOpened(false)}>
            Cancel
          </Button>
          <Button loading={submitLoading} secondary type="submit">
            Confirm
          </Button>
        </div>
      </form>
    </ModalComponent>
  );
}

export default Receivables;
