import React, { useState, createContext, useEffect } from "react";
import { useAuthUser } from "react-auth-kit";

export const SelectedLocationContext = createContext();

export const SelectedLocationProvider = ({ children }) => {
  const auth = useAuthUser();
  const [selectedLocation, setSelectedLocation] = useState(() => {
    const storedLocation = localStorage.getItem("selectedLocation");
    return auth()?.locations[0]?._id || storedLocation || "";
  });

  useEffect(() => {
    if (auth()?.locations?.length) {
      const initialLocation = auth().locations[0]._id;
      setSelectedLocation(initialLocation);
      localStorage.setItem("selectedLocation", initialLocation);
    } else {
      setSelectedLocation("");
      localStorage.removeItem("selectedLocation");
    }
  }, [auth()]);

  useEffect(() => {
    if (selectedLocation) {
      localStorage.setItem("selectedLocation", selectedLocation);
    } else {
      localStorage.removeItem("selectedLocation");
    }
  }, [selectedLocation]);

  return <SelectedLocationContext.Provider value={{ selectedLocation, setSelectedLocation }}>{children}</SelectedLocationContext.Provider>;
};
