import React, { useState } from "react";
import { Layout } from "../../../components/Layout/Layout";
import { Button } from "../../../components/Button/Button";
import { http } from "../../../utils/httpCommon";
import { TextInput } from "../../../components/TextInput/TextInput";
import CustomTextInput from "../../../components/CustomTextInput/CustomTextInput";
import { useForm } from "react-hook-form";
import useFetchData from "../../../hooks/FetchData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DropFile from "../../../components/DropFile/DropFile";
import { useNavigate } from "react-router-dom";
import flag from "../../../assets/EgyptFlag.png";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import AsyncSelect from "react-select/async";
import { Controller } from "react-hook-form";
import useFetchPostData from "../../../hooks/FetchPostData";
import { toast } from "react-hot-toast";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const NewCompany = () => {
  const [mainImage, setMainImages] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedLeader, setSelectedLeader] = useState([]);
  const [selectedSpokes, setSelectedSpokes] = useState([]);
  const navigator = useNavigate();
  const { response: industries, loading: loadingIndustries } = useFetchPostData(
    "/industry/all",
    JSON.stringify({
      page: 1,
      sortOrder: 1,
      limit: 10000,
    })
  );

  const { response: locations, loading } = useFetchData("/location/me");
  const schema = yup
    .object({
      name: yup.string().required(),
      phone: yup
        .string()
        .required("Phone number is required")
        .test("is-valid-phone", "Invalid phone number", (value) => {
          if (!value) return false;
          return isValidPhoneNumber(value);
        }),
      email: yup.string().email().typeError("Email is a required filed ").required(),
      leader: yup.string(),
      industry: yup.string(),
      spokes: yup.string(),
    })
    .required();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [submitError, setSubmitError] = useState("");
  const onSubmit = async (passedData) => {
    var data = new FormData();
    data.append("image", mainImage[0]);
    try {
      let res = await http.post("/image", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      try {
        var data2 = JSON.stringify({
          name: passedData.name,
          email: passedData.email,
          logo: res.data.url,
          phone: passedData.phone,
          leader: selectedLeader?.value,
          spokes: selectedSpokes?.map((d) => d.value),
          Admin: selectedLeader?.value,
          documents: [],
        });

        await http.post("/company", data2, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        toast.success("Company created successfully ");
        navigator("/community");
      } catch (error) {
        setSubmitError(error.response.data.message);
        toast.error(error?.response?.data?.message);
      }
    } catch (error) {}
  };

  // const [selectedLocation, setSelectedLocation] = React.useState("");
  // const handleChangeSelectLocation = (event) => {
  //   setValue("location", event.target.value);
  //   setSelectedLocation(event.target.value);
  // };

  const loadOptions = async (inputText, callback) => {
    let res = null;
    if (inputText.length > 0) {
      let response = await http.get(`/client/find?q=${inputText}`);
      res = response.data.data;
      callback(
        res.map((i) => ({
          label: (
            <div key={`${i.phone}-${i?.name}`} className="flex items-center">
              {i?.profilePicture ? (
                <img
                  src={i?.profilePicture}
                  className="w-[35px] h-[35px] object-cover rounded-full mr-2"
                  alt={i.name}
                />
              ) : (
                <div className="h-[35px] "></div>
              )}
              {i.name} / {i.phone}
            </div>
          ),
          value: i._id,
        }))
      );
    }
  };

  const loadOptionsSpokes = async (inputText, callback) => {
    let res = null;
    if (inputText.length > 0) {
      let response = await http.get(`/client/find?q=${inputText}`);
      res = response.data.data;
      callback(
        res.map((i) => ({
          label: (
            <div key={`${i.phone}-${i?.name}`} className="flex items-center">
              {i?.profilePicture ? (
                <img
                  src={i?.profilePicture}
                  className="w-[35px] h-[35px] object-cover rounded-full mr-2"
                  alt={i.name}
                />
              ) : (
                <div className="h-[35px] "></div>
              )}
              {i.name} / {i.phone}
            </div>
          ),
          value: i._id,
        }))
      );
    }
  };

  const handleChangeSelectIndustry = (event) => {
    setValue("industry", event.target.value);
    setSelectedIndustry(event.target.value);
  };

  const CustomNoOptionsMessage = ({ inputValue, onResetClick }) => (
    <div className="p-2">
      No results found{" "}
      <button
        className=" text-[18px]  text-[#34B2C0]  hover:opacity-95"
        style={{ fontFamily: "gilroy-bold" }}
        onClick={() => navigator(`/community/clients/new-client`)}
      >
        Add new client
      </button>
    </div>
  );

  // mobile number
  let phone = watch("phone");
  const handleChangePhoneNumber = (value) => {
    setCustomValue("phone", value);
  };

  const setCustomValue = (id, value) => {
    setValue(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  return (
    <Layout>
      {!loading && !loadingIndustries ? (
        <div className="flex justify-center">
          <div className="w-[85%] bg-white px-14 py-4 m-2">
            <div className="flex flex-col">
              <div className="w-full flex justify-between">
                <span className=" text-[24px] font-bold my-1">Add Company</span>
              </div>
              <span className="text-[16px]">Upload profile pic</span>
              <span className="text-[16px]">Supported formate: JPEG, PNG</span>
              <br />
            </div>
            <div className="flex gap-[20px] ">
              <div className="min-w-[40%] w-full h-[300px] mb-0 py-2">
                <DropFile
                  image={mainImage}
                  setImage={setMainImages}
                  height={"300px"}
                  width={"500px"}
                  message={"Company image"}
                  bgColor="white"
                  fit="object-contain"
                />
              </div>
            </div>

            {/* form  */}
            <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-6">
                <TextInput
                  label={"Name*"}
                  {...register("name", { required: "input name is required" })}
                />
                <p className="text-red-500 text-[16px]">{errors.name?.message}</p>
              </div>

              <div className="mt-4">
                <TextInput
                  label={"Email*"}
                  {...register("email", { required: "Email  is required" })}
                />
                <p className="text-red-500 text-[16px]">{errors.email?.message}</p>
              </div>
              <div className="mt-4">
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      id="phone"
                      name="phone"
                      defaultCountry="EG"
                      placeholder="Enter your phone number"
                      value={phone}
                      onChange={handleChangePhoneNumber}
                      className={`custom-phone-input ${errors.phone ? "error" : ""}`}
                    />
                  )}
                />
                {errors.phone && (
                  <div className="text-rose-500 text-[14px]">{errors.phone.message}</div>
                )}
              </div>

              <div className="mt-4">
                <div className="text-[18px] font-medium mb-1">Company Leader*</div>
                <Controller
                  control={control}
                  name="leader"
                  render={({ field }) => (
                    <AsyncSelect
                      components={{
                        NoOptionsMessage: ({ inputValue }) => (
                          <CustomNoOptionsMessage inputValue={inputValue} onResetClick={() => {}} />
                        ),
                      }}
                      {...field}
                      isClearable
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: "56px",
                          borderRadius: "8px",
                          borderColor: "black",
                          "&:hover": {},
                        }),
                        option: (styles) => ({
                          ...styles,
                          zIndex: "999 !important",
                          backgroundColor: "white !important",
                        }),
                      }}
                      value={selectedLeader}
                      onChange={(value) => setSelectedLeader(value)}
                      placeholder={"Search for Company Leader by phone number*"}
                      loadOptions={loadOptions}
                    />
                  )}
                />
                <p className="text-red-500 text-[16px]">{errors.leader?.message}</p>
              </div>

              <div className="mt-4">
                <div className="text-[18px] font-medium mb-1">Company spokes*</div>
                <Controller
                  control={control}
                  name="leader"
                  render={({ field }) => (
                    <AsyncSelect
                      components={{
                        NoOptionsMessage: ({ inputValue }) => (
                          <CustomNoOptionsMessage inputValue={inputValue} onResetClick={() => {}} />
                        ),
                      }}
                      {...field}
                      isClearable
                      isMulti
                      defaultOptions
                      cacheOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          minHeight: "56px",
                          borderRadius: "8px",
                          borderColor: "black",
                          "&:hover": {},
                        }),
                        option: (styles) => ({
                          ...styles,
                          zIndex: "999 !important",
                          backgroundColor: "white !important",
                        }),
                      }}
                      value={selectedSpokes}
                      onChange={(value) => setSelectedSpokes(value)}
                      placeholder={"Search for Company Spokes by phone number*"}
                      loadOptions={loadOptionsSpokes}
                    />
                  )}
                />
                <p className="text-red-500 text-[16px]">{errors.leader?.message}</p>
              </div>

              <div className="mt-4">
                <div>
                  <FormControl fullWidth>
                    <InputLabel id="Select Industry">Select Industry</InputLabel>
                    <Select
                      sx={{
                        height: "40px",
                      }}
                      labelId="Select Location"
                      id="Select industry"
                      value={selectedIndustry}
                      label="Select industry"
                      onChange={handleChangeSelectIndustry}
                    >
                      <MenuItem value="">
                        {" "}
                        <em>None</em>{" "}
                      </MenuItem>
                      {industries?.data?.map((l) => (
                        <MenuItem key={l._id} value={l._id}>
                          {l.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <p className="text-red-500 text-[16px]">{errors.industry?.message}</p>
                </div>
              </div>

              <p className="text-red-500 my-3">{submitError}</p>
              <div className="mt-6 mb-8 gap-[50px] flex justify-end">
                <Button onClick={() => navigator("/community")} secondary={true} invert={true}>
                  Cancel
                </Button>
                <Button secondary={true} type="submit">
                  Add Company
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <LoadingCircle />
      )}
    </Layout>
  );
};

export default NewCompany;
